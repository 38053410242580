// React stuff
var React = require("react");
var auth = require("../utils/auth");
var ReactTable = require("react-table").default;

var injectIntl = require("react-intl").injectIntl;

var createClass = require('create-react-class');

var Questionnaires = createClass({
  getInitialState: function(){
    return {questionnaires: []};
  },
  componentDidMount: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_questionnaires",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          if(data.result == null){
            self.setState({questionnaires: [],customerDataError: false});
          }else{
            self.setState({questionnaires: data.result,customerDataError: false});
          }
        }else{
          self.setState({questionnaires: [],customerDataError: true});
        }
      },
      error: function(){
        self.setState({questionnaires: [],dataError: true});
      }
    });
  },
  filter: function(filter,row){
    if(filter.id == "name"){
      search = String(filter.value);
      value = String(row[filter.id]);
      if(value.toLowerCase().indexOf(search.toLowerCase()) > -1){
        return true;
      }else{
        return false;
      }
    }else if(filter.id == "default"){
      search = String(filter.value);
      if(this.props.intl.formatMessage({id: 'questionnaires.yes'}).toLowerCase().indexOf(search) > -1){
        if(row[filter.id] == 1){
          return true;
        }
      }
    }else if(filter.id == "questions"){

      search = String(filter.value);
      value = row[filter.id].length;
      if(search == value){
        return true;
      }else{
        return false;
      }
    }else{
      console.log("Missing filter logic for " + filter.id);
    }
  },
  render: function() {
    var self = this;
    columns = [{
      Header: self.props.intl.formatMessage({id: 'questionnaires.name'}),
      accessor: 'name',
    },
    {
      Header: self.props.intl.formatMessage({id: 'questionnaires.questions'}),
      accessor: 'questions',
      Cell: function(props){
        return (<span>{props.value.length}</span>);     
      },
    },
    {
      Header: self.props.intl.formatMessage({id: 'questionnaires.default'}),
      accessor: 'default',
      Cell: function(props){
        return (<span>{(props.value=="1" ? self.props.intl.formatMessage({id: 'questionnaires.yes'}): "")}</span>);     
      },
    },
    {
      Header: "",
      sortable: false,
      filterable: false,
      accessor: 'questionnaireid',
      Cell: function(props){
        return (<a href={"/questionnaire/" + props.value} className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-pencil"></i> {self.props.intl.formatMessage({id: 'questionnaires.edit'})}</a>)
      },
    }]

    return (
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'questionnaires.questionnaires'})}<a href="/questionnaire/" className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-th-list"></i> {this.props.intl.formatMessage({id: 'questionnaires.createQuestionnaire'})}</a></h1>
            <ReactTable data={this.state.questionnaires} defaultFilterMethod={this.filter} columns={columns} defaultPageSize={25} className="-striped -highlight" showPageSizeOptions={false} filterable={true} nextText={self.props.intl.formatMessage({id: 'table.next'})} previousText={self.props.intl.formatMessage({id: 'table.previous'})} pageText={self.props.intl.formatMessage({id: 'table.page'})} ofText={self.props.intl.formatMessage({id: 'table.of'})} noDataText={self.props.intl.formatMessage({id: 'table.nodata'})}/>
          </div>
        </div>
      </div>
		);
  }
});

module.exports = injectIntl(Questionnaires);
