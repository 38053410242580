// React stuff
var React = require("react");
var auth = require("../utils/auth");
var PropTypes = require('prop-types');

var Menu = require("./menu");

var injectIntl = require("react-intl").injectIntl;
var createClass = require('create-react-class');

var Admin = createClass({
  logoutTime: function(){
    if(this.props.location.pathname != "/" && this.props.location.pathname != "/logout"){
      auth.loggedIn(function(authed){
        if(!authed){
          document.location.href="/logout";}
      }.bind(this));
    }
	},
  componentDidMount: function(){
    var self = this;
    self.logoutTime();
 		setInterval(function(){
     	self.logoutTime();
 		},5000);
  },
  render: function() {
    var menu = "";
    if(auth.getUserid() > 0){
      menu = <Menu auth={auth}/>
    }
  return (
      <div id={(auth.getUserid() != "null" ? "wrapper" : "prelogin")}>
        <div>
          <Menu auth={auth}/>
          {this.props.children}
        </div>
      </div>
 		);
  }
});

Admin.propTypes = {
  history: PropTypes.object,
  children: PropTypes.array
};


module.exports = injectIntl(Admin);
