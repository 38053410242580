// React stuff
var React = require("react");
var auth = require("../utils/auth");
var ReactModal = require('react-modal');

var injectIntl = require("react-intl").injectIntl;


var LinkedStateMixin = require('react-addons-linked-state-mixin');
var LinkedInput = require('react-linked-input');
var createClass = require('create-react-class');

var Dropzone = require('react-dropzone');

var Organization = createClass({
  mixins: [LinkedStateMixin],
  getInitialState: function() {
    return {organizationid:0,usernameprefix:'',name: '',number: '',errors: {},stats: {users: 0},files: [],type:'customer'};
  },
  getOrganization: function(id){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_organization",
      data: JSON.stringify({id: id}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({organizationid: (parseInt(data.result.organizationid) || 0),name: data.result.name,number: data.result.number,address: data.result.address,postal: data.result.postal,city:data.result.city,contact: data.result.contact,usernameprefix: data.result.usernameprefix,stats: data.result.stats,enableble: parseInt(data.result.enableble),enableserial: parseInt(data.result.enableserial),files: data.result.files,type: data.result.type});
      },
      error: function(){
        console.log(data);
      }
    });
  },
  componentDidMount: function(){
    if(this.props.match.params.id){
      this.getOrganization(this.props.match.params.id);
    }
  },
  onDrop: function (files) {
    var self = this;
    var reader = new FileReader();
    self.setState({fileerror: false});
    var file = files[0];
    reader.fileName = file.name
    reader.onload = function(upload) {
      $.ajax({
        type: 'POST',
        url: "/system/save_organization_file",
        data: JSON.stringify({id: self.state.organizationid,file: upload.target.result,name: upload.target.fileName}),
        dataType: 'json',
        contentType: "application/json",
        success: function (data, textStatus, jqXHR) {
          if(data.status == "success"){
            self.setState({fileerror: false});
            self.getOrganization(self.state.organizationid);
          }
        },
        error: function(){
          self.setState({fileerror: true});
        }
      });
    }
    reader.readAsDataURL(file);
  },
  deleteFile: function(id,e){
    var self = this;
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/delete_organization_file",
      data: JSON.stringify({id: self.state.organizationid,file:id}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          self.setState({fileerror: false});
          self.getOrganization(self.state.organizationid);
        }
      },
      error: function(){
        self.setState({fileerror: true});
      }
    });
  },
  save: function (e){
    e.preventDefault();
    var errors = {};
    // Check fields
    if(this.state.name.length < 3){
      errors.name = true;
    }
    if(this.state.usernameprefix.length < 2){
      errors.usernameprefix = true;
    }
    if(Object.keys(errors).length == 0){
      this.setState({usernameprefixexists: false});
      var self = this;
      $.ajax({
        type: 'POST',
        url: "/system/save_organization",
        data: JSON.stringify({id: self.state.organizationid,name: self.state.name,number: self.state.number,address: self.state.address,postal: self.state.postal,city: self.state.city,contact: self.state.contact,usernameprefix: self.state.usernameprefix,enableble: self.state.enableble,enableserial: self.state.enableserial,type: self.state.type}),
        dataType: 'json',
        contentType: "application/json",
        success: function (data, textStatus, jqXHR) {
          if(data.status == "success"){
            self.setState({organizationid: data.result});
            self.props.history.push( "/organizations");
          }else{
            self.setState({usernameprefixexists: true,errors: {usernameprefix: true}});
          }
        },
        error: function(){
          console.log(data);
        }
      });
    }else{
      this.setState({errors: errors});
    }
  },
  delete: function (e){
    e.preventDefault();
    this.setState({deleteModal:true});
  },
  confirmDelete: function (e){
    e.preventDefault();
    var self = this;
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/delete_organization",
      data: JSON.stringify({id: this.state.organizationid}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          self.props.history.push( "/organizations");
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  closeModal: function(e){
    if(e){
      e.preventDefault();
    }
    this.setState({deleteModal:false});
  },
  selectChange: function(data,e){
    if(data=="type"){
      this.setState({type: e.target.value});
    }else{
      console.log("Unknown data",data);
    }
    data = e.target.value;
  },
  render: function() {
    var customers = [];
    var self = this;
    return (
      <div id="page-content-wrapper">
        <ReactModal ariaHideApp={false} isOpen={this.state.deleteModal} contentLabel={this.props.intl.formatMessage({id: 'model.delete'})} onRequestClose={this.closeModal}>     
            <p>{this.props.intl.formatMessage({id: 'organization.deleteAreYouSure'},{name: this.state.name})}</p> 
            <button className='btn btn-sm btn-default' onClick={this.closeModal}>
              <i className="fa fa-times"></i> {this.props.intl.formatMessage({id: 'generic.close'})}
            </button>
            <button className='btn btn-sm btn-danger' onClick={this.confirmDelete}>
              <i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}
            </button>
        </ReactModal>
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'organization.organization'})}</h1>
            <form>
              <div className="row">
                <div className="col-sm-6">
                  <div className={"form-group" + (this.state.errors.name ? " has-error" : "")}>
                    <label className="control-label" htmlFor="name">{this.props.intl.formatMessage({id: 'organization.name'})}:</label>
                    <LinkedInput type="text" className="form-control" id="name" valueLink={this.linkState('name')} />
                  </div>
                  <div className={"form-group" + (this.state.errors.number ? " has-error" : "")}>
                    <label className="control-label" htmlFor="number">{this.props.intl.formatMessage({id: 'organization.number'})}:</label>
                    <LinkedInput type="text" className="form-control" id="number" valueLink={this.linkState('number')} />
                  </div>
                  <div className={"form-group" + (this.state.errors.address ? " has-error" : "")}>
                    <label className="control-label" htmlFor="address">{this.props.intl.formatMessage({id: 'organization.address'})}:</label>
                    <LinkedInput type="text" className="form-control" id="address" valueLink={this.linkState('address')} />
                  </div>
                  <div className={"form-group" + (this.state.errors.postal ? " has-error" : "")}>
                    <label className="control-label" htmlFor="postal">{this.props.intl.formatMessage({id: 'organization.postal'})}:</label>
                    <LinkedInput type="text" className="form-control" id="postal" valueLink={this.linkState('postal')} />
                  </div>
                  <div className={"form-group" + (this.state.errors.city ? " has-error" : "")}>
                    <label className="control-label" htmlFor="city">{this.props.intl.formatMessage({id: 'organization.city'})}:</label>
                    <LinkedInput type="text" className="form-control" id="city" valueLink={this.linkState('city')} />
                  </div>
                  <div className={"form-group" + (this.state.errors.contact ? " has-error" : "")}>
                    <label className="control-label" htmlFor="contact">{this.props.intl.formatMessage({id: 'organization.contact'})}:</label>
                    <LinkedInput type="text" className="form-control" id="contact" valueLink={this.linkState('contact')} />
                  </div>
                  <div className={"form-group" + (this.state.errors.usernameprefix ? " has-error" : "")}>
                    <label className="control-label" htmlFor="usernameprefix">{this.props.intl.formatMessage({id: 'organization.usernameprefix'})}:</label>
                    <LinkedInput type="text" className="form-control" id="usernameprefix" valueLink={this.linkState('usernameprefix')} />
                    <div className={"text-danger" + (this.state.usernameprefixexists ? "" : " hidden")}><small>{this.props.intl.formatMessage({id: 'organization.usernamePrefixExists'})}</small></div>
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="type">{this.props.intl.formatMessage({id: 'organization.type'})}:</label>
                    <select className="form-control" id="type" value={this.state.type} onChange={this.selectChange.bind(this,"type")}>
                      <option value="customer">{this.props.intl.formatMessage({id: 'organization.customer'})}</option>
                      <option value="demo">{this.props.intl.formatMessage({id: 'organization.demo'})}</option>
                      <option value="internal">{this.props.intl.formatMessage({id: 'organization.internal'})}</option>
                    </select>
                  </div>
                  <div className="form-group checkbox ">
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('enableble')}/>{this.props.intl.formatMessage({id: 'organization.enableble'})}</label>
                  </div>
                  <div className="form-group checkbox ">
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('enableserial')}/>{this.props.intl.formatMessage({id: 'organization.enableserial'})}</label>
                  </div>
                  <button type="submit" className="btn btn-sm btn-primary" onClick={this.save}><i className="fa fa-check"></i> {this.props.intl.formatMessage({id: 'generic.save'})}</button>
                  <button type="submit" className={"pull-right btn btn-sm btn-danger" + (this.state.organizationid==0 ? " hidden" : "")} onClick={this.delete}><i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}</button>
                </div>

                <div className="col-sm-6">
                  <table className={"table table-hover" + (this.state.organizationid > 0 ? "" : " hidden")}>
                    <tbody>
                      <tr>
                        <th>{this.props.intl.formatMessage({id: 'organization.users'})}:</th>
                        <td>{this.state.stats.users}</td>
                      </tr>
                      <tr>
                        <th>{this.props.intl.formatMessage({id: 'organization.employees'})}:</th>
                        <td>{this.state.stats.employees}</td>
                      </tr>
                                          <tr>
                        <th>{this.props.intl.formatMessage({id: 'organization.trucks'})}:</th>
                        <td>{this.state.stats.trucks}</td>
                      </tr>
                      <tr>
                        <th>{this.props.intl.formatMessage({id: 'organization.departments'})}:</th>
                        <td>{this.state.stats.departments}</td>
                      </tr>
                    </tbody>
                  </table> 
                  <h2 className={(this.state.organizationid > 0 ? "" : " hidden")}>{this.props.intl.formatMessage({id: 'organization.files'})}</h2> 
                  <Dropzone onDrop={this.onDrop} multiple={false} className={"imagedropzone" + (this.state.organizationid > 0 ? "" : " hidden")}>
                    <div className={"text-center" + (this.state.fileerror ? "" : " hidden")}>
                      <i className="fa fa-exclamation-triangle fa-5x"></i><br/>
                      {this.props.intl.formatMessage({id: 'organization.uploaderror'})}
                      </div>
                 
                    <div className="text-center"><span className={"btn btn-primary btn-sm"} onClick={this.preventDefault}><i className={"fa fa-cloud"}></i> {this.props.intl.formatMessage({id: 'organization.upload'})}</span></div>
                  </Dropzone>
                  {this.state.files.map(function(file,index){
                        return (<div className="row">
                          <div className="col-sm-4">{file.filename}</div>
                          <div className="col-sm-4">{file.time}</div>
                          <div className="col-sm-2 text-center"><a href={file.url} className={"btn btn-primary btn-sm"} ><i className={"fa fa-download"}></i></a></div>
                          <div className="col-sm-2 text-center"><span onClick={self.deleteFile.bind(null,file.id)} className={"btn btn-default btn-sm"} ><i className={"fa fa-trash"}></i></span></div>
                        </div>);
                  })}
                </div>
              </div>    
            </form>
          </div>
        </div>
      </div>
		);
  }
});

module.exports = injectIntl(Organization);
