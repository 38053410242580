// React stuff
var React = require("react");
var auth = require("../utils/auth");
var Table = require("reactable").Table;
var Tr = require("reactable").Tr;
var Td = require("reactable").Td;
var Table = require("reactable").Table;
var ReactModal = require('react-modal');
var Typeahead = require('react-typeahead').Typeahead;
var DatePicker = require('react-datepicker');
var LinkedInput = require('react-linked-input');

var injectIntl = require("react-intl").injectIntl;

var LinkedStateMixin = require('react-addons-linked-state-mixin');
var createClass = require('create-react-class');

var Model = createClass({
  mixins: [LinkedStateMixin],
  getInitialState: function() {
    return {truck_groups:[],interval_period: null,deactivated: false,interval_time: null,interval_period: 'hours',groups:[],enableserial: false,enableble: false,truckid:0,errors: {},organizations:[],departments:[],models:[],optionModels: [],writtenModel: "",manufactor: "",serial: "",types:[],year: null,hours_date: "",
    manufactors: ["ACE","Atlet","AUSA","Balkancar","Baoli","BT","BYD","Carer","Caterpillar","Cesab","Chery","Clark","Crown","CTC","D&amp;D","Daewoo","Doosan","EP","Feeler","Fiat","GlobalPower","Godrej","Hamech","Hangcha","Heli","HMT","Huahe","Hyster","Hytsu","Hyundai","Italcarrelli","JCB","Jungheinrich","Kalmar","Komatsu","Linde","Lugli","Manitou","Miag","Mitsubishi","Mora","NEXEN","Nichiyu","Nissan","NuovaDetas","OMPIMESPO","Raniero","Rocla","Samsung","Samuk","Shangli","Sichelschmidt","SMV","Steinbock","STILL","Swaraj","Tailift","TCM","Toyota","UNICARRIERS","UTILEV","Voltas","Yale","Yang","CLP","Gensan","Inmesol","Tower Light","Generac"]
    };
  },
  getModelTypes: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_modeltypes",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({types: data.result});
     },
      error: function(data){
        console.log(data);
      }
    });
  },
  getModels: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_models",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          if(data.result == null){
            self.setState({models: [],customerDataError: false});
          }else{
            var models = [];
            var optionModels = [];
            data.result.map(function(model,idx){
              models.push(model);
              optionModels.push(model.model);
            });
            self.setState({models: models,optionModels: optionModels,customerDataError: false});
          }
        }else{
          self.setState({models: [],customerDataError: true});
        }
      },
      error: function(){
        self.setState({models: [],dataError: true});
      }
    });
  },
  getOrganizations: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_organizations",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({organizations: data.result});
      },
      error: function(){
        console.log(data);
      }
    });
  },
  getDepartments: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_departments",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({departments: data.result});
      },
      error: function(){
        console.log(data);
      }
    });
  },
  getGroups: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_employeegroups",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          if(data.result == null){
            self.setState({groups: [],customerDataError: false});
          }else{
            self.setState({groups: data.result,customerDataError: false});
          }
        }else{
          self.setState({groups: [],customerDataError: true});
        }
      },
      error: function(){
        self.setState({groups: [],dataError: true});
      }
    });
  },
  changeGroup: function (id){
    groups = this.state.truck_groups;
    idx = groups.indexOf(parseInt(id));
    if(idx > -1){
      groups.splice(idx,1);
    }else{
      groups.push(parseInt(id));
    }
    this.setState({truck_groups: groups});
  },
  getTruck: function(id){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_truck",
      data: JSON.stringify({id: id}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        console.log(parseInt(data.result.serialconnection),parseInt(data.result.ble));
        self.setState({
          serialconnection: parseInt(data.result.serialconnection),
          ble: parseInt(data.result.ble),
          deactivated: parseInt(data.result.deactivated),
          blename: data.result.blename,
          bleopentime: data.result.bleopentime/60,
          truckid: (parseInt(data.result.truckid) || 0),
          year: data.result.year,
          writtenModel: data.result.writtenModel,
          manufactor: data.result.manufactor,
          serial: data.result.serial,
          internalid: data.result.internalid,
          organization: data.result.organization,
          department: data.result.department,
          model_type: data.result.model_type,
          ownership: data.result.ownership,
          aquisition_date: (moment(data.result.aquisition_date).isValid() ? moment(data.result.aquisition_date.substr(0,10)) : ""),
          expiration_date: (moment(data.result.expiration_date).isValid() ? moment(data.result.expiration_date.substr(0,10)) : ""),
          capacity: data.result.capacity,
          lift: data.result.lift,
          height: data.result.height,
          hours: data.result.hours,
          interval_period: data.result.interval_period,
          interval_time: data.result.interval_time,
          hours_date: (moment(data.result.hours_date).isValid()? moment(data.result.hours_date.substr(0,10)) : ""),
          last_service: (moment(data.result.last_service).isValid() ? moment(data.result.last_service.substr(0,10)) : ""),
          service_agreement: data.result.service_agreement,
          servicepartner: data.result.servicepartner,
          truck_groups: data.result.truck_groups,
          expiration_date_service: (moment(data.result.expiration_date_service).isValid() ? moment(data.result.expiration_date_service.substr(0,10)) : "")
        },function(){
          self.state.organizations.map(function(org,idx){
            if(org.organizationid == self.state.organization){
              self.setState({enableble: org.enableble});
              self.setState({enableserial: org.enableserial});
            }
          });
        });

        self.refs.typeahead.setEntryText(data.result.writtenModel + " ");
        self.refs.typeaheadmanufactor.setEntryText(data.result.manufactor + " ");
        self.selectedModelInObject(data.result.model);
      },
      error: function(){
        console.log(data);
      }
    });
  },
  selectedModelInObject: function(id){
    var self = this;
    this.state.models.map(function(model,idx){
      if(model.modelid == id){
        self.setState({selectedModel: model});
      }
    });
  },
  componentDidMount: function(){
    if(auth.getRole() != "organization"){
      this.getOrganizations();
    }
    this.getGroups();
    this.getDepartments();
    this.getModels();
    this.getModelTypes();
    if(this.props.match.params.id){
      this.getTruck(this.props.match.params.id);
    }
  },
  qrcode: function (e){
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/qrcode_truck",
      data: JSON.stringify({id: this.state.truckid}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          var win = window.open(data.result, '_blank');
          win.focus();
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  save: function (e){
    e.preventDefault();
    this.setState({saving: true});
    var errors = {};
    // Check fields
    if(this.state.writtenModel.length < 3 && !this.state.selectedModel){
      errors.model = true;
    }
    if(this.state.manufactor.length < 3){
      errors.manufactor = true;
    }
    if(this.state.model_type && this.state.model_type.length < 3){
      errors.model_type = true;
    }
    if(this.state.serial.length < 3){
      errors.serial = true;
    }
    if(!this.state.organization && auth.getRole() == "admin"){
      errors.organization = true;
    }
    if(this.state.year < 1960 || !this.state.year){
      errors.year = true;
    }
    console.log(Object.keys(errors));
    if(Object.keys(errors).length == 0){
      var self = this;
      var data = {};
      if(this.state.truckid){
        data.id = this.state.truckid;
      }
      if(this.state.selectedModel){
        data.model = this.state.selectedModel.modelid;
      }else{
        data.manufactor = this.state.manufactor;
        data.type = this.state.writtenModel;
      }
      data.serial = this.state.serial;
      data.internalid = this.state.internalid;
      if(auth.getRole() == "admin" || auth.getRole() == "superadmin"){
        data.organization = this.state.organization;
      }
      data.truck_groups = this.state.truck_groups;
      data.department = this.state.department;
      data.year = this.state.year;
      data.model_type = this.state.model_type;
      data.ownership = this.state.ownership,
      data.aquisition_date = this.state.aquisition_date,
      data.expiration_date = this.state.expiration_date,
      data.capacity = this.state.capacity,
      data.lift = this.state.lift,
      data.height = this.state.height,
      data.hours = this.state.hours,
      data.hours_date = this.state.hours_date,
      data.last_service = this.state.last_service,
      data.service_agreement = this.state.service_agreement,
      data.servicepartner = this.state.servicepartner,
      data.expiration_date_service = this.state.expiration_date_service,
      data.ble = this.state.ble,
      data.serialconnection = this.state.serialconnection,
      data.deactivated = this.state.deactivated,
      data.blename = this.state.blename,
      data.bleopentime = this.state.bleopentime*60,
      data.interval_period = this.state.interval_period,
      data.interval_time = this.state.interval_time
      $.ajax({
        type: 'POST',
        url: "/system/save_truck",
        data: JSON.stringify(data),
        dataType: 'json',
        contentType: "application/json",
        success: function (data, textStatus, jqXHR) {
          if(data.status == "success"){
            self.props.history.push("/trucks");
          }
        },
        error: function(){
          console.log(data);
        }
      });
    }else{
      console.log(errors);
      this.setState({errors: errors,saving: false});
    }
  },
  delete: function (e){
    e.preventDefault();
    this.setState({deleteModal:true});
  },
  confirmDelete: function (e){
    var self = this;
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/delete_truck",
      data: JSON.stringify({id: this.state.truckid}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          self.props.history.push("/trucks");
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  closeModal: function(e){
    if(e){
      e.preventDefault();
    }
    this.setState({deleteModal:false});
  },
  selectModel: function(option){

    this.setState({selectedModel: option,manufactor: "STILL"});
    this.setState({model_type: option.type});
  },
  selectManufactor: function(val){
    this.setState({manufactor: val});
  },
  resetModel: function(e,elm){
    this.setState({writtenModel: e.target.value});
    if(this.state.selectedModel && e.target.value != this.state.selectedModel.model){
      this.setState({selectedModel: null});
      this.setState({model_type: null});
      if(this.state.manufactor == "STILL"){
        this.setState({manufactor: ""});
      }
    }
  },
  displayOption: function(option,index){
    return option.model;
  },
  handleAquisitionDateChange: function(date){
    console.log(date);
    this.setState({aquisition_date: date});
  },
  handleExpirationDateChange: function(date){
   this.setState({expiration_date: date});
  },
  handleHoursDateChange: function(date){
    this.setState({hours_date: date});
  },
  handleExpirationDateSercviceChange: function(date){
   this.setState({expiration_date_service: date});
  },
  handleLastServiceChange: function(date){
   this.setState({last_service: date});
  },
  selectChange: function(data,e){
    if(data=="department"){
      this.setState({department: e.target.value});
    }else if(data=="service_agreement"){
      this.setState({service_agreement: e.target.value});
    }else if(data=="model_type"){
      this.setState({model_type: e.target.value});
    }else if(data=="ownership"){
      this.setState({ownership: e.target.value});
    }else if(data=="interval_period"){
      this.setState({interval_period: e.target.value});
    }else if(data=="organization"){
      this.setState({organization: e.target.value});
      if(auth.getRole() == "admin" || auth.getRole() == "superadmin"){
        var enableBle = false;
        var enableSerial = false;
        this.state.organizations.map(function(org,idx){
          if(org.organizationid == e.target.value){
            enableBle = org.enableble;
            enableSerial = org.enableserial;
          }
        })
        this.setState({enableble: enableBle,enableserial: enableSerial});
      }
      
    }else{
      console.log("Unknown data",data);
    }
    data = e.target.value;
  },
  render: function() {
    var customers = [];
    var self = this;
    return (
      <div id="page-content-wrapper">
        <ReactModal ariaHideApp={false} isOpen={this.state.deleteModal} contentLabel={this.props.intl.formatMessage({id: 'truck.delete'})} onRequestClose={this.closeModal}>     
          <p>{this.props.intl.formatMessage({id: 'truck.deleteAreYouSure'})}</p>
          <button className='btn btn-sm btn-default' onClick={this.closeModal}>
              <i className="fa fa-times"></i> {this.props.intl.formatMessage({id: 'generic.close'})}
            </button>
            <button className='btn btn-sm btn-danger' onClick={this.confirmDelete}>
              <i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}
            </button>
        </ReactModal>   
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'truck.truck'})}<button type="button" className={"pull-right btn btn-sm btn-default" + (this.state.truckid==0 ? " hidden" : "")} onClick={this.qrcode}><i className="fa fa-qrcode"></i> {this.props.intl.formatMessage({id: 'truck.qrcode'})}</button>
           </h1>
            <form>
              <div className="row">
                <div className="col-sm-6">
                  <div className={"form-group" + (this.state.errors.model ? " has-error" : "")}>
                    <label className="control-label" htmlFor="model">{this.props.intl.formatMessage({id: 'truck.model'})}:</label>
                    <Typeahead customClasses={{input: "form-control"}} ref="typeahead" onKeyUp={this.resetModel} options={this.state.models} value={this.state.writtenModel} filterOption='model' displayOption={this.displayOption} maxVisible={10} onOptionSelected={this.selectModel}/>
                  </div>
                  <div className={"form-group" + (this.state.errors.manufactor ? " has-error" : "")}>
                    <label className="control-label" htmlFor="manufactor">{this.props.intl.formatMessage({id: 'truck.manufactor'})}:</label>
                    <LinkedInput type="text" className={"form-control" + (this.state.selectedModel!=null ? "" : " hidden")} id="manufactor" valueLink={this.linkState('manufactor')} readOnly={this.state.selectedModel!=null} />
                    <Typeahead customClasses={{input: "form-control" + (this.state.selectedModel!=null ? " hidden" : "")}} ref="typeaheadmanufactor" options={this.state.manufactors} value={this.state.manufactor} maxVisible={10} onOptionSelected={this.selectManufactor}/>
                  </div>
                  <div className={"form-group" + (this.state.errors.serial ? " has-error" : "")}>
                    <label className="control-label" htmlFor="serial">{this.props.intl.formatMessage({id: 'truck.serial'})}:</label>
                    <LinkedInput type="text" ref="serial" className="form-control" id="serial" valueLink={this.linkState('serial')} />
                  </div>
                  <div className={"form-group" + (this.state.errors.serial ? " has-error" : "")}>
                    <label className="control-label" htmlFor="serial">{this.props.intl.formatMessage({id: 'truck.checkinterval'})}:</label>
                    <div className="row">
                      <div className="col-sm-6">
                        <LinkedInput type="number" ref="interval_time" className="form-control" id="interval_time" valueLink={this.linkState('interval_time')} />
                      </div>
                      <div className="col-sm-6">
                        <select className="form-control" id="interval_period" value={this.state.interval_period} onChange={this.selectChange.bind(this,"interval_period")} disabled={this.state.interval_time==null||this.state.interval_time==0}>
                          <option value="hours">{this.props.intl.formatMessage({id: 'truck.interval_hours'})}</option>
                          <option value="days">{this.props.intl.formatMessage({id: 'truck.interval_days'})}</option>
                          <option value="weeks">{this.props.intl.formatMessage({id: 'truck.interval_weeks'})}</option>
                          <option value="years">{this.props.intl.formatMessage({id: 'truck.interval_years'})}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="control-label" htmlFor="internalid">{this.props.intl.formatMessage({id: 'truck.internalid'})}:</label>
                    <LinkedInput type="text" className="form-control" id="internalid" valueLink={this.linkState('internalid')} />
                  </div>
                  <div className={"form-group" + (this.state.errors.model_type ? " has-error" : "")}>
                    <label className="control-label" htmlFor="type">{this.props.intl.formatMessage({id: 'truck.type'})}:</label>
                    <select className="form-control" id="type" value={this.state.model_type} onChange={this.selectChange.bind(this,"model_type")} disabled={this.state.selectedModel!=null}>
                      <option></option>
                      {this.state.types.map(function(val,index){
                        return (<option value={val.identifier}  key={index}>{val.name}</option>);
                      })}
                    </select>
                  </div>
                  <div className={"form-group " + (this.state.errors.organization ? " has-error" : "") + (auth.getRole()=="organization"||auth.getRole()=="department" ? " hidden" : "")}>
                    <label className="control-label" htmlFor="organization">{this.props.intl.formatMessage({id: 'truck.organization'})}:</label>
                    <select className="form-control" id="organization" value={this.state.organization} onChange={this.selectChange.bind(this,"organization")}>
                      <option></option>
                      {this.state.organizations.map(function(val,index){
                        return (<option value={val.organizationid} key={index}>{val.name}</option>);
                      })}
                    </select>
                  </div>
                  <div className={"form-group " + (this.state.errors.department ? " has-error" : "") + (auth.getRole()=="department" ? " hidden" : "")}>
                    <label className="control-label" htmlFor="department">{this.props.intl.formatMessage({id: 'truck.department'})}:{auth.getRole()=="department"}</label>
                    <select className="form-control" id="department" value={this.state.department} onChange={this.selectChange.bind(this,"department")}>
                      <option></option>
                      {this.state.departments.map(function(val,index){
                        if(val.organization==self.state.organization || auth.getRole()=="organization"){
                          return (<option value={val.departmentid}  key={index}>{val.name}</option>);
                        }else{
                          return;
                        }
                      })}
                    </select>
                  </div>
                  <div className="form-group checkbox ">
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('deactivated')}/>{this.props.intl.formatMessage({id: 'truck.deactivated'})}</label>
                  </div>
                </div>
              </div>
              <div className={"row " + ((this.state.enableble||(auth.getRole()=="organization"&&auth.getEnableble()=="1"))&&!this.state.serialconnection ? "" : "hidden")}>
                <div className="col-sm-6">
                  <h2>{this.props.intl.formatMessage({id: 'truck.ble'})}</h2>
                  <div className="form-group checkbox ">
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('ble')}/>{this.props.intl.formatMessage({id: 'truck.enableble'})}</label>
                  </div>
                  <div className={"form-group " + (this.state.ble ? "" : "hidden")}>
                    <label className="control-label" htmlFor="blename">{this.props.intl.formatMessage({id: 'truck.blename'})}:</label>
                    <LinkedInput type="text" ref="blename" className="form-control" id="blename" valueLink={this.linkState('blename')} />
                  </div>
                  <div className={"form-group " + (this.state.ble ? "" : "hidden")}>
                    <label className="control-label" htmlFor="bleopentime">{this.props.intl.formatMessage({id: 'truck.bleopentime'})}:</label>
                    <LinkedInput type="number" ref="bleopentime" className="form-control" id="bleopentime" valueLink={this.linkState('bleopentime')} />
                  </div>
                </div>  
              </div>    
              <div className={"row " + ((this.state.enableserial||(auth.getRole()=="organization"&&auth.getEnableserial()=="1"))&&!this.state.ble ? "" : "hidden")}>
                <div className="col-sm-6">
                  <h2>{this.props.intl.formatMessage({id: 'truck.serialconnection'})}</h2>
                  <div className="form-group checkbox">
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('serialconnection')}/>{this.props.intl.formatMessage({id: 'truck.enableserialconnection'})}</label>
                  </div>
                </div>  
              </div>    
              <div className="row">
                <div className="col-sm-6">
                  <h2>{this.props.intl.formatMessage({id: 'truck.equipment'})}</h2>
                  <div className={"form-group" + (this.state.errors.year ? " has-error" : "")}>
                    <label className="control-label" htmlFor="year">{this.props.intl.formatMessage({id: 'truck.year'})}:</label>
                    <LinkedInput type="text" ref="year" className="form-control" id="year" valueLink={this.linkState('year')} />
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="ownership">{this.props.intl.formatMessage({id: 'truck.ownership'})}:</label>
                    <select className="form-control" id="ownership" value={this.state.ownership} onChange={this.selectChange.bind(this,"ownership")}>
                      <option></option>
                      <option value="owned">{this.props.intl.formatMessage({id: 'truck.owned'})}</option>
                      <option value="leased">{this.props.intl.formatMessage({id: 'truck.leased'})}</option>
                      <option value="rent">{this.props.intl.formatMessage({id: 'truck.rent'})}</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="aquisition_date">{this.props.intl.formatMessage({id: 'truck.aquisitiondate'})}:</label>
                    <DatePicker id="aquisition_date" selected={this.state.aquisition_date} onChange={this.handleAquisitionDateChange}/>
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="expiration_date">{this.props.intl.formatMessage({id: 'truck.expirationdate'})}:</label>
                    <DatePicker id="expiration_date" selected={this.state.expiration_date} onChange={this.handleExpirationDateChange}/>
                  </div>
                  <h2>{this.props.intl.formatMessage({id: 'truck.technic'})}</h2>
                  <div className="form-group">
                    <label className="control-label" htmlFor="capacity">{this.props.intl.formatMessage({id: 'truck.capacity'})}:</label>
                    <LinkedInput type="text" className="form-control" id="capacity" valueLink={this.linkState('capacity')} />
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="lift">{this.props.intl.formatMessage({id: 'truck.lift'})}:</label>
                    <LinkedInput type="text"  className="form-control" id="lift" valueLink={this.linkState('lift')} />
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="height">{this.props.intl.formatMessage({id: 'truck.height'})}:</label>
                    <LinkedInput type="text" className="form-control" id="height" valueLink={this.linkState('height')} />
                  </div>

                  <h2>{this.props.intl.formatMessage({id: 'employee.groups'})}</h2>
                  <div className={"form-group " + (this.state.groups.length > 0 ? "" : "hidden") }>
                    {
                        this.state.groups.map(function(value, index){
                            return (<div>
                                <input key={index} type="checkbox" onChange={self.changeGroup.bind(null,value.id)} checked={(self.state.truck_groups.indexOf(parseInt(value.id)) > -1)}/> {value.name}
                              </div>
                            );
                        }) 
                    }
                  </div>
                </div>
                <div className="col-sm-6">
                  <h2>{this.props.intl.formatMessage({id: 'truck.serviceandmaintenance'})}</h2>
                  <div className="form-group">
                    <label className="control-label" htmlFor="hours">{this.props.intl.formatMessage({id: 'truck.hours'})}:</label>
                    <LinkedInput type="text" className="form-control" id="hours" valueLink={this.linkState('hours')} />
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="hours_date">{this.props.intl.formatMessage({id: 'truck.hours_date'})}:</label>
                    <DatePicker id="hours_date" selected={this.state.hours_date} onChange={this.handleHoursDateChange}/>
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="last_service">{this.props.intl.formatMessage({id: 'truck.last_service'})}:</label>
                    <DatePicker id="last_service" selected={this.state.last_service} onChange={this.handleLastServiceChange}/>
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="service_agreement">{this.props.intl.formatMessage({id: 'truck.service_agreement'})}:</label>
                    <select className="form-control" id="service_agreement" value={this.state.service_agreement} onChange={this.selectChange.bind(this,"service_agreement")}>
                      <option></option>
                      <option value="full">{this.props.intl.formatMessage({id: 'truck.full'})}</option>
                      <option value="discount">{this.props.intl.formatMessage({id: 'truck.discount'})}</option>
                      <option value="none">{this.props.intl.formatMessage({id: 'truck.none'})}</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="hours">{this.props.intl.formatMessage({id: 'truck.servicepartner'})}:</label>
                    <LinkedInput type="text" className="form-control" id="servicepartner" valueLink={this.linkState('servicepartner')} />
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="expiration_date_service">{this.props.intl.formatMessage({id: 'truck.expiration_date_service'})}:</label>
                    <DatePicker id="expiration_date_service" selected={this.state.expiration_date_service} onChange={this.handleExpirationDateSercviceChange}/>
                  </div>
                </div>
              </div>       
              <button type="submit" disabled={this.state.saving} className="btn btn-sm btn-primary" onClick={this.save}><i className="fa fa-check"></i> {this.props.intl.formatMessage({id: 'generic.save'})}</button>&nbsp;
              <button type="submit" className={"pull-right btn btn-sm btn-danger" + (this.state.truckid==0 ? " hidden" : "")} onClick={this.delete}><i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}</button>
            </form>
            
          </div>
        </div>
      </div>
		);
  }
});

module.exports = injectIntl(Model);
