module.exports = {

  authCallback: function(res,callback){
    if (res.authenticated) {
      localStorage.authenticated = true;
      localStorage.name = res.name;
      localStorage.userid = res.id;
      localStorage.organization = res.organization;
      localStorage.role = res.role;
      localStorage.enableble = res.enableble;
    }else{
      localStorage.authenticated = false;
      localStorage.name = null;
      localStorage.userid = null;
      localStorage.organization = null;
      localStorage.role = null;
      localStorage.enableble = null;
    }
    if(callback){
      callback(res.authenticated);
    }
  },

  login: function(username,password,remember,callback){
    if(remember){
      localStorage.username = username;
    }else{
      localStorage.username = "";
    }
    authRequest(username, password, this.authCallback,callback);
  },

  logout: function () {
    localStorage.authenticated = false;
    localStorage.name = null;
    localStorage.userid = null;
    localStorage.organization = null;
    localStorage.role = null;
    localStorage.enableble = null;
    localStorage.enableserial = null;
    resetauth();
  },
  getName: function(){
    return localStorage.name;
  },
  getUserid: function(){
    return localStorage.userid;
  },
  getRole: function(){
    return localStorage.role;
  },
  loggedIn: function (callback) {
    checkAuth(this.authCallback,callback);
  },
  getOrganization: function(){
    return localStorage.organization;
  },
  getEnableble: function(){
    return localStorage.enableble;
  },
  getEnableserial: function(){
    return localStorage.enableserial;
  }
  

};

function checkAuth(cb,usercb) {
  url = "/system/isauthed";
  $.ajax({
    type: 'POST',
    url: url,
    dataType: 'json',
    contentType: "application/json",
    success: function (data, textStatus, jqXHR) {
      if(data.status == "success"){
        cb({
          authenticated: true,
          name: data.result.name,
          id: data.result.id,
          organization: data.result.organization,
          role: data.result.role,
          enableble: data.result.enableble,
          enableserial: data.result.enableserial
        },usercb);
      }else{
        cb({authenticated: false},usercb);
      }
    }
  });
}
function resetauth(cb,usercb) {
  url = "/system/resetauth";
  $.ajax({
    type: 'POST',
    url: url,
    dataType: 'json',
    contentType: "application/json",
  });
}

function authRequest(email, pass, cb,usercb) {
  url = "/system/auth";
  data = {"username": email,"password": pass};
  $.ajax({
    type: 'POST',
    url: url,
    dataType: 'json',
    data: JSON.stringify(data),
    contentType: "application/json",
    success: function (data, textStatus, jqXHR) {
      if(data.status == "success"){
        cb({
          authenticated: true,
          name: data.result.name,
          id: data.result.id,
          organization: data.result.organization,
          role: data.result.role,
          enableble: data.result.enableble,
        },usercb);
      }else{
        cb({authenticated: false},usercb);
      }
    }
  });
}
