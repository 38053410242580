// React stuff
var React = require("react");
var auth = require("../utils/auth");

var ReactModal = require('react-modal');


var injectIntl = require("react-intl").injectIntl;

var LinkedStateMixin = require('react-addons-linked-state-mixin');
var createClass = require('create-react-class');
var LinkedInput = require('react-linked-input');

var User = createClass({
  mixins: [LinkedStateMixin],
  getInitialState: function() {
    return {
      userid:0,name: '',username: '',new_password: false,organizations:[],departments:[],errors: {},daily_reports: false,role: (auth.getRole()=="superadmin" ? "admin" : "")
      
    };
  },
  getOrganizations: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_organizations",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({organizations: data.result});
     },
      error: function(){
        console.log(data);
      }
    });
  },
  getDepartments: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_departments",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({departments: data.result});
      },
      error: function(){
        console.log(data);
      }
    });
  },
  getUser: function(id){
    console.log(id,JSON.stringify({id: id}));
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_user",
      data: JSON.stringify({id: id}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({userid: (parseInt(data.result.userid) || 0),name: data.result.name,username: data.result.username,role: data.result.role,organization: data.result.organization,department: data.result.department,email:data.result.email,email_reports: data.result.email_reports,daily_reports: data.result.daily_reports});
     },
      error: function(data){
        console.log(data);
      }
    });
  },
  componentDidMount: function(){
    this.getOrganizations();
    this.getDepartments();
    if(this.props.match.params.id){
      this.getUser(this.props.match.params.id);
    }
  },
  handleOptionChange: function (changeEvent) {
    this.setState({
      role: changeEvent.target.value
    });
  },
  save: function (e){
    e.preventDefault();
    var errors = {};
    // Check fields
    if(this.state.name.length < 3){
      errors.name = true;
    }
    if(this.state.username.length < 3){
      errors.username = true;
    }
    if(this.state.role == ""){
      errors.role = true;
    }
    if((this.state.userid == 0 || this.state.new_password) && !this.state.password){
      errors.password = true;
    }
    if(this.state.role != "admin" && !this.state.organization){
      errors.organization = true;
    }
    if(Object.keys(errors).length == 0){
      var self = this;
      self.setState({usernameexists: false});
      $.ajax({
        type: 'POST',
        url: "/system/save_user",
        data: JSON.stringify({id: self.state.userid,name: self.state.name,username:self.state.username,role: self.state.role,organization: self.state.organization,department: self.state.department,email: self.state.email,email_reports: self.state.email_reports,daily_reports: self.state.daily_reports,password: this.state.password}),
        dataType: 'json',
        contentType: "application/json",
        success: function (data, textStatus, jqXHR) {
          if(data.status == "success"){

            self.props.history.push("/users");
            self.setState({userid: data.result});
          }else{
            self.setState({usernameexists: true});
          }
        },
        error: function(){
          console.log(data);
        }
      });
    }else{
      this.setState({errors: errors});
    }
  },
  delete: function (e){
    e.preventDefault();
    this.setState({deleteModal:true});
  },
  confirmDelete: function (e){
    e.preventDefault();
    var self = this;
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/delete_user",
      data: JSON.stringify({id: this.state.userid}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          self.props.history.pushState(null, "/users");
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  setDepartment: function(e){
    this.setState({department: e.target.value});
  },setOrganization: function(e){
    this.setState({organization: e.target.value});
  },
  closeModal: function(e){
    if(e){
      e.preventDefault();
    }
    this.setState({deleteModal:false});
  },
  render: function() {
    var customers = [];
    var self = this;
    return (
      <div id="page-content-wrapper">
        <ReactModal ariaHideApp={false} isOpen={this.state.deleteModal} contentLabel={this.props.intl.formatMessage({id: 'user.delete'})} onRequestClose={this.closeModal}>     
          <p>{this.props.intl.formatMessage({id: 'user.deleteAreYouSure'},{name: this.state.name})}</p>
          <button className='btn btn-sm btn-default' onClick={this.closeModal}>
              <i className="fa fa-times"></i> {this.props.intl.formatMessage({id: 'generic.close'})}
            </button>
            <button className='btn btn-sm btn-danger' onClick={this.confirmDelete}>
              <i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}
            </button>
        </ReactModal>   
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'user.user'})}</h1>
            <form>
              <div className="row">
                <div className="col-sm-6">
                  <div className={"form-group" + (this.state.errors.name ? " has-error" : "")}>
                    <label className="control-label" htmlFor="name">{this.props.intl.formatMessage({id: 'user.name'})}:</label>
                    <LinkedInput type="text" className="form-control" id="name" valueLink={this.linkState('name')} />
                  </div>
                  <div className={"form-group" + (this.state.errors.username ? " has-error" : "")}>
                    <label className="control-label" htmlFor="username">{this.props.intl.formatMessage({id: 'user.username'})}:</label>
                    <LinkedInput type="text" className="form-control" id="username" valueLink={this.linkState('username')}/>
                    <div className={"text-danger" + (this.state.usernameexists ? "" : " hidden")}><small>{this.props.intl.formatMessage({id: 'user.usernameexists'})}</small></div>
                  </div>
                  <div className={"form-group" + (this.state.errors.role ? " has-error" : "") + (auth.getRole()=="admin" ? "" : " hidden")}>
                    <label className="control-label" htmlFor="pwd">{this.props.intl.formatMessage({id: 'user.role'})}:</label>
                  </div>
                  <div className={"form-group" + (auth.getRole()=="admin" ? "" : " hidden")}>
                    <label className="radio-inline"><LinkedInput type="radio" name="role" value="admin" checked={this.state.role === 'admin'} 
                      onChange={this.handleOptionChange}/> {this.props.intl.formatMessage({id: 'user.admin'})} </label>
                    <label className="radio-inline"><LinkedInput type="radio" name="role" value="organization" checked={this.state.role === 'organization'} 
                      onChange={this.handleOptionChange}/> {this.props.intl.formatMessage({id: 'user.organization'})} </label>
                    <label className="radio-inline"><LinkedInput type="radio" name="role" value="department" checked={this.state.role === 'department'} 
                      onChange={this.handleOptionChange}/> {this.props.intl.formatMessage({id: 'user.department'})} </label>
                  </div>
                  <div className={"checkbox " + (this.state.userid==0 ? "hidden" : "")}>
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('new_password')}/> {this.props.intl.formatMessage({id: 'user.newpassword'})}</label>
                  </div>
                  <div className={"form-group " + (this.state.new_password||this.state.userid==0 ? "" : "hidden") + (this.state.errors.password ? " has-error" : "")}>
                    <label className="control-label" htmlFor="password">{this.props.intl.formatMessage({id: 'user.inputpassword'})}:</label>
                    <LinkedInput type="password" className="form-control" id="password" valueLink={this.linkState('password')} />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className={"form-group " + (this.state.role=='organization'||this.state.role=='department'||auth.getRole()=="superadmin" ? "" : "hidden") + (this.state.errors.organization ? " has-error" : "")}>
                    <label className="control-label" htmlFor="organization">{this.props.intl.formatMessage({id: 'user.organization'})}:</label>
                    <select className="form-control" id="organization" value={this.state.organization} onChange={this.setOrganization}>
                      <option></option>
                      {this.state.organizations.map(function(val,index){
                        if(auth.getRole()!="superadmin"||val.countryorg){
                          return (<option value={val.organizationid} key={index}>{val.name}</option>);
                        }
                      })}
                    </select>
                  </div>
                  <div className={"form-group " + (this.state.role=='department' ? "" : "hidden") + (this.state.errors.department ? " has-error" : "")}>
                    <label className="control-label" htmlFor="department">{this.props.intl.formatMessage({id: 'user.department'})}:</label>
                    <select className="form-control" id="department" value={this.state.department}  onChange={this.setDepartment}>
                      <option></option>
                      {this.state.departments.map(function(val,index){
                        if(val.organization==self.state.organization){
                          return (<option value={val.departmentid}  key={index}>{val.name}</option>);
                        }else{
                          return;
                        }
                      })}
                    </select>
                  </div>
                  <div className={"checkbox " + ((this.state.role=='organization'||this.state.role=='department') ? "" : "hidden")}>
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('email_reports')}/> {this.props.intl.formatMessage({id: 'user.email_reports'})}</label>
                  </div>
                  <div className={"checkbox " + ((this.state.role=='organization'||this.state.role=='department') ? "" : "hidden")}>
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('daily_reports')}/> {this.props.intl.formatMessage({id: 'user.daily_reports'})}</label>
                  </div>
                  <div className={"form-group " + (this.state.email_reports||this.state.daily_reports ? "" : "hidden")}>
                    <label className="control-label" htmlFor="email">{this.props.intl.formatMessage({id: 'user.email'})}:</label>
                    <LinkedInput type="email" className="form-control" id="email" valueLink={this.linkState('email')} />
                  </div>
                </div>
              </div>    
              <button type="submit" className="btn btn-sm btn-primary" onClick={this.save}><i className="fa fa-check"></i> {this.props.intl.formatMessage({id: 'generic.save'})}</button>
              <button type="submit" className={"pull-right btn btn-sm btn-danger" + (this.state.userid==0||auth.getUserid()==this.state.userid ? " hidden" : "")} onClick={this.delete}><i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}</button>
            </form>
          </div>
        </div>
      </div>
		);
  }
});

module.exports = injectIntl(User);
