// React stuff
var React = require("react");
var auth = require("../utils/auth");
var ReactModal = require('react-modal');
var DatePicker = require('react-datepicker');
var injectIntl = require("react-intl").injectIntl;

var createClass = require('create-react-class');

var ReactTable = require("react-table").default;

var QuestionnaireResult = createClass({
	getInitialState: function(){
    if(localStorage.period){
      startdate = moment().subtract(localStorage.period,"days");
    }else{
      startdate = moment().subtract("14","days");
    }
    enddate = moment();
    return {startdate: startdate,enddate:enddate,submissions: [],submissionModal: false,submission: {truck:{},employee:{},answers: []}};
  },
  componentDidMount: function(){
    this.getSubmissions();
  },
  getSubmissions: function(){
    var self = this;
    this.setState({fetching: true});
    $.ajax({
      type: 'POST',
      url: "/system/get_submissions",
      dataType: 'json',
      data: JSON.stringify({start: this.state.startdate,end: this.state.enddate}),
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          if(data.result == null){
            self.setState({submissions: [],fetching: false});
          }else{
            self.setState({submissions: data.result,fetching: false});
          }
        }else{
          self.setState({submissions: [],fetching: false});
        }
      },
      error: function(){
        self.setState({submissions: [],fetching: false});
      }
    });
  },
  export: function(e){
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/export_submissions",
      dataType: 'json',
      data: JSON.stringify({start: this.state.startdate,end: this.state.enddate}),
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          var win = window.open(data.result, '_blank');
          win.focus();
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  showSubmission: function(id,e){
  	e.preventDefault();
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_submission",
      data: JSON.stringify({id: id}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          if(data.result == null){
            self.setState({submission: {},submissionModal: false});
          }else{
            self.setState({submission: data.result,submissionModal: true});
          }
        }else{
          self.setState({submission: {},submissionModal: false});
        }
      },
      error: function(){
        self.setState({submission: {},submissionModal: false});
      }
    });
  },
  showPdf: function (id,e){
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/pdf_submission",
      data: JSON.stringify({id: id}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          var win = window.open(data.result, '_blank');
          win.focus();
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  showResults: function(e){
  	e.preventDefault();
  	this.setState({submissionModal: true});
  },
  closeModal: function(){
  	this.setState({submissionModal: false});
  },
  handleEnddateChange: function(date){
    this.setState({enddate: date},function(){
      this.setPeriod();
    });
  },
  handleStartdateChange: function(date){
    this.setState({startdate: date},function(){
      this.setPeriod();
    });
  },
  setPeriod: function(){
    diff = this.state.enddate.diff(this.state.startdate,'days');
    localStorage.period = diff;
    this.getSubmissions();
  },
  filter: function(filter,row){
    search = String(filter.value);
    value = String(row[filter.id]);
    if(filter.id == "truck_serial"){
      value = (row._original.truck_internalid ? row._original.truck_internalid + " / " : "") + row.truck_serial;
    }
    if(value.toLowerCase().indexOf(search.toLowerCase()) > -1){
      return true;
    }else{
      return false;
    }
  },
  render: function() {
    var self = this;
    columns = [{
      Header: self.props.intl.formatMessage({id: 'questionnaireresult.time'}),
      accessor: 'time',
    },
    {
      Header: self.props.intl.formatMessage({id: 'questionnaireresult.questionnaire'}),
      accessor: 'questionnaire_name',
      Cell: function(props){
        return (<span> {props.value} &nbsp; <i className={"fa fa-exclamation-triangle " + (props.original.answered_no ? "" : "hidden")}></i></span>);
      },
    },
    {
      Header: self.props.intl.formatMessage({id: 'questionnaireresult.truck'}),
      accessor: 'truck_serial',
      Cell: function(props){
        return (<span> {(props.original.truck_internalid ? props.original.truck_internalid + ' / ' : '') + props.original.truck_serial}</span>);
      },
    },
    {
      Header: self.props.intl.formatMessage({id: 'questionnaireresult.employee'}),
      accessor: 'employee_name',
    },
    {
      Header: "",
      sortable: false,
      filterable: false,
      accessor: 'submission_id',
      Cell: function(props){
        return (
          <button className='btn btn-sm btn-primary pull-right' onClick={self.showSubmission.bind(null,props.value)}>
	              				<i className="fa fa-search"></i> {self.props.intl.formatMessage({id: 'questionnaireresult.view'})}
	        </button>
        );
      },
    },
    {
      Header: "",
      sortable: false,
      filterable: false,
      accessor: 'submission_id',
      Cell: function(props){
        return (
          <button className='btn btn-sm btn-block btn-primary pull-right' onClick={self.showPdf.bind(null,props.value)}>
          <i className="fa fa-file"></i> {self.props.intl.formatMessage({id: 'questionnaireresult.pdf'})}
        </button>
        );
      },
    }]
    submissions = [];
    var self = this;
    this.state.submissions.map(function(val,idx){
      if(self.state.enddate.isSameOrAfter(moment(val.time)) && moment(val.time).isSameOrAfter(self.state.startdate)){
        submissions.push(val);
      }
    });
    return (
    	<div id="page-content-wrapper">
       <ReactModal ariaHideApp={false} isOpen={this.state.submissionModal} contentLabel={this.state.submission.name} onRequestClose={this.closeModal}>     
          	<div className="row">
          		<div className={this.state.submission.truck.image ? "col-sm-4" : "col-sm-6"}>
	          		<h4>{self.props.intl.formatMessage({id: 'questionnaireresult.truck'})}</h4>
	          		{this.state.submission.truck.manufactor} {this.state.submission.truck.writtenModel}<br/>
	          		{this.state.submission.truck.serial}<br/>
	          		{this.state.submission.truck.internalid}<br/>
          		</div>
              <div className={this.state.submission.truck.image ? "col-sm-4" : "hidden"}>
                <img src={this.state.submission.truck.image} className="img-responsive" />
              </div>
          		<div className={this.state.submission.truck.image ? "col-sm-4" : "col-sm-6"}>
	          		<h4>{self.props.intl.formatMessage({id: 'questionnaireresult.employee'})}</h4>
	          		{this.state.submission.employee.name}<br/>
	          		{this.state.submission.employee.employeeno}<br/>
	          		{this.state.submission.employee.mobile}<br/>
          		</div>
          	</div>
          	<hr/>
          	{this.state.submission.answers.map(function(answer,index){
          		var value = "";
              var image = "";
          		if(answer.type == "bool"){
          			if(answer.value == "1"){
									value = <span className={answer.value==answer.bool_positive ? "text-success" : "text-danger"}>{self.props.intl.formatMessage({id: 'questionnaireresult.yes'})}</span>
          			}else{
          				value = <span className={answer.value==answer.bool_positive ? "text-success" : "text-danger"}>{self.props.intl.formatMessage({id: 'questionnaireresult.no'})}</span>
          			}
                if(answer.extra && answer.action_handler == "image"){
                  image = <img src={answer.extra} className="img-responsive" />;
                  value = <div>{value}{image}</div>
                }
                if(answer.extra && answer.action_handler == "text"){
                  value = <div>{value}<br/>{answer.extra}</div>
                }
             	}else if(answer.type == "image"){
								value = <img src={answer.value} className="img-responsive" />
          		}else{
          			value = answer.value;
          		}
          		return (<div key={index}><h3>{answer.question}</h3><span>{value}</span></div>);
          	})}
            <button className='btn btn-sm btn-default' onClick={this.closeModal}>
              <i className="fa fa-times"></i> {self.props.intl.formatMessage({id: 'generic.close'})}
            </button>
        </ReactModal>
    	  <div className="container-fluid">
          <div className={"row text-center" + (this.state.fetching ? "" : " hidden")}>
            <i className="fa fa-spinner fa-spin fa-3x"></i>
          </div>
          <div className={"row" + (!this.state.fetching ? "" : " hidden")}>
            <div className="col-sm-3 col-sm-offset-3">
              <DatePicker id="startdate" dateFormat="DD/MM-Y" selected={this.state.startdate} onChange={this.handleStartdateChange}/>
            </div>
            <div className="col-sm-3">
              <DatePicker id="enddate" dateFormat="DD/MM-Y" selected={this.state.enddate} onChange={this.handleEnddateChange}/>
            </div>
            <div className="col-sm-3">
              <button onClick={this.export} className="pull-right btn btn-xs btn-default" ><i className="fa fa-table"></i> {this.props.intl.formatMessage({id: 'trucks.export'})}</button>
            </div>
          </div>  
        	<div className={"col-sm-12" + (!this.state.fetching ? "" : " hidden")}>
            <ReactTable defaultFilterMethod={this.filter} getTrProps={function(e,e2){
              res = {};
              res.className = e.className;
              if(typeof(e2) != "undefined"){
                res.className = e.className + " " + e2.original.color;
              }
              return res;
            }} data={submissions} columns={columns} defaultPageSize={25} className="-striped -highlight" showPageSizeOptions={false} filterable={true} nextText={self.props.intl.formatMessage({id: 'table.next'})} previousText={self.props.intl.formatMessage({id: 'table.previous'})} pageText={self.props.intl.formatMessage({id: 'table.page'})} ofText={self.props.intl.formatMessage({id: 'table.of'})} noDataText={self.props.intl.formatMessage({id: 'table.nodata'})}/>
		  		</div>
		  	</div>
    	</div>
  	);
  }
});

module.exports = injectIntl(QuestionnaireResult);
