// React stuff
var React = require("react");

var injectIntl = require("react-intl").injectIntl;

var createClass = require('create-react-class');

var AppRedirect = createClass({
  getMobileOperatingSystem: function() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
  },

  componentDidMount: function(){
    setTimeout(function() {
      if(this.getMobileOperatingSystem() == "Android"){
        document.getElementById("androidlink").click();
      }else if(this.getMobileOperatingSystem() == "iOS" ){
        document.getElementById("applelink").click();
      }else{
       // alert(this.getMobileOperatingSystem());
      }
    }.bind(this), 3000);
    // Wait for 3 seconds and redirect
  },
  render: function() {

    return (
      <div id="page-content-wrapper text-center">
        <img src="/assets/img/logo.png" className="center-block img-responsive"/>
        <a id="androidlink" href='https://play.google.com/store/apps/details?id=dk.codehero.still'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' className="center-block img-responsive"/></a>
        <a id="applelink" href='https://itunes.apple.com/dk/app/still-preop/id1206366774?ls=1&mt=8'><img alt='Get it on iTunes' src='/assets/img/iTunes.png' className="center-block img-responsive"/></a>
      </div>
		);
  }
});

module.exports = injectIntl(AppRedirect);
