// React stuff
var React = require("react");
var auth = require("../utils/auth");
var ReactTable = require("react-table").default;


var injectIntl = require("react-intl").injectIntl;
var createClass = require('create-react-class');

var Models = createClass({
  getInitialState: function(){
    return {models: [],types:[]};
  },
  componentDidMount: function(){
    this.getModelTypes();
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_models",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          if(data.result == null){
            self.setState({models: [],customerDataError: false});
          }else{
            self.setState({models: data.result,customerDataError: false});
          }
        }else{
          self.setState({models: [],customerDataError: true});
        }
      },
      error: function(){
        self.setState({models: [],dataError: true});
      }
    });
  },
  getModelTypes: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_modeltypes",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({types: data.result});
     },
      error: function(){
        console.log(data);
      }
    });
  },
  lookupModelType: function(identifier){
    for(i in this.state.types){
      if(this.state.types[i].identifier == identifier){
        return this.state.types[i].name;
      }
    }
    return " ";
  },
  filter: function(filter,row){
    if(filter.id == "model"){
      search = String(filter.value);
      value = String(row[filter.id]);
      if(value.toLowerCase().indexOf(search.toLowerCase()) > -1){
        return true;
      }else{
        return false;
      }
    }else if(filter.id == "type"){
      search = String(filter.value);
      value = this.lookupModelType(row[filter.id]);
      console.log(search,value);
      if(value.toLowerCase().indexOf(search.toLowerCase()) > -1){
        return true;
      }else{
        return false;
      }
    }else{
      console.log("Missing filter logic for " + filter.id);
    }
  },
  render: function() {
    var self = this;
    columns = [{
      Header: self.props.intl.formatMessage({id: 'models.model'}),
      accessor: 'model',
    },
    {
      Header: self.props.intl.formatMessage({id: 'models.type'}),
      accessor: 'type',
      Cell: function(props){
        return (<span>{self.lookupModelType(props.value)}</span>);     
      },
    },
    {
      Header: "",
      sortable: false,
      filterable: false,
      accessor: 'modelid',
      Cell: function(props){
        return (<a href={"/model/" + props.value} className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-pencil"></i> {self.props.intl.formatMessage({id: 'users.edit'})}</a>)
      },
    }]
    return (
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'models.models'})}<a href="/model/" className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-plus"></i> {this.props.intl.formatMessage({id: 'models.createModel'})}</a></h1>
            <div className="col-sm-12">
              <ReactTable defaultFilterMethod={this.filter} data={this.state.models} columns={columns} defaultPageSize={25} className="-striped -highlight" showPageSizeOptions={false} filterable={true} nextText={self.props.intl.formatMessage({id: 'table.next'})} previousText={self.props.intl.formatMessage({id: 'table.previous'})} pageText={self.props.intl.formatMessage({id: 'table.page'})} ofText={self.props.intl.formatMessage({id: 'table.of'})} noDataText={self.props.intl.formatMessage({id: 'table.nodata'})}/>
            </div>
          </div>
        </div>
      </div>
		);
  }
});

module.exports = injectIntl(Models);
