// React stuff
var React = require("react");
var auth = require("../utils/auth");
var ReactModal = require('react-modal');
var injectIntl = require("react-intl").injectIntl;
var LinkedInput = require('react-linked-input');

var LinkedStateMixin = require('react-addons-linked-state-mixin');

var createClass = require('create-react-class');

var User = createClass({
  mixins: [LinkedStateMixin],
  getInitialState: function() {
    return {employeeid:0,name: '',mobile: '',welcome_sms: true,employeeno: "",errors: {},usernameprefix: "",groups: [],employee_groups:[]};
  },
  getGroups: function(){
      var self = this;
      $.ajax({
        type: 'POST',
        url: "/system/get_employeegroups",
        dataType: 'json',
        contentType: "application/json",
        success: function (data, textStatus, jqXHR) {
          if(data.status == "success"){
            if(data.result == null){
              self.setState({groups: [],customerDataError: false});
            }else{
              self.setState({groups: data.result,customerDataError: false});
            }
          }else{
            self.setState({groups: [],customerDataError: true});
          }
        },
        error: function(){
          self.setState({groups: [],dataError: true});
        }
      });
  },
  getOrganization: function(id){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_organization",
      data: JSON.stringify({id: id}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({usernameprefix: data.result.usernameprefix});
        if(self.props.match.params.id){
          self.getEmployee(self.props.match.params.id);
        }
     },
      error: function(){
        console.log(data);
      }
    });
  },
  getEmployee: function(id){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_employee",
      data: JSON.stringify({id: id}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        var replace = "^" + self.state.usernameprefix + "\.";
        var re = new RegExp(replace,"g");
        self.setState({employeeid: (parseInt(data.result.employeeid) || 0),name: data.result.name,mobile: data.result.mobile,employeeno: data.result.employeeno, username: (data.result.username ? data.result.username.replace(re,"") : ""),employee_groups: data.result.employee_groups,welcome_sms: false});
     },
      error: function(){
        console.log(data);
      }
    });
  },
  qrcode: function (e){
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/qrcode_employee",
      data: JSON.stringify({id: this.state.employeeid}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          var win = window.open(data.result, '_blank');
          win.focus();
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  componentDidMount: function(){
    this.getOrganization();
    this.getGroups();
  },
  save: function (e){
    e.preventDefault();
    var errors = {};
    // Check fields
    if(this.state.name.length < 3){
      errors.name = true;
    }
    var re = /^(\d{9}|\d{8}|\+\d{9,16})$/i;
    if(!re.test(this.state.mobile) && this.state.mobile){
      errors.mobile = true;
    }else if(!this.state.mobile && !this.state.username){
      errors.mobileusernane = true;
    }
    if(Object.keys(errors).length == 0){
      var self = this;
      self.setState({mobileexists: false});
      $.ajax({
        type: 'POST',
        url: "/system/save_employee",
        data: JSON.stringify({id: self.state.employeeid,name: self.state.name,employee_groups: self.state.employee_groups,mobile:self.state.mobile,employeeno: self.state.employeeno,welcome_sms: self.state.welcome_sms,password: self.state.password,username: (self.state.username ? (self.state.usernameprefix + "." + self.state.username) : "")}),
        dataType: 'json',
        contentType: "application/json",
        success: function (data, textStatus, jqXHR) {
          if(data.status == "success"){
            self.props.history.push("/employees");
          }else if(data.result == "mobile exists"){
            self.setState({mobileexists: true,errors: {mobile: true}});
          }else if(data.result == "username exists"){
            self.setState({usernameexists: true,errors: {username: true}});
          }
        },
        error: function(){
          console.log(data);
        }
      });
    }else{
      this.setState({errors: errors});
    }
  },
  delete: function (e){
    e.preventDefault();
    this.setState({deleteModal:true});
  },
  changeGroup: function (id){
    groups = this.state.employee_groups;
    idx = groups.indexOf(parseInt(id));
    if(idx > -1){
      groups.splice(idx,1);
    }else{
      groups.push(parseInt(id));
    }
    this.setState({employee_groups: groups});
  },
  confirmDelete: function (e){
    var self = this;
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/delete_employee",
      data: JSON.stringify({id: this.state.employeeid}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          self.props.history.push("/employees");
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  closeModal: function(e){
    if(e){
      e.preventDefault();
    }
    this.setState({deleteModal:false});
  },
  render: function() {
    var customers = [];
    var self = this;
    return (
      <div id="page-content-wrapper">
        <ReactModal ariaHideApp={false} isOpen={this.state.deleteModal} contentLabel={this.props.intl.formatMessage({id: 'employee.delete'})} onRequestClose={this.closeModal}>     
            <p>{this.props.intl.formatMessage({id: 'employee.deleteAreYouSure'},{name: this.state.name})}</p> 
            <button className='btn btn-sm btn-default' onClick={this.closeModal}>
              <i className="fa fa-times"></i> {this.props.intl.formatMessage({id: 'generic.close'})}
            </button>
            <button className='btn btn-sm btn-danger' onClick={this.confirmDelete}>
              <i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}
            </button>
        </ReactModal>
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'employee.employee'})}
            <button type="button" className={"pull-right btn btn-sm btn-default" + (this.state.employeeid==0 ? " hidden" : "")} onClick={this.qrcode}><i className="fa fa-qrcode"></i> {this.props.intl.formatMessage({id: 'truck.qrcode'})}</button>
            </h1>
            <form>
              <div className="row">
                <div className="col-sm-6">
                  <div className={"form-group" + (this.state.errors.name ? " has-error" : "")}>
                    <label className="control-label" htmlFor="name">{this.props.intl.formatMessage({id: 'employee.name'})}:</label>
                    <LinkedInput type="text" className="form-control" id="name" valueLink={this.linkState('name')} />
                  </div>
                  <div className={"form-group" + (this.state.errors.mobile||this.state.errors.mobileusernane ? " has-error" : "")}>
                    <label className="control-label" htmlFor="mobile">{this.props.intl.formatMessage({id: 'employee.cell'})}:</label>
                    <LinkedInput type="tel" className="form-control" id="mobile" valueLink={this.linkState('mobile')}/>
                    <div className={"text-danger" + (this.state.mobileexists ? "" : " hidden")}><small>{this.props.intl.formatMessage({id: 'employee.cellNumberExists'})}</small></div>
                  </div>
                  <div className={"form-group" + (this.state.errors.mobileusernane ? " has-error" : "")}>
                    <label className="control-label" htmlFor="username">{this.props.intl.formatMessage({id: 'employee.username'})}:</label>
                    <div className="input-group">
                      <span className="input-group-addon">{this.state.usernameprefix}.</span>
                      <LinkedInput type="tel" className="form-control" id="username" valueLink={this.linkState('username')}/>
                    </div>
                    <div className={"text-danger" + (this.state.usernameexists ? "" : " hidden")}><small>{this.props.intl.formatMessage({id: 'employee.usernameExists'})}</small></div>
                  </div>
                  <div className="form-group ">
                    <label className="control-label" htmlFor="employeeno">{this.props.intl.formatMessage({id: 'employee.employeeNumber'})}:</label>
                    <LinkedInput type="text" className="form-control" id="employeeno" valueLink={this.linkState('employeeno')} />
                  </div>
                  <div className="checkbox">
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('welcome_sms')}/> {this.props.intl.formatMessage({id: 'employee.sendSMS'})}</label>
                  </div>
                  <div className={"form-group " + (this.state.welcome_sms ? "" : "hidden") }>
                    <label className="control-label" htmlFor="password">{this.props.intl.formatMessage({id: 'employee.password'})}<br/><small>{this.props.intl.formatMessage({id: 'employee.emptyPasswordMessage'})}</small>:</label>
                    <LinkedInput type="text" className="form-control" id="password" valueLink={this.linkState('password')} />
                  </div>
                </div>
                <div className="col-sm-6">
                <div className={"form-group " + (this.state.groups.length > 0 ? "" : "hidden") }>
                    <label className="control-label" htmlFor="groups">{this.props.intl.formatMessage({id: 'employee.groups'})}:</label><br/>
                    {
                        this.state.groups.map(function(value, index){
                            return (<div>
                                <input type="checkbox" onChange={self.changeGroup.bind(null,value.id)} checked={(self.state.employee_groups.indexOf(parseInt(value.id)) > -1)}/> {value.name}
                              </div>
                            );
                        }) 
                    }
                  </div>
                </div>
              </div>    
              <button type="submit" className="btn btn-sm btn-primary" onClick={this.save}><i className="fa fa-check"></i> {this.props.intl.formatMessage({id: 'generic.save'})}</button>
              <button type="submit" className={"pull-right btn btn-sm btn-danger" + (this.state.employeeid==0 ? " hidden" : "")} onClick={this.delete}><i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
});

module.exports = injectIntl(User);
