module.exports={
    "mail": {
        "subject": "Rapport d'incident sur un mat\u00e9riel",
        "text": "Un employ\u00e9 a effectu\u00e9 une prise de poste sur un mat\u00e9riel\nL'employ\u00e9 a signal\u00e9 un probl\u00e8me sur le mat\u00e9riel\nVeuillez consulter  le fichier PDF \u201c",
        "uncheckedsubject": "Rapport d'incident sur un mat\u00e9riel",
        "uncheckedtext": "Voici l'aper\u00e7u quotidien des mat\u00e9riels dont la prise de poste n'a pas \u00e9t\u00e9 effectu\u00e9e. Veuillez consulter le fichier"
    },
    "app": {
        "welcome": {
            "teaser1": "Scanner le QR code",
            "teaser2": "Remplir la prise de poste",
            "teaser3": "Utiliser le mat\u00e9riel",
            "contactheader": "Contacter STILL",
            "contactcontent": "Des questions concernant la prise de poste, ou votre votre, veuillez contacter STILL.",
            "continuebtn": "Continuer pour vous connecter."
        },
        "login": {
            "teaser": "Bienvenue sur STILL PreOp",
            "logininfo": "Veuillez saisir vos identifiants",
            "mobile": "Identifiant",
            "password": "Mote de passe",
            "rememberme": "Se souvenir de moi",
            "login": "Connexion",
            "scanlogin": "QR Code",
            "forgottenpass": "Mot de passe oubli\u00e9?"
        },
        "scan": {
            "unknownserial": "Le n\u00b0 de s\u00e9rie n'a pas \u00e9t\u00e9 reconnu!",
            "surveyerror": "Aucun questionnaire trouv\u00e9!",
            "scancode": "Scanner le QR code",
            "seriallabel": "Sacanner ou saisir le n\u00b0 de s\u00e9rie",
            "continue": "Continuer",
            "qrcodeprompt": "Placer le QR code dans la zone \u00e0 scanner"
        },
        "survey": {
            "continuebtn": "Continuer",
            "truckok": "Le questionnaire a \u00e9t\u00e9 compl\u00e9t\u00e9!",
            "back": "Retour",
            "truckinfo": "Information mat\u00e9riel",
            "yes": "Oui",
            "no": "Non",
            "continue": "Continuer",
            "takeimage": "Prendre une photo",
            "textplaceholder": "Saire votre r\u00e9ponse",
            "numberplaceholder": "Saisir un nombre",
            "cancel": "Annuler",
            "choosequestionnaire": "Choisir un questionnaire",
            "blescanning": "Scan en cours\u2026",
            "bleerror": "Erreur bluetooth!",
            "blenotruck": "Ne trouve pas le mat\u00e9riel",
            "bleconnecting": "Connexion en cours\u2026",
            "blestarting": "Lancement\u2026",
            "bletrucknotstarted": "Le mat\u00e9rie ne peut pas \u00eatre utilis\u00e9",
            "blecouldntstarttruck": "Ne peut pas activer le mat\u00e9riel",
            "blecouldntconnect": "Ne peut pas connecter le mat\u00e9riel",
            "pushtitle": "Attention",
            "pushtext": "Le mat\u00e9riel va bient\u00f4t s'arr\u00eater",
            "truck": "Mat\u00e9riel",
            "model": "Mod\u00e8le",
            "manufactor": "Marque",
            "serial": "N\u00b0 de s\u00e9rie",
            "internalid": "N\u00b0 interne",
            "type": "Type",
            "organization": "Service",
            "department": "D\u00e9partement",
            "year": "Ann\u00e9e",
            "ownership": "Propri\u00e9t\u00e9",
            "owned": "Achat",
            "leased": "Location longue dur\u00e9e",
            "rent": "Location courte dur\u00e9e",
            "aquisitiondate": "Date d'acquisition",
            "expirationdate": "Date d'expiration",
            "capacity": "Capacit\u00e9",
            "lift": "Hauteur de levage (mm)",
            "height": "Hauteur hors tout (mm)",
            "serviceandmaintenance": "Service et maintenance",
            "hours": "Nombre d'heures",
            "hours_date": "Date du dernier relev\u00e9 horam\u00e8tre",
            "last_service": "Date du dernier service \/ maintenance",
            "service_agreement": "Contrat de service",
            "full": "Full Service",
            "discount": "Remise",
            "none": "Aucun accord",
            "servicepartner": "Partenaire service",
            "expiration_date_service": "Date d'expiration",
            "equipment": "Equipement",
            "technic": "Technique",
            "close_modal": "Fermer",
            "info": "Information",
            "electricalforklift": "Mat\u00e9riel \u00e9lectrique",
            "dieselforklift": "Mat\u00e9riel diesel",
            "gasforklift": "Mat\u00e9riel GPL",
            "reachtruck": "Chariot r\u00e9tractable",
            "4wayreachtruck": "Chariot r\u00e9tractable 4 voies",
            "pallettruck": "Transpalette",
            "doubledeckerpallettruck": "Transpalette double niveau",
            "highliftpallettruck": "Gerbeur",
            "standonpallettruck": "Transpalette \u00e0 conducteur debout",
            "sitonpallettruck": "Transpalette \u00e0 conducteur assis",
            "counterweightpallettruck": "Chariot frontal",
            "widetrackpallettruck": "Transpalette \u00e0 porte-\u00e0-faux",
            "orderpicker": "Pr\u00e9parateur de commande",
            "narrowtrackpallettruck": "Chariot \u00e0 all\u00e9e \u00e9troite",
            "tractor": "Tracteur",
            "other": "Autres"
        },
        "confirmation": {
            "header": "Merci pour votre saisie!"
        },
        "bletrucktime": {
            "headerresult": "Le mat\u00e9riel va s'arr\u00eater",
            "minutes": "minutes",
            "ok": "Continuer"
        }
    },
    "system": {
        "countrycode": "",
        "passwordsms": "Bienvenue en tant qu'utilisateur de STILL Pre-Op Check. Utilisez l'application syst\u00e9matiquement avant d'utiliser un mat\u00e9riel. Vous pouvez t\u00e9l\u00e9charger l'application \u00e0 l'adresse https:\/\/still-preop.com\/app\nVotre identifiant est votre n\u00b0 de t\u00e9l\u00e9phone\net votre mot de passe est: {password}\nCordialement. STILL \u201c",
        "remindersms": "Rappelez vous de remplir le questionnaire: {questionnaire} du mat\u00e9riel: {type}, avec le N) de s\u00e9rie: {serial}"
    },
    "web": {
        "generic": {
            "close": "Fermer",
            "delete": "Supprimer",
            "save": "Enregistrer",
            "copy": "Copier"
        },
        "login": {
            "wrongUsernameAndPass": "Identifiant ou mot de passe incorrect!",
            "usernamePlaceholder": "Identifiant",
            "passwordPlaceholder": "Mot de passe",
            "rememberMe": "Se souvenir de moi",
            "login": "Connexion"
        },
        "department": {
            "delete": "Supprimer le d\u00e9partement",
            "deleteAreYouSure": "Etes-vous s\u00fbr de vouloir supprimer {name}?",
            "department": "D\u00e9partement",
            "name": "Nom",
            "trucks": "Mat\u00e9riels",
            "working_days": "jours de travail",
            "monday": "Lundi",
            "tuesday": "Mardi",
            "wednesday": "Mercredi",
            "thursday": "Jeudi",
            "friday": "Vendredi",
            "saturday": "Samedi",
            "sunday": "Dimanche"
        },
        "departments": {
            "name": "Nom",
            "edit": "Modifier",
            "createDepartment": "Cr\u00e9er un d\u00e9partement",
            "departments": "D\u00e9partements"
        },
        "employee": {
            "delete": "Supprimer un employ\u00e9",
            "deleteAreYouSure": "Etes-vous s\u00fbr de vouloir supprimer {name}?",
            "employee": "Employ\u00e9",
            "name": "Nom",
            "cell": "N\u00b0 de t\u00e9l\u00e9phone",
            "cellNumberExists": "N\u00b0 de t\u00e9l\u00e9phone existe",
            "employeeNumber": "N\u00b0 employ\u00e9",
            "sendSMS": "Envoyer un SMS avec mot de passe",
            "password": "Mot de passe",
            "emptyPasswordMessage": "Si le mot de passe est vide, le syst\u00e8me g\u00e9n\u00e8re un mot de passe",
            "usernameExists": "L'identifiant existe d\u00e9j\u00e0",
            "username": "Identifiant",
            "groups": "Goupes employ\u00e9s"
        },
        "employees": {
            "employees": "Employ\u00e9s",
            "createEemployee": "Cr\u00e9er un employ\u00e9",
            "edit": "Modifier",
            "groups": "Groupes",
            "createGroup": "Cr\u00e9er un groupe"
        },
        "logout": {
            "thankYou": "Merci",
            "loggedout": "Vous \u00eates maintenant d\u00e9connect\u00e9!",
            "loginAgain": "Se connecter de nouveau"
        },
        "menu": {
            "countryusers": "Pays de l'utilisateur",
            "organizations": "Service",
            "models": "Mod\u00e8le",
            "trucks": "Mat\u00e9riels",
            "users": "Utilisateurs",
            "submits": "R\u00e9sultats",
            "questionnaires": "Questionnaires",
            "departmets": "D\u00e9partements",
            "employees": "Employ\u00e9s",
            "editprofile": "Modifier le profil",
            "logout": "D\u00e9connexion"
        },
        "model": {
            "model": "Mod\u00e8le",
            "upload": "T\u00e9l\u00e9chargement",
            "delete": "Supprimer",
            "deleteAreYouSure": "Etes-vous s\u00fbr de vouloir supprimer {name}?"
        },
        "models": {
            "model": "Mod\u00e8le",
            "models": "Mod\u00e8les",
            "type": "Type",
            "edit": "Modifier",
            "createModel": "Cr\u00e9er un mod\u00e8le"
        },
        "organization": {
            "enableble": "Utiliser des syst\u00e8mes Bluetooth",
            "delete": "Supprimer",
            "deleteAreYouSure": "Etes-vous s\u00fbr de vouloir supprimer {name}?",
            "organization": "Service",
            "name": "Nom",
            "number": "N\u00b0 client",
            "address": "Adresse",
            "postal": "Code postal",
            "city": "Ville",
            "contact": "Contact",
            "users": "Utilisateurs",
            "employees": "Employ\u00e9s",
            "trucks": "Mat\u00e9riels",
            "departments": "D\u00e9partements",
            "usernameprefix": "Pr\u00e9fixe pour identifiants, texte court, ex: stfr pour STILL France",
            "internal": "Interne",
            "demo": "D\u00e9mo",
            "customer": "Client"
        },
        "organizations": {
            "organizations": "Service",
            "name": "Nom",
            "usageReport": "Facture",
            "createOrganization": "Cr\u00e9er un service",
            "edit": "Modifier"
        },
        "questionnaire": {
            "number_updatefield": "Mise \u00e0 jour les horaires avec les valeurs saisies",
            "color": "Couleur",
            "delete": "Supprimer les questionnaires",
            "deleteAreYouSure": "Etes-vous s\u00fbr de vouloir supprimer {name}?",
            "questionnaire": "Questionnaires",
            "name": "Nom",
            "default": "Questionnaire standard",
            "shuffleQuestions": "Questions al\u00e9atoires",
            "department": "D\u00e9partement",
            "nocolor": "Pas de couleur",
            "grey": "Gris",
            "green": "Vert",
            "blue": "Bleu",
            "yellow": "Jaune",
            "red": "Rouge",
            "questions": "Question",
            "question": "Question",
            "type": "Type",
            "mandatory": "N\u00e9cessaire",
            "type_boolean": "Oui\/Non",
            "type_number": "Nombre",
            "type_text": "Texte",
            "type_image": "Photo",
            "type_boolean_positive": "Oui est une r\u00e9ponse positive",
            "type_boolean_reverse_positive": "Non est une r\u00e9ponse positive",
            "action_type_image": "Photo si r\u00e9ponse n\u00e9gative",
            "action_type_text": "Texte si r\u00e9ponse n\u00e9gative",
            "action_type_no_action": "Pas d'action",
            "enableble": "Cette question peut activer le syst\u00e8me Bluetooth",
            "copy": "Copier",
            "copyExplainer": "Voulez-vous cr\u00e9er une copie de ce questionnaire?",
            "alwaysemail": "Toujours envoyer les r\u00e9sultats par E-mail",
            "nostartonfalse": "Ne pas d\u00e9marrer le mat\u00e9riel en cas de mauvaises r\u00e9ponses",
            "primary": "Questionnaire principal"
        },
        "questionnaireresult": {
            "truck": "Mat\u00e9riel",
            "employee": "Employ\u00e9",
            "time": "Heure",
            "questionnaire": "Questionnaire",
            "view": "Afficher les r\u00e9ponses",
            "pdf": "PDF",
            "no": "Non",
            "yes": "Oui"
        },
        "questionnaires": {
            "questionnaires": "Questionnaires",
            "createQuestionnaire": "Cr\u00e9er un questionnaire",
            "name": "Nom",
            "questions": "Question",
            "default": "Questionnaire standard",
            "edit": "Modifier",
            "yes": "Oui"
        },
        "truck": {
            "ble": "Syst\u00e8me Bluetooth",
            "enableble": "Activer le syst\u00e8me Bluetooth",
            "blename": "Nom du syst\u00e8me",
            "bleopentime": "L'unit\u00e9 de temps doit \u00eatre en minutes.",
            "delete": "Supprimer",
            "deleteAreYouSure": "Etes-vous s\u00fbr de vouloir supprimer le chariot?",
            "truck": "Mat\u00e9riel",
            "qrcode": "QR Code",
            "model": "Mod\u00e8le",
            "manufactor": "Marque",
            "serial": "N\u00b0 de s\u00e9rie",
            "internalid": "N\u00b0 interne",
            "type": "Type",
            "organization": "Service",
            "department": "D\u00e9partement",
            "year": "Ann\u00e9e",
            "ownership": "Propri\u00e9t\u00e9",
            "owned": "Achat",
            "leased": "Location longue dur\u00e9e",
            "rent": "Location courte dur\u00e9e",
            "aquisitiondate": "Date d'acquisition",
            "expirationdate": "Date d'expiration",
            "capacity": "Capacit\u00e9",
            "lift": "Hauteur de levage (mm)",
            "height": "Hauteur hors tout (mm)",
            "serviceandmaintenance": "Service et maintenance",
            "hours": "Nombre d'heures",
            "hours_date": "Date du dernier relev\u00e9 horam\u00e8tre",
            "last_service": "Date du dernier service \/ maintenance",
            "service_agreement": "Contrat de service",
            "full": "Full Service",
            "discount": "Remise",
            "none": "Aucun accord",
            "servicepartner": "Partenaire service",
            "expiration_date_service": "Date d'expiration",
            "equipment": "Equipement",
            "technic": "Technique",
            "checkinterval": "Intervalle VGP",
            "interval_days": "Jours",
            "interval_weeks": "Semaines",
            "interval_hours": "Heures",
            "interval_years": "Ann\u00e9e",
            "deactivated": "D\u00e9sactiv\u00e9",
            "reminder": "Rappel"
        },
        "trucks": {
            "trucks": "Mat\u00e9riels",
            "createTruck": "Cr\u00e9er un chariot",
            "manufactor": "Marque",
            "type": "Type",
            "organization": "Service",
            "serial": "N\u00b0 de s\u00e9rie",
            "internalid": "N\u00b0 interne",
            "edit": "Modifier",
            "export": "Exporter",
            "all": "Tout",
            "checkmorethan3days": "Plus de 3 jours depuis la derni\u00e8re prise de poste",
            "checkwithin3days": "V\u00e9rifi\u00e9 moins de 3 jours",
            "checktoday": "V\u00e9rifi\u00e9 aujourd'hui"
        },
        "user": {
            "user": "Utilisateur",
            "delete": "Supprimer utilisateur",
            "deleteAreYouSure": "Etes-vous s\u00fbr de vouloir supprimer {name}?",
            "name": "Nom",
            "username": "Identifiant",
            "usernameexists": "L'identifiant existe d\u00e9j\u00e0",
            "role": "Fonction",
            "admin": "Administrateur",
            "organization": "Service",
            "department": "D\u00e9partement",
            "newpassword": "Nouveau mot de passe",
            "inputpassword": "D\u00e9finir le mot de passe",
            "email": "Email",
            "email_reports": "Envoi des rapports d'incident",
            "daily_reports": "Envoi journalier d'un aper\u00e7u des mat\u00e9riels non v\u00e9rifi\u00e9s"
        },
        "users": {
            "users": "Utilisateurs",
            "name": "Nom",
            "username": "Identifiant",
            "role": "Fonction",
            "organization": "Service",
            "edit": "Modifier",
            "createUser": "cr\u00e9er un utilisateur"
        },
        "welcome": {
            "truckstotal": "Total de mat\u00e9riels",
            "withservice": "Mat\u00e9riels avec prise de poste effectu\u00e9e aujourd'hui",
            "withoutservice": "Mat\u00e9riels sans prise de poste effectu\u00e9e"
        },
        "table": {
            "next": "Suivant",
            "previous": "Pr\u00e9c\u00e9dent",
            "page": "Page",
            "of": "de",
            "nodata": "Pas de donn\u00e9es disponibles"
        }
    }
}