// React stuff
var React = require("react");
var auth = require("../utils/auth");
var ReactModal = require('react-modal');

var injectIntl = require("react-intl").injectIntl;

var Dropzone = require('react-dropzone');

var LinkedStateMixin = require('react-addons-linked-state-mixin');
var LinkedInput = require('react-linked-input');
var createClass = require('create-react-class');

var Model = createClass({
  mixins: [LinkedStateMixin],
  getInitialState: function() {
    return {model:0,model: '',type: '',image:'',errors: {},types:[]};
  },
  getModelTypes: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_modeltypes",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({types: data.result});
     },
      error: function(){
      }
    });
  },
  getModel: function(id){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_model",
      data: JSON.stringify({id: id}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({modelid: (parseInt(data.result.modelid) || 0),model: data.result.model,type: data.result.type,image: data.result.image});
     },
      error: function(){
      }
    });
  },
  componentDidMount: function(){
    if(this.props.match.params.id){
      this.getModel(this.props.match.params.id);
    }
    this.getModelTypes();
  },
  onDrop: function (files) {
    var self = this;
    var reader = new FileReader();
    var file = files[0];
    reader.onload = function(upload) {
      self.setState({
        image: upload.target.result,
        imageuploaded: upload.target.result,
      });
    }
    reader.readAsDataURL(file);
  },
  preventDefault: function(e){
    if(e){
      e.preventDefault();
    }
  },
  save: function (e){
    e.preventDefault();
    var errors = {};
    // Check fields
    if(this.state.model.length < 3){
      errors.model = true;
    }
    if(Object.keys(errors).length == 0){
      var self = this;
      $.ajax({
        type: 'POST',
        url: "/system/save_model",
        data: JSON.stringify({id: self.state.modelid,model: self.state.model,type: self.state.type,imageuploaded: self.state.imageuploaded}),
        dataType: 'json',
        contentType: "application/json",
        success: function (data, textStatus, jqXHR) {
          if(data.status == "success"){

            self.props.history.push("/models");
            self.setState({organizationid: data.result});
          }
        },
        error: function(){
          console.log(data);
        }
      });
    }else{
      this.setState({errors: errors});
    }
  },
  delete: function (e){
    e.preventDefault();
    this.setState({deleteModal:true});
  },
  confirmDelete: function (e){
    e.preventDefault();
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/delete_model",
      data: JSON.stringify({id: this.state.modelid}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          self.props.history.pushState(null, "/models");
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  closeModal: function(e){
    if(e){
      e.preventDefault();
    }
    this.setState({deleteModal:false});
  },
  selectChange: function(data,e){
    if(data=="type"){
      this.setState({type: e.target.value});
    }else{
      console.log("Unknown data",data);
    }
    data = e.target.value;
  },
  render: function() {
    var customers = [];
    var self = this;
    return (
      <div id="page-content-wrapper">
       <ReactModal ariaHideApp={false} isOpen={this.state.deleteModal} contentLabel={this.props.intl.formatMessage({id: 'model.delete'})} onRequestClose={this.closeModal}>     
            <p>{this.props.intl.formatMessage({id: 'model.deleteAreYouSure'},{name: this.state.model})}</p>
            <button className='btn btn-sm btn-default' onClick={this.closeModal}>
              <i className="fa fa-times"></i> {this.props.intl.formatMessage({id: 'generic.close'})}
            </button>
            <button className='btn btn-sm btn-danger' onClick={this.confirmDelete}>
              <i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}
            </button>
        </ReactModal>
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'model.model'})}</h1>
            <form>
              <div className="row">
                <div className="col-sm-6">
                  <div className={"form-group" + (this.state.errors.Model ? " has-error" : "")}>
                    <label className="control-label" htmlFor="model">{this.props.intl.formatMessage({id: 'model.model'})}:</label>
                    <LinkedInput type="text" className="form-control" id="model" valueLink={this.linkState('model')} />
                  </div>
                  
                  <select className="form-control" id="type" value={this.state.type} onChange={this.selectChange.bind(this,"type")}>
                    <option></option>
                    {this.state.types.map(function(val,index){
                      return (<option value={val.identifier}  key={index}>{val.name}</option>);
                    })}
                  </select>
                </div>
                <div className="col-sm-6">
                  <Dropzone onDrop={this.onDrop} multiple={false} className="imagedropzone" accept={"image/png,image/jpg,image/jpeg"}>
                    <div className="text-center"><span className={"btn btn-primary btn-sm"} onClick={this.preventDefault}><i className={"fa fa-cloud"}></i> {this.props.intl.formatMessage({id: 'model.upload'})}</span></div>
                    <div className="col-md-offset-4 col-md-4">
                      <img className={"img-responsive" + (this.state.image ? "" : " hidden")} src={(this.state.image ? this.state.image : "")} />
                    </div>
                  </Dropzone>
                </div>
              </div>    
              <button type="submit" className="btn btn-sm btn-primary" onClick={this.save}><i className="fa fa-check"></i> {this.props.intl.formatMessage({id: 'generic.save'})}</button>
              <button type="submit" className={"pull-right btn btn-sm btn-danger" + (this.state.model==0 ? " hidden" : "")} onClick={this.delete}><i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}</button>
            </form>
          </div>
        </div>
      </div>
		);
  }
});

module.exports = injectIntl(Model);
