// React stuff
var React = require("react");
var auth = require("../utils/auth");
var ReactTable = require("react-table").default;


var injectIntl = require("react-intl").injectIntl;

var createClass = require('create-react-class');

var Employees = createClass({
  getInitialState: function(){
    return {employees: [],groups:[]};
  },
  componentDidMount: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_employees",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          if(data.result == null){
            self.setState({employees: [],customerDataError: false});
          }else{
            self.setState({employees: data.result,customerDataError: false});
          }
        }else{
          self.setState({employees: [],customerDataError: true});
        }
      },
      error: function(){
        self.setState({employees: [],dataError: true});
      }
    });
    if(auth.getRole()=="organization"){
      var self = this;
      $.ajax({
        type: 'POST',
        url: "/system/get_employeegroups",
        dataType: 'json',
        contentType: "application/json",
        success: function (data, textStatus, jqXHR) {
          if(data.status == "success"){
            if(data.result == null){
              self.setState({groups: [],customerDataError: false});
            }else{
              self.setState({groups: data.result,customerDataError: false});
            }
          }else{
            self.setState({employees: [],customerDataError: true});
          }
        },
        error: function(){
          self.setState({employees: [],dataError: true});
        }
      });
    }
  },
  filter: function(filter,row){
    if(filter.id == "name" || filter.id == "mobile" || filter.id == "employeeno"){
      search = String(filter.value);
      value = String(row[filter.id]);
      if(value.toLowerCase().indexOf(search.toLowerCase()) > -1){
        return true;
      }else{
        return false;
      }
    }else if(filter.id == "default"){
      search = String(filter.value);
      if(this.props.intl.formatMessage({id: 'questionnaires.yes'}).toLowerCase().indexOf(search) > -1){
        if(row[filter.id] == 1){
          return true;
        }
      }
    }else if(filter.id == "questions"){

      search = String(filter.value);
      value = row[filter.id].length;
      if(search == value){
        return true;
      }else{
        return false;
      }
    }else{
      console.log("Missing filter logic for " + filter.id);
    }
  },
  render: function() {
    var self = this;
    columns = [{
        Header: self.props.intl.formatMessage({id: 'employee.name'}),
        accessor: 'name',
      },
      {
        Header: self.props.intl.formatMessage({id: 'employee.cell'}),
        accessor: 'mobile',
      },
      {
        Header: self.props.intl.formatMessage({id: 'employee.employeeNumber'}),
        accessor: 'employeeno',
      },
      {
        Header: "",
        sortable: false,
        filterable: false,
        accessor: 'employeeid',
        Cell: function(props){
          return (<a href={"/employee/" + props.value} className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-pencil"></i> {self.props.intl.formatMessage({id: 'employees.edit'})}</a>)
        },
      }
    ];
    groupscolumns = [{
        Header: self.props.intl.formatMessage({id: 'employee.name'}),
        accessor: 'name',
      },
      {
        Header: "",
        sortable: false,
        filterable: false,
        accessor: 'groupid',
        Cell: function(props){
          return (<a href={"/employeegroup/" + props.value} className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-pencil"></i> {self.props.intl.formatMessage({id: 'employees.edit'})}</a>)
        },
      }
    ];
    return (
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'employees.employees'})} <a href="/employee/" className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-user-plus"></i> {this.props.intl.formatMessage({id: 'employees.createEemployee'})}</a></h1>
            <ReactTable data={this.state.employees} defaultFilterMethod={this.filter} columns={columns} defaultPageSize={15} className="-striped -highlight" showPageSizeOptions={false} filterable={true} nextText={self.props.intl.formatMessage({id: 'table.next'})} previousText={self.props.intl.formatMessage({id: 'table.previous'})} pageText={self.props.intl.formatMessage({id: 'table.page'})} ofText={self.props.intl.formatMessage({id: 'table.of'})} noDataText={self.props.intl.formatMessage({id: 'table.nodata'})}/>
          </div>
          <div className={"col-sm-12"}>
            <h1>{this.props.intl.formatMessage({id: 'employees.groups'})} <a href="/employeegroup/" className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-user-plus"></i> {this.props.intl.formatMessage({id: 'employees.createGroup'})}</a></h1>
            <ReactTable data={this.state.groups} defaultFilterMethod={this.filter} columns={groupscolumns} defaultPageSize={15} className="-striped -highlight" showPageSizeOptions={false} filterable={true} nextText={self.props.intl.formatMessage({id: 'table.next'})} previousText={self.props.intl.formatMessage({id: 'table.previous'})} pageText={self.props.intl.formatMessage({id: 'table.page'})} ofText={self.props.intl.formatMessage({id: 'table.of'})} noDataText={self.props.intl.formatMessage({id: 'table.nodata'})}/>
          </div>
        </div>
      </div>
		);
  }
});

module.exports = injectIntl(Employees);
