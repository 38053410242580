module.exports={
    "app": {
        "welcome": {
            "teaser1": "Zeskanuj kod QR",
            "teaser2": "Odpowiedz na pytania kontrolne",
            "teaser3": "U\u017cyj urz\u0105dzenia",
            "contactheader": "Skontaktuj si\u0119 z nami",
            "contactcontent": "Masz pytania dotycz\u0105e PreOp lub Twojego urz\u0105dzenia, skontaktuj si\u0119 z nami.",
            "continuebtn": "Przejd\u017a do logowania"
        },
        "login": {
            "teaser": "Witaj w STILL PreOp",
            "logininfo": "Wprowad\u017a swoje dane logowania",
            "mobile": "Numer telefonu kom\u00f3rkowego",
            "password": "Kod",
            "rememberme": "Zapami\u0119taj mnie",
            "login": "Zaloguj",
            "scanlogin": "Kod QR",
            "forgottenpass": "Zapomnia\u0142e\u015b has\u0142a?"
        },
        "scan": {
            "unknownserial": "Numer seryjny nie zosta\u0142 rozpoznany!",
            "surveyerror": "Nie znaleziono kwestionariuszy!",
            "scancode": "Zeskanuj kod QR",
            "seriallabel": "Zeskanuj lub wprowad\u017a numer seryjny w\u00f3zka",
            "continue": "Dalej",
            "qrcodeprompt": "Umie\u015b\u0107 kod QR w obszarze skanowania"
        },
        "survey": {
            "continuebtn": "Dalej",
            "truckok": "Raport zosta\u0142 zako\u0144czony.",
            "back": "Wstecz",
            "truckinfo": "Dane urz\u0105dzenia",
            "yes": "Tak",
            "no": "Nie",
            "continue": "Dalej",
            "takeimage": "Zr\u00f3b zdj\u0119cie",
            "textplaceholder": "Wpisz odpowied\u017a",
            "numberplaceholder": "Wpisz numer",
            "cancel": "Anuluj",
            "choosequestionnaire": "Wybierz kwestionariusz",
            "blescanning": "Skanowanie\u2026",
            "bleerror": "B\u0142\u0105d bluetooth!",
            "blenotruck": "Urz\u0105dzenie nie odnalezione",
            "bleconnecting": "\u0141\u0105czenie\u2026",
            "blestarting": "Rozpoczynanie\u2026",
            "bletrucknotstarted": "Uruchomienie urz\u0105dzenia mo\u017ce nie by\u0107 mo\u017cliwe",
            "blecouldntstarttruck": "Nie mo\u017cna uruchomi\u0107 urz\u0105dzenia",
            "blecouldntconnect": "Nie mo\u017cna po\u0142\u0105czy\u0107 si\u0119 z urz\u0105dzeniem",
            "pushtitle": "Ostrze\u017cenie",
            "pushtext": "Urz\u0105dzenie wkr\u00f3tce si\u0119 zatrzyma",
            "truck": "Urz\u0105dzenie",
            "model": "Model",
            "manufactor": "Marka",
            "serial": "Numer seryjny",
            "internalid": "Numer wewn\u0119trzny",
            "type": "Typ",
            "organization": "Organizacja",
            "department": "Obszar",
            "year": "Rok",
            "ownership": "Forma w\u0142asno\u015bci",
            "owned": "W\u0142asno\u015b\u0107",
            "leased": "Leasing",
            "rent": "Wynajem",
            "aquisitiondate": "Data rozpocz\u0119cia pracy",
            "expirationdate": "Przewidywane zako\u0144czenie pracy",
            "capacity": "Ud\u017awig",
            "lift": "Wysoko\u015b\u0107 podnoszenia",
            "height": "Wysoko\u015b\u0107 ca\u0142kowita",
            "serviceandmaintenance": "Przegl\u0105d",
            "hours": "Liczba motogodzin",
            "hours_date": "Data ostatniego odczytu motogodzin",
            "last_service": "Data ostatniego przegl\u0105du",
            "service_agreement": "Umowa serwisowa",
            "full": "Full Service",
            "discount": "Umowa zni\u017ckowa",
            "none": "Brak umowy",
            "servicepartner": "Partner serwisowy",
            "expiration_date_service": "Przewidywane zako\u0144czenie pracy",
            "equipment": "Osprz\u0119t",
            "technic": "Technik",
            "close_modal": "Zamknij",
            "info": "Informacje",
            "electricalforklift": "Urz\u0105dzenie z nap\u0119dem elektrycznym",
            "dieselforklift": "Urz\u0105dzenie Diesel",
            "gasforklift": "Udrz\u0105dzenie na gaz",
            "reachtruck": "W\u00f3zek Techniki Magazynowej",
            "4wayreachtruck": "4-kierunkowy w\u00f3zek wysokiego sk\u0142adowania",
            "pallettruck": "W\u00f3zek unosz\u0105cy",
            "doubledeckerpallettruck": "2-poziomowy w\u00f3zek unosz\u0105cy",
            "highliftpallettruck": "W\u00f3zek podno\u015bnikowy",
            "standonpallettruck": "W\u00f3zek podno\u015bnikowy z platform\u0105",
            "sitonpallettruck": "W\u00f3zek podno\u015bnikowy z fotelem",
            "counterweightpallettruck": "W\u00f3zek podno\u015bnikowy z przeciwwag\u0105",
            "widetrackpallettruck": "Dzeroki toy",
            "orderpicker": "W\u00f3zek do kompletacji",
            "narrowtrackpallettruck": "W\u00f3zek systemowy",
            "tractor": "Ci\u0105gnik",
            "other": "Inny"
        },
        "confirmation": {
            "header": "Dzi\u0119kujemy za wpis."
        },
        "bletrucktime": {
            "headerresult": "Urz\u0105dzenia zatrzyma si\u0119 za",
            "minutes": "minuty",
            "ok": "Dalej"
        }
    },
    "web": {
        "generic": {
            "close": "Zamknij",
            "delete": "Usu\u0144",
            "save": "Zapisz",
            "copy": "Kopiuj"
        },
        "login": {
            "wrongUsernameAndPass": "B\u0142\u0119dne has\u0142o lub nazwa u\u017cytkownika!",
            "usernamePlaceholder": "Nazwa u\u017cytkownika",
            "passwordPlaceholder": "Has\u0142o",
            "rememberMe": "Zapami\u0119taj dane logowania",
            "login": "Zaloguj"
        },
        "department": {
            "delete": "Usu\u0144 obszar",
            "deleteAreYouSure": "Czy na pewno chcesz usun\u0105\u0107 {nazwa}?",
            "department": "Obszar",
            "name": "Nazwa",
            "trucks": "Urz\u0105dzenia",
            "working_days": "Dni pracy",
            "monday": "Poniedzia\u0142ek",
            "tuesday": "Wtorek",
            "wednesday": "\u015aroda",
            "thursday": "Czwartek",
            "friday": "Pi\u0105tek",
            "saturday": "Sobota",
            "sunday": "Niedziela"
        },
        "departments": {
            "name": "Nazwa",
            "edit": "Edytuj",
            "createDepartment": "Dodaj obszar",
            "departments": "Obszary"
        },
        "employee": {
            "delete": "Usu\u0144 pracownika",
            "deleteAreYouSure": "Czy na pewno chcesz usun\u0105\u0107 {nazwa}?",
            "employee": "Pracownik",
            "name": "Imi\u0119",
            "cell": "Telefon kom\u00f3rkowy",
            "cellNumberExists": "Numer telefonu kom\u00f3rkowego istnieje",
            "employeeNumber": "Numer pracownika",
            "sendSMS": "Wy\u015blij SMS z kodem logowania",
            "password": "Kod",
            "emptyPasswordMessage": "Je\u015bli pozostawisz rubryk\u0119 kod pust\u0105, system wygeneruje kod automatycznie",
            "usernameExists": "Nazwa\/numer u\u017cytkownika ju\u017c istnieje",
            "username": "Nazwa u\u017cytkownika",
            "groups": "Grupy pracownik\u00f3w"
        },
        "employees": {
            "employees": "Pracownicy",
            "createEemployee": "Dodaj pracownika",
            "edit": "Edytuj",
            "groups": "Grupy pracownik\u00f3w",
            "createGroup": "Utw\u00f3rz grup\u0119"
        },
        "logout": {
            "thankYou": "Dzi\u0119kujemy",
            "loggedout": "Zosta\u0142e\u015b wylogowany!",
            "loginAgain": "Zaloguj si\u0119 ponownie"
        },
        "menu": {
            "countryusers": "U\u017cytkownicy krajowi",
            "organizations": "Organizacja",
            "models": "Modele",
            "trucks": "Urz\u0105dzenia",
            "users": "U\u017cytkownicy",
            "submits": "Wyniki",
            "questionnaires": "Kwestionariusze",
            "departmets": "Obszary",
            "employees": "Pracownicy",
            "editprofile": "Edytuj profil",
            "logout": "Wyloguj"
        },
        "model": {
            "model": "Model",
            "upload": "Wgraj",
            "delete": "Usu\u0144",
            "deleteAreYouSure": "Czy na pewno chcesz usun\u0105\u0107 {nazwa}?"
        },
        "models": {
            "model": "Model",
            "models": "Modele",
            "type": "Typ",
            "edit": "Edytuj",
            "createModel": "Dodaj model"
        },
        "organization": {
            "enableble": "U\u017cyj urz\u0105dze\u0144 bluetooth",
            "enableserial": "U\u017cyj urz\u0105dze\u0144 USB",
            "delete": "Usu\u0144",
            "deleteAreYouSure": "Czy na pewno chcesz usun\u0107 {nazwa}?",
            "organization": "Organizacja",
            "name": "Nazwa",
            "number": "Numer klienta",
            "address": "Adres",
            "postal": "Kod pocztowy",
            "city": "Miejscowo\u015b\u0107",
            "contact": "Osoba kontaktowa",
            "users": "U\u017cytkownicy",
            "employees": "Pracownicy",
            "trucks": "Urz\u0105dzenia",
            "departments": "Obszary",
            "usernameprefix": "STI-POL",
            "internal": "Wewn\u0119trzny",
            "demo": "Demo",
            "customer": "Klient"
        },
        "organizations": {
            "organizations": "Organizacja",
            "name": "Nazwa firmy",
            "usageReport": "P\u0142atno\u015bci",
            "createOrganization": "Dodaj organizacj\u0119",
            "edit": "Edytuj"
        },
        "questionnaire": {
            "number_updatefield": "Aktualizuj rozk\u0142ady o wprowadzone warto\u015bci",
            "color": "Kolor",
            "delete": "Usu\u0144 kwestionariusz",
            "deleteAreYouSure": "Czy na pewno chcesz usun\u0107 {nazwa}?",
            "questionnaire": "Kwestionariusz",
            "name": "Nazwa",
            "default": "Standardowy kwestionariusz",
            "shuffleQuestions": "Mieszaj pytania",
            "department": "Obszar",
            "nocolor": "Bez koloru",
            "grey": "Szary",
            "green": "Zielony",
            "blue": "Niebieski",
            "yellow": "\u017b\u00f3\u0142ty",
            "red": "Czerwony",
            "questions": "Pytanie",
            "question": "Pytanie",
            "type": "Typ",
            "mandatory": "Wymagane",
            "type_boolean": "Tak\/Nie",
            "type_number": "Numer",
            "type_text": "Tekst",
            "type_image": "Zdj\u0119cie",
            "type_boolean_positive": "\"Tak\" jest pozytywn\u0105 odpowiedzi\u0105",
            "type_boolean_reverse_positive": "\"Nie\" jest pozytywn\u0105 odpowiedzi\u0105",
            "action_type_image": "Zdj\u0119cie przy negatywnej odpowiedzi",
            "action_type_text": "Komentarz przy negatywnej odpowiedzi",
            "action_type_no_action": "Brak akcji",
            "enableble": "To pytanie mo\u017ce uruchomi\u0107 urz\u0105dzenie bluetooth",
            "copy": "Kopiuj",
            "copyExplainer": "Czy chcesz wykona\u0107 kopi\u0119 tego kwestionariusza?",
            "alwaysemail": "Zawsze wysy\u0142aj wyniki mailem.",
            "nostartonfalse": "Nie uruchamiaj urz\u0105dzenia po b\u0142\u0119dnej odpowiedzi.",
            "primary": "Podstawowy kwestionariusz"
        },
        "questionnaireresult": {
            "truck": "Urz\u0105dzenie",
            "employee": "Pracownik",
            "time": "Czas",
            "questionnaire": "Kwestionariusz",
            "view": "Poka\u017c odpowied\u017a",
            "pdf": "PDF",
            "no": "Nie",
            "yes": "Tak"
        },
        "questionnaires": {
            "questionnaires": "Kwestionariusze",
            "createQuestionnaire": "Dodaj kwestionariusz",
            "name": "Nazwa",
            "questions": "Pytanie",
            "default": "Standardowy kwestionariusz",
            "edit": "Edytuj",
            "yes": "Tak"
        },
        "truck": {
            "ble": "Urz\u0105dzenie bluetooth",
            "enableble": "Uruchom urz\u0105dzenie bluetooth",

            "serialconnection": "Urządzenie USB",
            "enableserialconnection": "Aktywuj urządzenie za pomocą urządzenia USB",
            "blename": "Nazwa urz\u0105dzenia",
            "bleopentime": "Jednostka czasowa musi zosta\u0107 uruchomiona w ci\u0105gu minut",
            "delete": "Usu\u0144",
            "deleteAreYouSure": "Czy na pewno chcesz usun\u0105\u0107 urz\u0105dzenie?",
            "truck": "Urz\u0105dzenie",
            "qrcode": "Kod QR",
            "model": "Model",
            "manufactor": "Marka",
            "serial": "Numer seryjny",
            "internalid": "Numer wewn\u0119trzny",
            "type": "Typ",
            "organization": "Organizacja",
            "department": "Obszar",
            "year": "Rok produkcji",
            "ownership": "Forma w\u0142\u0105sno\u015bci",
            "owned": "W\u0142asno\u015b\u0107",
            "leased": "Leasing",
            "rent": "Wynajem",
            "aquisitiondate": "Data rozpocz\u0119cia pracy",
            "expirationdate": "Przewidywane zako\u0144czenie pracy",
            "capacity": "Ud\u017awig",
            "lift": "Wysoko\u015b\u0107 podnoszenia (mm)",
            "height": "Wysoko\u015b\u0107 ca\u0142kowita (mm)",
            "serviceandmaintenance": "Naprawa i konserwacja",
            "hours": "Liczba motogodzin",
            "hours_date": "Data ostatniego odczytu motogodzin",
            "last_service": "Data ostatniej naprawy \/ przegl\u0105du",
            "service_agreement": "Umowa serwisowa",
            "full": "Full Service",
            "discount": "Umowa zni\u017ckowa",
            "none": "Brak umowy",
            "servicepartner": "Partner serwisowy",
            "expiration_date_service": "Termin nast\u0119pnego przegl\u0105du serwisowego",
            "equipment": "Wyposa\u017cenie",
            "technic": "Dane techniczne",
            "checkinterval": "Odst\u0119p czasu dla wykonania kontroli",
            "interval_days": "Dni",
            "interval_weeks": "Tygodnie",
            "interval_hours": "Godziny",
            "interval_years": "Lata",
            "deactivated": "Wy\u0142\u0105czony",
            "reminder": "Przypomnienie"
        },
        "trucks": {
            "trucks": "Urz\u0105dzenia",
            "createTruck": "Dodaj urz\u0105dzenie",
            "manufactor": "Marka",
            "type": "Typ",
            "organization": "Organizacja",
            "serial": "Numer seryjny",
            "internalid": "Numer wewn\u0119trzny",
            "edit": "Edytuj",
            "export": "Eksport",
            "all": "Wszystkie",
            "checkmorethan3days": "Sprawdzane ponad 3 dni temu",
            "checkwithin3days": "Sprawdzane w ci\u0105gu 3 dni",
            "checktoday": "Sprawdzone dzisiaj"
        },
        "user": {
            "user": "U\u017cytkownik",
            "delete": "Usu\u0144 u\u017cytkownika",
            "deleteAreYouSure": "Czy na pewno chcesz usun\u0105\u0107 {nazwa}?",
            "name": "Imi\u0119",
            "username": "Nazwa u\u017cytkownika",
            "usernameexists": "U\u017cytkownik ju\u017c istnieje",
            "role": "Rola",
            "admin": "Administrator",
            "organization": "Organizacja",
            "department": "Obszar",
            "newpassword": "Nowy kod",
            "inputpassword": "Ustaw has\u0142o",
            "email": "Email",
            "email_reports": "Otrzymuj raporty o b\u0142\u0119dach",
            "daily_reports": "Otrzymuj codzienne zestawienieurz\u0105dze\u0144 nie sprawdzonych"
        },
        "users": {
            "users": "U\u017cytkownicy",
            "name": "Imi\u0119",
            "username": "Nazwa u\u017cytkownika",
            "role": "Rola",
            "organization": "Organizacja",
            "edit": "Edytuj",
            "createUser": "Dodaj u\u017cytkownika"
        },
        "welcome": {
            "truckstotal": "Urz\u0105dzenia \u0142acznie",
            "withservice": "Urz\u0105dzenia z uko\u0144czon\u0105 inspekcj\u0105 w dniu dzisiejszym",
            "withoutservice": "Urz\u0105dzenia bez inspekcji"
        },
        "table": {
            "next": "Nast\u0119pna",
            "previous": "Poprzednia",
            "page": "Strona",
            "of": "z",
            "nodata": "Brak danych"
        }
    },
    "system": {
        "countrycode": "48",
        "passwordsms": "Witaj u\u017cytkowniku aplikacji STILL PreOp\nTwoja nazwa u\u017cytkownika to Tw\u00f3j numer telefonu\na Tw\u00f3j kod to: {password}\nPozdrawiamy. STILL \"",
        "remindersms": "Pami\u0119taj by wype\u0142ni\u0107 formularz: {questionnaire} na urz\u0105dzeniu: {type}, o numerze seryjnym: {serial}"
    },
    "0": "2",
    "mail": {
        "subject": "Raport b\u0142\u0119du w urz\u0105dzeniu",
        "text": "Pracownik uko\u0144czy\u0142 raport dotycz\u0105cy urz\u0105dzenia\nPracownik wskaza\u0142, \u017ce jest problem z urz\u0105dzeniem\nProsz\u0119 zobaczy\u0107 plik PDF ",
        "uncheckedsubject": "Raport b\u0142\u0119du w urz\u0105dzeniu",
        "uncheckedtext": "Dzienne zestawienie urz\u0105dze\u0144 niesprawdzonych. Dane w pliku."
    }
}