// React stuff
var React = require("react");
var auth = require("../utils/auth");
var ReactTable = require('react-table').default;


var injectIntl = require("react-intl").injectIntl;
var createClass = require('create-react-class');

var Trucks = createClass({
  getInitialState: function(){
    return {trucks: [],checktime: this.props.match.params.checked};
  },
  componentDidMount: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_trucks",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          if(data.result == null){
            self.setState({trucks: [],customerDataError: false});
          }else{
            self.setState({trucks: data.result,customerDataError: false});
          }
        }else{
          self.setState({trucks: [],customerDataError: true});
        }
      },
      error: function(){
        self.setState({trucks: [],dataError: true});
      }
    });
  },
  export: function(e){
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/truck_export",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          var win = window.open(data.result, '_blank');
          win.focus();
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  qrcode: function(e){
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/qrcode_truck",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          var win = window.open(data.result, '_blank');
          win.focus();
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  checked: function(checktime,e){
    e.preventDefault();
    this.setState({checktime: checktime});
  },
  filter: function(filter,row){
      search = String(filter.value);
      value = String(row[filter.id]);
      if(value.toLowerCase().indexOf(search.toLowerCase()) > -1){
        return true;
      }else{
        return false;
      }
    },
  render: function() {
    var trucks = [];
    columns = [{
      Header: this.props.intl.formatMessage({id: 'trucks.manufactor'}),
      accessor: 'manufactor',
    },
    {
      Header: this.props.intl.formatMessage({id: 'trucks.type'}),
      accessor: 'type',
    },
    {
      Header: this.props.intl.formatMessage({id: 'department.department'}),
      accessor: 'departmentname',
    },
    {
      Header: this.props.intl.formatMessage({id: 'trucks.serial'}),
      accessor: 'serial',
    },
    {
      Header: this.props.intl.formatMessage({id: 'trucks.internalid'}),
      accessor: 'internalid',
    },
    {
      Header: "",
      sortable: false,
      filterable: false,
      accessor: 'truckid',
      Cell: function(props){
        return (<a href={"/truck/" + props.value} className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-pencil"></i> {self.props.intl.formatMessage({id: 'trucks.edit'})}</a>)
     
      },
    }]
    if(this.state.trucks.length != 0){
      var self = this;
      this.state.trucks.map(function(truck,index){
        if(self.state.checktime!=null){
          if(self.state.checktime==0&&truck.last_check!=0){
            return;
          }
          if(self.state.checktime==1&&(truck.last_check < 1 || truck.last_check > 3)){
            return;
          }
          if(self.state.checktime==3&&truck.last_check < 3){
            return;
          }
        }
        trucks.push(
          truck
        );
      });
    }
    
    var self = this;
    return (
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'trucks.trucks'})} <button onClick={this.qrcode} className="pull-right btn btn-xs btn-default" ><i className="fa fa-qrcode"></i> {this.props.intl.formatMessage({id: 'truck.qrcode'})}</button> <button onClick={this.export} className="pull-right btn btn-xs btn-default" ><i className="fa fa-table"></i> {this.props.intl.formatMessage({id: 'trucks.export'})}</button> <a href="/truck/" className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-plus"></i> {this.props.intl.formatMessage({id: 'trucks.createTruck'})}</a></h1>
          </div>
          <div className="col-sm-12">
            <button className="pull-right btn btn-xs btn-success" onClick={this.checked.bind(null,0)}>{this.props.intl.formatMessage({id: 'trucks.checktoday'})}</button>
            <button className="pull-right btn btn-xs btn-warning" onClick={this.checked.bind(null,1)}>{this.props.intl.formatMessage({id: 'trucks.checkwithin3days'})}</button>
            <button className="pull-right btn btn-xs btn-danger" onClick={this.checked.bind(null,3)}>{this.props.intl.formatMessage({id: 'trucks.checkmorethan3days'})}</button>
            <button className="pull-right btn btn-xs btn-default" onClick={this.checked.bind(null,null)}>{this.props.intl.formatMessage({id: 'trucks.all'})}</button>
          </div>
          <div className="col-sm-12">
              <ReactTable defaultFilterMethod={this.filter} getTrProps={function(e,e2){
              res = {};
              res.className = e.className;
              if(typeof(e2) != "undefined"){
                if(e2.original.deactivated == "1"){
                  res.className = e.className + " strikeout";
                }else if(e2.original.last_check==0){
                  res.className = e.className + " success";
                }else if(e2.original.last_check < 3 && e2.original.last_check!=null){
                  res.className = e.className + " warning";
                }else if(e2.original.last_check >= 3){
                  res.className = e.className + " danger";
                }
              }
              return res;
            }} data={trucks} columns={columns} defaultPageSize={25} className="-striped -highlight" showPageSizeOptions={false} filterable={true} nextText={self.props.intl.formatMessage({id: 'table.next'})} previousText={self.props.intl.formatMessage({id: 'table.previous'})} pageText={self.props.intl.formatMessage({id: 'table.page'})} ofText={self.props.intl.formatMessage({id: 'table.of'})} noDataText={self.props.intl.formatMessage({id: 'table.nodata'})}/>
          </div>
        </div>
      </div>
		);
  }
});

module.exports = injectIntl(Trucks);
