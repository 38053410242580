// React stuff
var React = require("react");
var auth = require("../utils/auth");

var injectIntl = require("react-intl").injectIntl;
var createClass = require('create-react-class');

var Welcome = createClass({
  getInitialState: function() {
    return {trucks: 0,withcheck: 0,withoutcheck: 0,role: auth.getRole(),customer:{},demo:{},internal:{}};
  },
  getStats: function(id){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_stats",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({
          trucks: (parseInt(data.result.trucks) || 0),
          withcheck: (parseInt(data.result.withcheck) || 0),
          withoutcheck: (parseInt(data.result.withoutcheck) || 0),
          customer: (data.result.customer ? data.result.customer : {}),
          demo: (data.result.demo ? data.result.demo : {}),
          internal: (data.result.internal ? data.result.internal : {})
        });
      },
      error: function(){
        console.log(data);
      }
    });

  },

  componentDidMount: function(){
    this.getStats();
  },
  render: function() {
    return (
      <div id="page-content-wrapper">
        <div className={"container-fluid dashboard-boxes" + ((this.state.role=="admin"||this.state.role=="superadmin")  ? " hidden" : "")}>
          <a href="/trucks">
            <div className="dashboard-box">
              {this.props.intl.formatMessage({id: 'welcome.truckstotal'})}
              <h2>{this.state.trucks}</h2>
            </div>
          </a>
          <a href="/trucks/0">
            <div className="dashboard-box">
              {this.props.intl.formatMessage({id: 'welcome.withservice'})}
              <h2>{this.state.withcheck}</h2>
            </div>
          </a>
          <a href="/trucks/3">
            <div className="dashboard-box">
              {this.props.intl.formatMessage({id: 'welcome.withoutservice'})}
              <h2>{this.state.withoutcheck}</h2>
            </div>
          </a>
        </div>
        <div className={"container-fluid dashboard-boxes" + (this.state.role!="admin"&&this.state.role!="superadmin" ? " hidden" : "")}>
          <h2>{this.props.intl.formatMessage({id: 'organization.customer'})}</h2>
          <a href="/trucks">
            <div className="dashboard-box">
              {this.props.intl.formatMessage({id: 'welcome.truckstotal'})}
              <h2>{this.state.customer.trucks}</h2>
            </div>
          </a>
          <a href="/trucks/0">
            <div className="dashboard-box">
              {this.props.intl.formatMessage({id: 'welcome.withservice'})}
              <h2>{this.state.customer.withcheck}</h2>
            </div>
          </a>
          <a href="/trucks/3">
            <div className="dashboard-box">
              {this.props.intl.formatMessage({id: 'welcome.withoutservice'})}
              <h2>{this.state.customer.withoutcheck}</h2>
            </div>
          </a>
        </div>
        <div className={"container-fluid dashboard-boxes" + (this.state.role!="admin"&&this.state.role!="superadmin"  ? " hidden" : "")}>
          <h2>{this.props.intl.formatMessage({id: 'organization.demo'})}</h2>
          <a href="/trucks">
            <div className="dashboard-box">
              {this.props.intl.formatMessage({id: 'welcome.truckstotal'})}
              <h2>{this.state.demo.trucks}</h2>
            </div>
          </a>
          <a href="/trucks">
            <div className="dashboard-box">
              {this.props.intl.formatMessage({id: 'welcome.withservice'})}
              <h2>{this.state.demo.withcheck}</h2>
            </div>
          </a>
          <a href="/trucks">
            <div className="dashboard-box">
              {this.props.intl.formatMessage({id: 'welcome.withoutservice'})}
              <h2>{this.state.demo.withoutcheck}</h2>
            </div>
          </a>
        </div>
        <div className={"container-fluid dashboard-boxes" + (this.state.role!="admin"&&this.state.role!="superadmin"  ? " hidden" : "")}>
          <h2>{this.props.intl.formatMessage({id: 'organization.internal'})}</h2>
          <a href="/trucks">
            <div className="dashboard-box">
              {this.props.intl.formatMessage({id: 'welcome.truckstotal'})}
              <h2>{this.state.internal.trucks}</h2>
            </div>
          </a>
          <a href="/trucks">
            <div className="dashboard-box">
              {this.props.intl.formatMessage({id: 'welcome.withservice'})}
              <h2>{this.state.internal.withcheck}</h2>
            </div>
          </a>
          <a href="/trucks">
            <div className="dashboard-box">
              {this.props.intl.formatMessage({id: 'welcome.withoutservice'})}
              <h2>{this.state.internal.withoutcheck}</h2>
            </div>
          </a>
        </div>
      </div>
		);
  }
});

module.exports = injectIntl(Welcome);
