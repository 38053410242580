// React stuff
var React = require("react");
var auth = require("../utils/auth");
var ReactTable = require('react-table').default;


var injectIntl = require("react-intl").injectIntl;
var createClass = require('create-react-class');

var Users = createClass({
  getInitialState: function(){
    return {customers: []};
  },
  componentDidMount: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_users",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          if(data.result == null){
            self.setState({customers: [],customerDataError: false});
          }else{
            self.setState({customers: data.result,customerDataError: false});
          }
        }else{
          self.setState({customers: [],customerDataError: true});
        }
      },
      error: function(){
        self.setState({customers: [],dataError: true});
      }
    });
  },
  filter: function(filter,row){
    if(filter.id == "name" || filter.id == "username" || filter.id == "role" || filter.id == "organization"){
      search = String(filter.value);
      value = String(row[filter.id]);
      if(value.toLowerCase().indexOf(search.toLowerCase()) > -1){
        return true;
      }else{
        return false;
      }
    }else if(filter.id == "type"){
      search = String(filter.value);
      value = this.lookupModelType(row[filter.id]);
      if(value.toLowerCase().indexOf(search.toLowerCase()) > -1){
        return true;
      }else{
        return false;
      }
    }else{
      console.log("Missing filter logic for " + filter.id);
    }
  },
  render: function() {    
    var self = this;
    var customers = [];
    columns = [{
      Header: self.props.intl.formatMessage({id: 'users.name'}),
      accessor: 'name',
    },
    {
      Header: self.props.intl.formatMessage({id: 'users.username'}),
      accessor: 'username',
    },
    {
      Header: self.props.intl.formatMessage({id: 'users.role'}),
      accessor: 'role',
    },
    {
      Header: self.props.intl.formatMessage({id: 'users.organization'}),
      accessor: 'organization',
    },
    {
      Header: "",
      sortable: false,
      filterable: false,
      accessor: 'userid',
      Cell: function(props){
        return (<a href={"/user/" + props.value} className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-pencil"></i> {self.props.intl.formatMessage({id: 'users.edit'})}</a>)
     
      },
    }]
    if(this.state.customers.length != 0){
      this.state.customers.map(function(customer,index){
        customers.push(
          <tr key={index}>
            <td column={self.props.intl.formatMessage({id: 'users.name'})}>
              {customer.name}
            </td>
            <td column={self.props.intl.formatMessage({id: 'users.username'})}>
              {customer.username}
            </td>
            <td column={self.props.intl.formatMessage({id: 'users.role'})}>
              {customer.role}
            </td>
            <td column={self.props.intl.formatMessage({id: 'users.organization'})}>
              {customer.organization}
            </td>
            <td column="">
              <a href={"/user/" + customer.userid} className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-pencil"></i> {self.props.intl.formatMessage({id: 'users.edit'})}</a>
            </td>
          </tr>
        );
      });
    }
    return (
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'users.users'})}<a href="/user/" className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-user-plus"></i> {this.props.intl.formatMessage({id: 'users.createUser'})}</a></h1>
              <ReactTable data={this.state.customers} defaultFilterMethod={this.filter} columns={columns} defaultPageSize={25} className="-striped -highlight" showPageSizeOptions={false} filterable={true} nextText={self.props.intl.formatMessage({id: 'table.next'})} previousText={self.props.intl.formatMessage({id: 'table.previous'})} pageText={self.props.intl.formatMessage({id: 'table.page'})} ofText={self.props.intl.formatMessage({id: 'table.of'})} noDataText={self.props.intl.formatMessage({id: 'table.nodata'})}/>
              
          </div>
        </div>
      </div>
		);
  }
});

module.exports = injectIntl(Users);
