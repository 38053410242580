module.exports={
    "app": {
        "welcome": {
            "teaser1": "Scan QR Code",
            "teaser2": "Fill in the PreOp Check",
            "teaser3": "Use the machine",
            "contactheader": "Contact STILL",
            "contactcontent": "Questions about Pre-op check, or your Machine, please feel free to contact STILL.",
            "continuebtn": "Continue to login"
        },
        "login": {
            "teaser": "Welcome to STILL PreOp",
            "logininfo": "Please enter your credentials",
            "mobile": "Cell phone number",
            "password": "Code",
            "rememberme": "Remember me",
            "login": "Log In",
            "scanlogin": "QR Code",
            "forgottenpass": "Have you forgotten your password?"
        },
        "scan": {
            "unknownserial": "The serial number was not recognized!",
            "surveyerror": "No questionnaires found!",
            "scancode": "Scan QR Code",
            "seriallabel": "Scan or type in the Serial No.",
            "continue": "Continue",
            "qrcodeprompt": "Place the QR code in the scanning area"
        },
        "survey": {
            "continuebtn": "Continue",
            "truckok": "The questionnaire has been completed!",
            "back": "Back",
            "truckinfo": "Machine info",
            "yes": "Yes",
            "no": "No",
            "continue": "Continue",
            "takeimage": "Take a Picture",
            "textplaceholder": "Type in your answer",
            "numberplaceholder": "Type in number",
            "cancel": "Cancel",
            "choosequestionnaire": "Choose questionnaire",
            "blescanning": "Scanning...",
            "bleerror": "Bluetooth Error!",
            "blenotruck": "Could not find the machine",
            "bleconnecting": "Connecting ...",
            "blestarting": "Starting ...",
            "bletrucknotstarted": "The machine might not be able to start",
            "blecouldntstarttruck": "Could not start the machine",
            "blecouldntconnect": "Could not connect to the machine",
            "pushtitle": "Warning",
            "pushtext": "The machine will stop soon",
            "truck": "Machine",
            "model": "Model",
            "manufactor": "Brand",
            "serial": "Serial No.",
            "internalid": "Internal No.",
            "type": "Type",
            "organization": "Organization",
            "department": "Department",
            "year": "Year",
            "ownership": "Ownership",
            "owned": "Owned",
            "leased": "Leaset",
            "rent": "Rented",
            "aquisitiondate": "Acquisition date",
            "expirationdate": "Date of expiry",
            "capacity": "Capacity",
            "lift": "Lift height (mm)",
            "height": "Overall height (mm)",
            "serviceandmaintenance": "Service and maintenance",
            "hours": "No. Of Hours",
            "hours_date": "Date of latest hour reading",
            "last_service": "Date of last service \/ maintenance",
            "service_agreement": "Service agreement",
            "full": "FullService",
            "discount": "Discount Agreement",
            "none": "No Agreement",
            "servicepartner": "Service Partner",
            "expiration_date_service": "Date of expiry",
            "equipment": "Equipment",
            "technic": "Technic",
            "close_modal": "Close",
            "info": "Information",
            "electricalforklift": "Machine Electrical",
            "dieselforklift": "Machine Diesel",
            "gasforklift": "Machine Gas",
            "reachtruck": "Reach Truck",
            "4wayreachtruck": "4-Way Reach truck",
            "pallettruck": "Low lift pallet trucks",
            "doubledeckerpallettruck": "Double stack pallet trucks",
            "highliftpallettruck": "High lift pallet trucks",
            "standonpallettruck": "Stand-in stacker",
            "sitonpallettruck": "Sit-in stacker",
            "counterweightpallettruck": "Counterbalance stacker",
            "widetrackpallettruck": "Wide track stacker",
            "orderpicker": "Order picker",
            "narrowtrackpallettruck": "Narrow aisle truck",
            "tractor": "Tractor",
            "other": "Others"
        },
        "confirmation": {
            "header": "Thanks for your entry!"
        },
        "bletrucktime": {
            "headerresult": "The machine will stop in",
            "minutes": "minutes",
            "ok": "Continue"
        }
    },
    "web": {
        "generic": {
            "close": "Close",
            "delete": "Delete",
            "save": "Save",
            "copy": "Copy"
        },
        "login": {
            "wrongUsernameAndPass": "Username or password is incorrect!",
            "usernamePlaceholder": "Username",
            "passwordPlaceholder": "Password",
            "rememberMe": "Remember me",
            "login": "Log In"
        },
        "department": {
            "delete": "Delete department",
            "deleteAreYouSure": "Are you sure you want to delete {name}?",
            "department": "Department",
            "name": "Name",
            "trucks": "Machines",
            "working_days": "operating days",
            "monday": "Monday",
            "tuesday": "Thuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday",
            "sunday": "Sunday"
        },
        "departments": {
            "name": "Name",
            "edit": "Edit",
            "createDepartment": "Create department",
            "departments": "Departments"
        },
        "employee": {
            "delete": "Delete employee",
            "deleteAreYouSure": "Are you sure you want to delete {name}?",
            "employee": "Employee",
            "name": "Name",
            "cell": "Cell phone",
            "cellNumberExists": "Cell phone number exists",
            "employeeNumber": "Employee Number",
            "sendSMS": "Send SMS with code",
            "password": "Code",
            "emptyPasswordMessage": "If the code is empty, the system generates a code",
            "usernameExists": "The username already exists",
            "username": "Username",
            "groups": "Employee groups"
        },
        "employees": {
            "employees": "Employees",
            "createEemployee": "Create employee",
            "edit": "Edit",
            "groups": "Groups",
            "createGroup": "Create group"
        },
        "logout": {
            "thankYou": "Thanks for now",
            "loggedout": "You are now logged out!",
            "loginAgain": "Login again"
        },
        "menu": {
            "countryusers": "Country users",
            "organizations": "Organization",
            "models": "Models",
            "trucks": "Machines",
            "users": "Users",
            "submits": "Results",
            "questionnaires": "Questionnaires",
            "departmets": "Departments",
            "employees": "Employees",
            "editprofile": "Edit Profile",
            "logout": "Log Out"
        },
        "model": {
            "model": "Model",
            "upload": "Upload",
            "delete": "Delete",
            "deleteAreYouSure": "Are you sure you want to delete {name}?"
        },
        "models": {
            "model": "Model",
            "models": "Models",
            "type": "Type",
            "edit": "Edit",
            "createModel": "Create model"
        },
        "organization": {
            "enableble": "Use Bluetooth devices",
            "enableserial": "Use USB devices",
            "delete": "Delete",
            "deleteAreYouSure": "Are you sure you want to delete {name}?",
            "organization": "Organization",
            "name": "Name",
            "number": "Costumer No.",
            "address": "Adress",
            "postal": "Zip Code",
            "city": "City",
            "contact": "Contact Person",
            "users": "Users",
            "employees": "Employees",
            "trucks": "Machines",
            "departments": "Departments",
            "usernameprefix": "Prefix for usernames, short text, eg. stuk for STILL United kingdom",
            "internal": "Internal",
            "demo": "Demo",
            "customer": "Costumer"
        },
        "organizations": {
            "organizations": "Organization",
            "name": "Name",
            "usageReport": "Billing",
            "createOrganization": "Create organization",
            "edit": "Edit"
        },
        "questionnaire": {
            "number_updatefield": "Update timetables with the value entered",
            "color": "Color",
            "delete": "Delete questionnaire",
            "deleteAreYouSure": "Are you sure you want to delete {name}?",
            "questionnaire": "Questionnaire",
            "name": "Name",
            "default": "Standard questionnaire",
            "shuffleQuestions": "Shuffle questions",
            "department": "Department",
            "nocolor": "No color",
            "grey": "Grey",
            "green": "Green",
            "blue": "Blue",
            "yellow": "Yellow",
            "red": "Red",
            "questions": "Question",
            "question": "Question",
            "type": "Type",
            "mandatory": "Required",
            "type_boolean": "Yes\/No",
            "type_number": "Number",
            "type_text": "Text",
            "type_image": "Picture",
            "type_boolean_positive": "Yes is a positive answer",
            "type_boolean_reverse_positive": "No is a positive answer",
            "action_type_image": "Photo by negative response",
            "action_type_text": "Text by negative response",
            "action_type_no_action": "No Action",
            "enableble": "This question can enable Bluetooth device",
            "copy": "Copy",
            "copyExplainer": "Would you like to create a copy of this questionnaire?",
            "alwaysemail": "Always send results by email",
            "nostartonfalse": "Do not start the machine with false answers",
            "primary": "Primary questionnaire"
        },
        "questionnaireresult": {
            "truck": "Machine",
            "employee": "Employee",
            "time": "Time",
            "questionnaire": "Questionnaire",
            "view": "Show response",
            "pdf": "PDF",
            "no": "No",
            "yes": "Yes"
        },
        "questionnaires": {
            "questionnaires": "Questionnaires",
            "createQuestionnaire": "Create questionnaire",
            "name": "Name",
            "questions": "Question",
            "default": "Standard questionnaire",
            "edit": "Edit",
            "yes": "Yes"
        },
        "truck": {
            "ble": "Bluetooth device",
            "enableble": "Enable Bluetooth device",
            "serialconnection": "USB connection",
            "enableserialconnection": "Start the truck via USB-C",
            "blename": "Name of device",
            "bleopentime": "Time unit must be activated in minutes",
            "delete": "Delete",
            "deleteAreYouSure": "Are you sure you want to delete the truck?",
            "truck": "Machine",
            "qrcode": "QR Code",
            "model": "Model",
            "manufactor": "Brand",
            "serial": "Serial No.",
            "internalid": "Internal No.",
            "type": "Type",
            "organization": "Organization",
            "department": "Department",
            "year": "Year",
            "ownership": "Ownership",
            "owned": "Owned",
            "leased": "Leaset",
            "rent": "Rented",
            "aquisitiondate": "Acquisition date",
            "expirationdate": "Date of expiry",
            "capacity": "Capacity",
            "lift": "Lift height (mm)",
            "height": "Overall height (mm)",
            "serviceandmaintenance": "Service and maintenance",
            "hours": "No. Of Hours",
            "hours_date": "Date of latest hour reading",
            "last_service": "Date of last service \/ maintenance",
            "service_agreement": "Service agreement",
            "full": "FullService",
            "discount": "Discount Agreement",
            "none": "No Agreement",
            "servicepartner": "Service Partner",
            "expiration_date_service": "Date of expiry",
            "equipment": "Equipment",
            "technic": "Technic",
            "checkinterval": "Interval for execution of control",
            "interval_days": "Days",
            "interval_weeks": "Weeks",
            "interval_hours": "Hours",
            "interval_years": "Year",
            "deactivated": "Deactivated",
            "reminder": "Reminder"
        },
        "trucks": {
            "trucks": "Machines",
            "createTruck": "Create forklift",
            "manufactor": "Brand",
            "type": "Type",
            "organization": "Organization",
            "serial": "Serial No.",
            "internalid": "Internal No.",
            "edit": "Edit",
            "export": "Export",
            "all": "All",
            "checkmorethan3days": "More than 3 days since last check",
            "checkwithin3days": "Checked within 3 days",
            "checktoday": "Checked today"
        },
        "user": {
            "user": "User",
            "delete": "Delete User",
            "deleteAreYouSure": "Are you sure you want to delete {name}?",
            "name": "Name",
            "username": "Username",
            "usernameexists": "The username already exists",
            "role": "Role",
            "admin": "Administrator",
            "organization": "Organization",
            "department": "Department",
            "newpassword": "New Code",
            "inputpassword": "Set password",
            "email": "Email",
            "email_reports": "Receive error reports",
            "daily_reports": "Receive daily overview of non-checked machines"
        },
        "users": {
            "users": "Users",
            "name": "Name",
            "username": "Username",
            "role": "Role",
            "organization": "Organization",
            "edit": "Edit",
            "createUser": "create a user"
        },
        "welcome": {
            "truckstotal": "Machines in total",
            "withservice": "Machines with completed inspection today",
            "withoutservice": "Machines with missing inspection"
        },
        "table": {
            "next": "Next",
            "previous": "Previus",
            "page": "Page",
            "of": "of",
            "nodata": "No data available"
        }
    },
    "system": {
        "countrycode": "",
        "passwordsms": "Welcome as a STILL Preop Check user, use the app whenever you need a machine. You can download the app here: https:\/\/still-preop.com\/app\nYour username is your mobile number\nand your code is: {password}\nSincerely. STILL \"",
        "remindersms": "Remember to fill in the questionnaire: {questionnaire} on the machine: {type}, with serial number: {serial}"
    },
    "mail": {
        "subject": "Error report on a machine",
        "text": "An employee has completed a report on a machine\nThe employee has indicated there is a problem with the machine\nplease see the PDF file",
        "uncheckedsubject": "Error report on a machine",
        "uncheckedtext": "Hereby the daily overview of machines that have not filled in the primary questionnaire, please see the file"
    }
}