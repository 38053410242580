// React stuff
var React = require("react");
var auth = require("../utils/auth");

var injectIntl = require("react-intl").injectIntl;

var createClass = require('create-react-class');
PropTypes = require('prop-types');

var Login = createClass({
  getInitialState: function() {
    return {autherror: false};
  },

  handleClick: function(e){
		e.preventDefault();
		auth.login(this.refs.username.value.trim(),this.refs.password.value.trim(),this.refs.remember.checked,function(authed){
			if(authed){
				this.setState({autherror: false});
				this.props.history.push("/welcome");
			}else{
				this.setState({autherror: true});
				this.refs.signin.reset();
				this.refs.username.focus();
			}
		}.bind(this));
	},

	componentDidMount: function(){
		if(localStorage.username){
			this.refs.username.value = localStorage.username;
			this.refs.remember.checked = true;
      this.refs.password.focus();
		}else{
      this.refs.username.focus();
    }
    var self = this;
    auth.loggedIn(function(res){
      if(res==true){
        self.props.history.push("/welcome");
      }
    });
	},

  render: function(){
		var alertBox;
		if(this.state.autherror){
			alertBox = <div className="alert alert-danger">{this.props.intl.formatMessage({id: "login.wrongUsernameAndPass"})}</div>;
		}
    return (
	  <div className="card card-container">
	 		<img src="/assets/img/logo.png" className="center-block img-responsive"/>
			{alertBox}
		  <form className="form-signin" ref="signin" onSubmit={this.handleClick}>
    		<div className="input-group form-group">
				  <span className="input-group-addon " id="basic-addon1"><i className="fa fa-user fa-fw"></i></span>
					<input type="text" id="username" ref="username" className="form-control" placeholder={this.props.intl.formatMessage({id: 'login.usernamePlaceholder'})} required autoFocus />
				</div>
				<div className="input-group form-group">
				  <span className="input-group-addon" id="basic-addon2"><i className="fa fa-key fa fa-fw"></i></span>
					<input type="password" id="password" ref="password" className="form-control" placeholder={this.props.intl.formatMessage({id: 'login.passwordPlaceholder'})} required />
				</div>
			 	<div id="remember" className="checkbox">
        	<label>
          	<input type="checkbox" value="remember-me" ref="remember" /> {this.props.intl.formatMessage({id: 'login.rememberMe'})}
          </label>
        </div>
        <button onClick={this.handleClick} className="btn btn-lg btn-primary btn-block btn-signin" type="submit"><i className="fa fa-sign-in fa-fw"></i> {this.props.intl.formatMessage({id: 'login.login'})}</button>
      </form>
			</div>
		);
  }
});

Login.propTypes = {
  history: PropTypes.object,
};

module.exports = injectIntl(Login);
