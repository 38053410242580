// React stuff
var React = require("react");
var ReactDOM = require("react-dom");
var Router = require('react-router-dom').BrowserRouter
var Route = require('react-router-dom').Route
var Switch = require('react-router-dom').Switch
var NotFoundRoute = require("react-router-dom").NotFoundRoute;
var Switch = require('react-router-dom').Switch
var createBrowserHistory = require("history/lib/createBrowserHistory");
var IntlProvider = require("react-intl").IntlProvider;
var addLocaleData = require("react-intl").addLocaleData;
var translations = require("./utils/translations");
var PropTypes = require('prop-types');
var da = require("react-intl/locale-data/da");
addLocaleData(da);
var sv = require("react-intl/locale-data/sv");
addLocaleData(sv);
var pl = require("react-intl/locale-data/pl");
addLocaleData(pl);
var fi = require("react-intl/locale-data/fi");
addLocaleData(fi);
var en = require("react-intl/locale-data/en");
addLocaleData(en);
var fr = require("react-intl/locale-data/fr");
addLocaleData(fr);
var no = require("react-intl/locale-data/no");
addLocaleData(no);

// Admin
var Backend = require("./pages/template");
var Welcome = require("./pages/welcome");

var Login = require("./pages/login");
var Logout = require("./pages/logout");

var Users = require("./pages/users");
var User = require("./pages/user");

var Organizations = require("./pages/organizations");
var Organization = require("./pages/organization");

var Departments = require("./pages/departments");
var Department = require("./pages/department");

var Models = require("./pages/models");
var Model = require("./pages/model");

var Trucks = require("./pages/trucks");
var Truck = require("./pages/truck");

var Employees = require("./pages/employees");
var Employee = require("./pages/employee");
var EmployeeGroup = require("./pages/employeegroup");

var Questionnaires = require("./pages/questionnaires");
var Questionnaire = require("./pages/questionnaire");
var QuestionnaireResult = require("./pages/questionnaireresult");

var AppRedirect = require("./pages/appredirect");

// Special function to catch i18n
(function () {
  var error = console.error;
  console.error = function () {
    if(arguments[0].indexOf("[React Intl] Missing message: ") > -1){
      regex = /\[React Intl\] Missing message: "(.*?)" for locale: "(.*?)"/g;
      var match = regex.exec(arguments[0]);
      $.ajax({
        type: 'POST',
        url: "/system/missing_translation",
        contentType: "application/json",
        data: JSON.stringify({locale: match[2],identifier: match[1]}),
        dataType: 'json',
        contentType: "application/json"
      });
    }
    error.apply(this, Array.prototype.slice.call(arguments));

  };
}());

var locale = navigator.language.split('-')  
locale = locale[1] ? locale[0] : navigator.language;
var messages = translations[locale] ? translations[locale] : translations['en'];
if(!translations[locale]){
  locale = 'en';
}
/*
ReactDOM.render((
  <IntlProvider locale={locale} messages={messages}>
  <Router history={createBrowserHistory()}>
    <Route name="logout" path="/logout" component={Logout}/>
    <Route name="app" path="/app" component={AppRedirect} />
    <Route name="admin" path="/" component={Backend}>
      <IndexRoute component={Login}/>
      <Route name="welcome" path="welcome" component={Welcome} />
      
      <Route name="users" path="users" component={Users} />
      <Route name="user" path="user" component={User} />
      <Route name="useredit" path="user/:id" component={User} />

      <Route name="organizations" path="organizations" component={Organizations} />
      <Route name="organization" path="organization" component={Organization} />
      <Route name="organizationedit" path="organization/:id" component={Organization} />
    
      <Route name="models" path="models" component={Models} />
      <Route name="model" path="model" component={Model} />
      <Route name="modeledit" path="model/:id" component={Model} />
    
      <Route name="trucks" path="trucks" component={Trucks} />
      <Route name="truckschecked" path="trucks/:checked" component={Trucks} />
      <Route name="truck" path="truck" component={Truck} />
      <Route name="truckedit" path="truck/:id" component={Truck} />

      <Route name="employees" path="employees" component={Employees} />
      <Route name="employee" path="employee" component={Employee} />
      <Route name="employeeedit" path="employee/:id" component={Employee} />

      <Route name="questionnaires" path="questionnaires" component={Questionnaires} />
      <Route name="questionnaire" path="questionnaire" component={Questionnaire} />
      <Route name="questionnaireresult" path="submissions" component={QuestionnaireResult} />
      <Route name="questionnaireedit" path="questionnaire/:id" component={Questionnaire} />

      <Route name="departments" path="departments" component={Departments} />
      <Route name="department" path="department" component={Department} />
      <Route name="departmentedit" path="department/:id" component={Department} />
    </Route>
  </Router>
  </IntlProvider>
), document.getElementById("app"));*/
ReactDOM.render((
  <IntlProvider locale={locale} messages={messages}>
  <Router>
    <Switch>
      <Route exact name="login" path="/" component={Login}/>
      <Route name="logout" path="/logout" component={Logout}/>
      <Route name="app" path="/app" component={AppRedirect} />
      <Backend>
        <Route name="welcome" path="/welcome" component={Welcome} />
        
        <Route name="users" path="/users" component={Users} />
        <Route exact name="user" path="/user" component={User} />
        <Route name="useredit" path="/user/:id" component={User} />

        <Route name="trucks" path="/trucks" component={Trucks} />
        <Route name="truckschecked" path="/trucks/:checked" component={Trucks} /> 
        <Route exact name="truck" path="/truck" component={Truck} />
        <Route name="truckedit" path="/truck/:id" component={Truck} />

        <Route name="organizations" path="/organizations" component={Organizations} />
        <Route exact name="organization" path="/organization" component={Organization} />
        <Route name="organizationedit" path="/organization/:id" component={Organization} />
      
        <Route name="models" path="/models" component={Models} />
        <Route exact name="model" path="/model" component={Model} />
        <Route name="modeledit" path="/model/:id" component={Model} />

        <Route name="employees" path="/employees" component={Employees} />
        <Route exact name="employeegroup" path="/employeegroup" component={EmployeeGroup} />
        <Route name="employeegroupedit" path="/employeegroup/:id" component={EmployeeGroup} />
        <Route name="employee" path="/employee" component={Employee} exact/>
        <Route name="employeeedit" path="/employee/:id" component={Employee} exact/>


        <Route name="questionnaires" path="/questionnaires" component={Questionnaires} />
        <Route exact name="questionnaire"  path="/questionnaire" component={Questionnaire} />
        <Route name="questionnaireresult" path="/submissions" component={QuestionnaireResult} />
        <Route name="questionnaireedit" path="/questionnaire/:id" component={Questionnaire} />

        <Route name="departments" path="/departments" component={Departments} />
        <Route exact name="department" path="/department" component={Department} />
        <Route name="departmentedit" path="/department/:id" component={Department} />
      </Backend>
    </Switch>
  </Router>
  </IntlProvider>
), document.getElementById("app"));
