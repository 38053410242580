// This is essentially bulk require
flattenObject = function(ob) {
	var toReturn = {};
	
	for (var i in ob) {
		if (!ob.hasOwnProperty(i)) continue;
		
		if ((typeof ob[i]) == 'object') {
			var flatObject = flattenObject(ob[i]);
			for (var x in flatObject) {
				if (!flatObject.hasOwnProperty(x)) continue;
				
				toReturn[i + '.' + x] = flatObject[x];
			}
		} else {
			toReturn[i] = ob[i];
		}
	}
	return toReturn;
};

var locales = {};
locales.da = flattenObject(require("../locales/da.json").web);
locales.en = flattenObject(require("../locales/en.json").web);
locales.sv = flattenObject(require("../locales/se.json").web);
locales.pl = flattenObject(require("../locales/pl.json").web);
locales.fi = flattenObject(require("../locales/fi.json").web);
locales.fr = flattenObject(require("../locales/fr.json").web);
locales.no = flattenObject(require("../locales/no.json").web);


module.exports = locales;

