module.exports={
    "mail": {
        "subject": "Feilrapport p\u00e5 maskinen",
        "text": "En medarbeider har fylt ut en rapport p\u00e5 maskinen\\nMedarbeideren har angitt hva som er problemet med trucken\\nVennligst se PDF filen",
        "uncheckedsubject": "Feilrapport p\u00e5 maskinen",
        "uncheckedtext": "Den daglige oversikt over maskinen hvor det ikke er fylt ut sp\u00f8rreskjema, vennligst se filen"
    },
    "app": {
        "welcome": {
            "teaser1": "Scan QR Kode",
            "teaser2": "Inspiser maskinen",
            "teaser3": "Betjen maskinen",
            "contactheader": "Kontakt STILL",
            "contactcontent": "Har du sp\u00f8rsm\u00e5l til Pre-op check, eller til maskinen er du velkommen til \u00e5 kontakte STILL",
            "continuebtn": "Fortsett til login"
        },
        "login": {
            "teaser": "Velkommen til STILL Pre-op check",
            "logininfo": "Vennligst tast inn dine innloggings-opplysninger",
            "mobile": "Mobiltelefonnummer eller brukernavn",
            "password": "Kode",
            "rememberme": "Husk min innlogging",
            "login": "Logg in",
            "scanlogin": "QR Login",
            "forgottenpass": "Har du glemt din kode?"
        },
        "scan": {
            "unknownserial": "Serienummeret ble ikke gjenkjent ",
            "surveyerror": "Det ble ikke funnet noen sp\u00f8rreskjema",
            "scancode": "Scan QR kode",
            "seriallabel": "Scan eller tast serienummer",
            "continue": "Fortsett til login",
            "qrcodeprompt": "Plasser QR koden i scanningsomr\u00e5det"
        },
        "survey": {
            "continuebtn": "Fortsett",
            "doitanyway": "Fyll ut skjemaet p\u00e5 nytt",
            "truckok": "Sp\u00f8rreskjemaet er fylt ut  ",
            "back": "Tilbake",
            "truckinfo": "Maskininfo",
            "yes": "Ja",
            "no": "Nei",
            "continue": "Fortsett",
            "takeimage": "Ta bilde",
            "textplaceholder": "Tast inn ditt svar",
            "numberplaceholder": "Tast inn tall",
            "cancel": "Annuller",
            "choosequestionnaire": "Velg sp\u00f8rreskjema",
            "blescanning": "Skanner",
            "bleerror": "Bluetooth-feil",
            "blenotruck": "Kunne ikke finne maskinen",
            "bleconnecting": "Tilkobling",
            "blestarting": "Starter",
            "bletrucknotstarted": "Maskinen kunne muligvis ikke startes",
            "blecouldntstarttruck": "Kunne ikke starte maskinen",
            "blecouldntconnect": "Kunne ikke koble til maskinen",
            "pushtitle": "Advarsel",
            "pushtext": "Maskinen stopper snart",
            "truck": "Maskin",
            "model": "Modeller",
            "manufactor": "Merke",
            "serial": "Serienummer",
            "internalid": "Internt ID",
            "type": "Type",
            "organization": "Organisasjon",
            "department": "Avdeling",
            "year": "\u00c5rgang",
            "ownership": "Eierforhold",
            "owned": "Eiet",
            "leased": "Leaset",
            "rent": "Leie",
            "aquisitiondate": "Anskaffelsesdato",
            "expirationdate": "Utl\u00f8psdato",
            "capacity": "Kapasitet (Kg)",
            "lift": "L\u00f8fteh\u00f8yde (mm)",
            "height": "Byggeh\u00f8yde (mm)",
            "serviceandmaintenance": "Service og vedlikehold",
            "hours": "Antall timer",
            "hours_date": "Dato for siste avlesning",
            "last_service": "Dato for siste service\/ettersyn",
            "service_agreement": "Serviceavtale",
            "full": "Fullservice",
            "discount": "Rabattavtale",
            "none": "Ingen avtale",
            "servicepartner": "Servicepartner",
            "expiration_date_service": "Utl\u00f8psdato",
            "equipment": "Utstyr",
            "technic": "Teknikk",
            "close_modal": "N\u00e5r",
            "info": "Informasjon",
            "electricalforklift": "Gaffeltruck Elektrisk",
            "dieselforklift": "Gaffeltruck Diesel",
            "gasforklift": "Gaffeltruck Gass",
            "reachtruck": "Reach Truck",
            "4wayreachtruck": "4-Veis Truck",
            "pallettruck": "Pallevogn",
            "doubledeckerpallettruck": "Dobbeltstacker",
            "highliftpallettruck": "Stabler",
            "standonpallettruck": "Sta Inn Stabler",
            "sitonpallettruck": "Sid inn Stabler",
            "counterweightpallettruck": "Kontravekt Stabler",
            "widetrackpallettruck": "Bredspor Stabler",
            "orderpicker": "Plukketruck",
            "narrowtrackpallettruck": "Smalgangstruck",
            "tractor": "Trekker",
            "other": "Andre"
        },
        "confirmation": {
            "header": "Takk for din inntasting"
        },
        "bletrucktime": {
            "headerresult": "Maskinen stopper om",
            "minutes": "Minutter",
            "ok": "Videre"
        }
    },
    "system": {
        "countrycode": "45",
        "passwordsms": "Velkommen som bruker av STILL Preop Check, benytt Appen hver gang du skal bruke maskinen. Du kan hente Appen her: https:\/\/still-preop.com\/app Ditt brukernavn er ditt mobilnummmer\t og din kode er: {password}",
        "remindersms": "Husk \u00e5 fylle ut sp\u00f8rreskjemaet: {questionnaire} p\u00e5 maskinen: {type}, med serienummeret: {serial}"
    },
    "web": {
        "generic": {
            "close": "Lukk",
            "delete": "Slett",
            "save": "Lagre",
            "copy": "Kopier"
        },
        "login": {
            "wrongUsernameAndPass": "Brukernavn eller kodeord er feil",
            "usernamePlaceholder": "Brukernavn",
            "passwordPlaceholder": "Kodeord",
            "rememberMe": "Husk mine opplysninger",
            "login": "Logg inn"
        },
        "department": {
            "delete": "Slett avdeling",
            "deleteAreYouSure": "Er du sikker p\u00e5 du vil slette {navn?",
            "department": "Avdeling",
            "name": "Navn",
            "trucks": "Maskiner",
            "working_days": "Driftdager",
            "monday": "Mandag",
            "tuesday": "Tirsdag",
            "wednesday": "Onsdag",
            "thursday": "Torsdag",
            "friday": "Fredag",
            "saturday": "L\u00f8rdag",
            "sunday": "S\u00f8ndag"
        },
        "departments": {
            "name": "Navn",
            "edit": "Rediger",
            "createDepartment": "Opprett avdeling",
            "departments": "Avdelinger"
        },
        "employee": {
            "delete": "Slett medarbeider",
            "deleteAreYouSure": "Er du sikker p\u00e5 du vil slette {navn)?",
            "employee": "Medarbeider",
            "name": "Navn",
            "cell": "Mobil",
            "cellNumberExists": "Mobilnummeret eksisterer",
            "employeeNumber": "Ansettelsesnummer",
            "sendSMS": "Send SMS med kode",
            "password": "Kode",
            "emptyPasswordMessage": "Hvis ingen kode, generer systemet en kode",
            "usernameExists": "Brukernavnet eksisterer allerede",
            "username": "Brukernavn",
            "groups": "Medarbeidergrupper"
        },
        "employees": {
            "employees": "Medarbeidere",
            "createEemployee": "Opprett medarbeider",
            "edit": "Rediger",
            "groups": "Grupper",
            "createGroup": "Opprett gruppe"
        },
        "logout": {
            "thankYou": "Takk for n\u00e5",
            "loggedout": "Du er n\u00e5 logget ut",
            "loginAgain": "Logg inn igjen"
        },
        "menu": {
            "countryusers": "Landbrukere",
            "organizations": "Organisasjoner ",
            "models": "Modeller",
            "trucks": "Maskiner",
            "users": "Brukere",
            "submits": "Resultater",
            "questionnaires": "Sp\u00f8rreskjemaer ",
            "departmets": "Avdelinger",
            "employees": "Medarbeiere",
            "editprofile": "Rediger profil",
            "logout": "Logg ut"
        },
        "model": {
            "model": "Modeller",
            "upload": "Upload",
            "delete": "Delete",
            "deleteAreYouSure": "Er du sikker p\u00e5 du vil slette {name}?"
        },
        "models": {
            "model": "Modeller",
            "models": "Modeller",
            "type": "Type",
            "edit": "Rediger",
            "createModel": "Opprett modell"
        },
        "organization": {
            "enableble": "Benytt Bluetooth-enheter",
            "enableserial": "Benytt USB-enheter",
            "delete": "Slett",
            "deleteAreYouSure": "Er du sikker p\u00e5 du vil slette {name}?",
            "organization": "Organisasjon",
            "name": "Navn",
            "number": "Kundenr",
            "address": "Adresse",
            "postal": "Postnr",
            "city": "By",
            "contact": "Kontaktperson",
            "users": "Brukere",
            "employees": "Medarbeidere",
            "trucks": "Maskiner",
            "departments": "Avdelinger",
            "usernamePrefixExists": "Prefixet til brukernavn brukes av en annen organisasjon",
            "usernameprefix": "Prefix til brukernavn, kort tekst, f.eks. stN for STILL Norge ",
            "internal": "Internt",
            "demo": "Demo",
            "customer": "Kunde"
        },
        "organizations": {
            "organizations": "Organisasjoner ",
            "name": "Navn",
            "usageReport": "Avregningsgrunnlag",
            "createOrganization": "Opprett organisasjon",
            "edit": "Rediger"
        },
        "questionnaire": {
            "number_updatefield": "Oppdater timeteller med verdi som tastes inn",
            "color": "Farge",
            "delete": "Slett sp\u00f8rreskjema",
            "deleteAreYouSure": "Er du sikker p\u00e5 du vil slette {name}?",
            "questionnaire": "Sp\u00f8rreskjema",
            "name": "Navn",
            "default": "Standard sp\u00f8rreskjema",
            "shuffleQuestions": "Bland sp\u00f8rsm\u00e5lene",
            "department": "Avdeling",
            "nocolor": "Ingen farge",
            "grey": "Gr\u00e5",
            "green": "Gr\u00f8nn",
            "blue": "Bl\u00e5",
            "yellow": "Gul",
            "red": "R\u00f8d",
            "questions": "Sp\u00f8rsm\u00e5l",
            "question": "Sp\u00f8rsm\u00e5l",
            "type": "Type",
            "mandatory": "P\u00e5krevet ",
            "type_boolean": "Ja\/Nei",
            "type_number": "Tall",
            "type_text": "Tekst",
            "type_image": "Bilde",
            "type_boolean_positive": "Ja er positivt svar",
            "type_boolean_reverse_positive": "Nei er positivt svar",
            "action_type_image": "Bilde ved negativt svar",
            "action_type_text": "Tekst ved negativt svar",
            "action_type_no_action": "Ingen handling",
            "enableble": "Dette sp\u00f8rreskjemaet kan aktivere Bluetooth-enheten",
            "copy": "Kopier",
            "copyExplainer": "Vil du opprette en kopi av dette sp\u00f8rreskjema ?",
            "alwaysemail": "Send alltid resultat p\u00e5 e-mail",
            "nostartonfalse": "Start ikke maskinen ved feil besvarelser",
            "primary": "Prim\u00e6rt sp\u00f8rreskjema"
        },
        "questionnaireresult": {
            "truck": "Maskin",
            "employee": "Medarbeider",
            "time": "Tidspunkt",
            "questionnaire": "Sp\u00f8rreskjema",
            "view": "Vis besvarelse",
            "pdf": "PDF",
            "no": "Nei",
            "yes": "Ja"
        },
        "questionnaires": {
            "questionnaires": "Sp\u00f8rreskjemaer  ",
            "createQuestionnaire": "Opprett sp\u00f8rreskjema",
            "name": "Navn",
            "questions": "Sp\u00f8rsm\u00e5l",
            "default": "Standard sp\u00f8rreskjema ",
            "edit": "Rediger",
            "yes": "Ja"
        },
        "truck": {
            "ble": "Bluetooth-enhet",
            "enableble": "Aktiver bluetooth-enhet",
            "serialconnection": "USB enhet",
            "enableserialconnection": "Akiver maskinen med USB-C",
            
            "blename": "Navn p\u00e5 enhet",
            "bleopentime": "Tiden enhenten skal v\u00e6re aktivert, i minutter",
            "delete": "Slett",
            "deleteAreYouSure": "Er du sikker p\u00e5 du vil slette maskinen?",
            "truck": "Maskin",
            "qrcode": "QR Kode",
            "model": "Modeller",
            "manufactor": "Merke",
            "serial": "Serie nummer",
            "internalid": "Internt ID",
            "type": "Type",
            "organization": "Organisasjon",
            "department": "Avdeling",
            "year": "\u00c5rgang",
            "ownership": "Eierforhold",
            "owned": "Eiet",
            "leased": "Leaset",
            "rent": "Leie",
            "aquisitiondate": "Anskaffelsesdato",
            "expirationdate": "Dato for utl\u00f8p",
            "capacity": "Kapasitet (Kg)",
            "lift": "L\u00f8fteh\u00f8yde (mm)",
            "height": "Byggeh\u00f8yde (mm)",
            "serviceandmaintenance": "Service og vedlikehold",
            "hours": "Antall timer",
            "hours_date": "Dato for siste timeavlesning",
            "last_service": "Dato for siste service\/ettersyn",
            "service_agreement": "Serviceavtale",
            "full": "Fullservice",
            "discount": "Rabattavtale",
            "none": "Ingen avtale",
            "servicepartner": "Servicepartner",
            "expiration_date_service": "Utl\u00f8psdato",
            "equipment": "Utstyr",
            "technic": "Teknikk",
            "checkinterval": "Intervall for utf\u00f8relse og kontroll",
            "interval_days": "Dag",
            "interval_weeks": "Uker",
            "interval_hours": "Timer",
            "interval_years": "\u00c5r",
            "deactivated": "Deaktivert",
            "reminder": "P\u00e5minnelse"
        },
        "trucks": {
            "trucks": "Maskiner",
            "createTruck": "Opprett maskinen",
            "manufactor": "Merke",
            "type": "Type",
            "organization": "Organisasjon",
            "serial": "Serienummer",
            "internalid": "Internt ID",
            "edit": "Rediger",
            "export": "Eksporter",
            "all": "Alle",
            "checkmorethan3days": "Mer enn 3 dager siden sjekk",
            "checkwithin3days": "Sjekket inn for 3 dager siden",
            "checktoday": "Sjekket i dag"
        },
        "user": {
            "user": "Bruker",
            "delete": "Slett bruker",
            "deleteAreYouSure": "Er du sikker p\u00e5 du vil slette {name}?",
            "name": "Navn",
            "username": "Brukernavn",
            "usernameexists": "Brukernavnet eksisterer allerede",
            "role": "Rolle",
            "admin": "Administrator",
            "organization": "Organisasjon",
            "department": "Avdeling",
            "newpassword": "Ny kode",
            "inputpassword": "Angi kodeord",
            "email": "E-mail",
            "email_reports": "Mottatt feil rapporter",
            "daily_reports": "Mottatt daglig oversikt over maskiner som ikke er sjekket"
        },
        "users": {
            "users": "Brukere",
            "name": "Navn",
            "username": "Brukernavn",
            "role": "Rolle",
            "organization": "Organisasjon",
            "edit": "Rediger",
            "createUser": "Opprett bruker"
        },
        "welcome": {
            "truckstotal": "Maskiner i alt",
            "withservice": "Maskiner som har utf\u00f8rt sjekk i dag",
            "withoutservice": "Maskiner med manglende sjekk"
        },
        "table": {
            "next": "Neste",
            "previous": "Forrige",
            "page": "Side",
            "of": "Av",
            "nodata": "Ingen data tilgjengelig"
        }
    }
}