// React stuff
var React = require("react");
var auth = require("../utils/auth");

var ReactTable = require("react-table").default;

var injectIntl = require("react-intl").injectIntl;

var createClass = require('create-react-class');

var matchSorter = require('match-sorter');

var Departments = createClass({
  getInitialState: function(){
    return {departments: []};
  },
  componentDidMount: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_departments",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          if(data.result == null){
            self.setState({departments: [],customerDataError: false});
          }else{
            self.setState({departments: data.result,customerDataError: false});
          }
        }else{
          self.setState({departments: [],customerDataError: true});
        }
      },
      error: function(){
        self.setState({departments: [],dataError: true});
      }
    });
  },
  render: function() {

    var self = this;
    columns = [{
      Header: self.props.intl.formatMessage({id: 'departments.name'}),
      accessor: 'name',
      filterMethod: function(filter, rows){
        if(rows.name.toLowerCase().indexOf(filter.value.toLowerCase()) > -1){
          return true;
        }else{
          return false;
        }
      },
      
    },
    {
      Header: "",
      sortable: false,
      filterable: false,
      accessor: 'departmentid',
      Cell: function(props){
        return (<a href={"/department/" + props.value} className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-pencil"></i> {self.props.intl.formatMessage({id: 'departments.edit'})}</a>)
      },
    }]
    return (
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'departments.departments'})}<a href="/department/" className="pull-right btn btn-xs btn-success" role="button"><i className="fa fa-building-o"></i> {this.props.intl.formatMessage({id: 'departments.createDepartment'})}</a></h1>
            <ReactTable data={this.state.departments} columns={columns} defaultPageSize={25} className="-striped -highlight" showPageSizeOptions={false} filterable={true} nextText={self.props.intl.formatMessage({id: 'table.next'})} previousText={self.props.intl.formatMessage({id: 'table.previous'})} pageText={self.props.intl.formatMessage({id: 'table.page'})} ofText={self.props.intl.formatMessage({id: 'table.of'})} noDataText={self.props.intl.formatMessage({id: 'table.nodata'})}/>
          </div>
        </div>
      </div>
		);
  }
});

module.exports = injectIntl(Departments);
