module.exports={
    "app": {
        "welcome": {
            "teaser1": "Scanna QR-kod",
            "teaser2": "Inspektera trucken",
            "teaser3": "Anv\u00e4nd trucken",
            "contactheader": "Kontakta STILL Sverige AB",
            "contactcontent": "Har du fr\u00e5gor om PreOp Check eller din truck \u00e4r du v\u00e4lkommen att kontakta STILL.",
            "continuebtn": "Forts\u00e4tt till login"
        },
        "login": {
            "teaser": "V\u00e4lkommen till STILL PreOp Check",
            "logininfo": "Skriv in ditt anv\u00e4ndarnamn och l\u00f6senord.",
            "mobile": "Mobiltelefonnr. eller anv\u00c3\u00a4ndarnamn",
            "password": "Kod",
            "rememberme": "Kom ih\u00e5g mitt login",
            "login": "Logga in",
            "scanlogin": "QR-kod",
            "forgottenpass": "Har du gl\u00f6mt din kod?"
        },
        "scan": {
            "unknownserial": "Serienumret \u00e4r inte k\u00e4nt hos STILL!",
            "surveyerror": "Inget fr\u00e5geformul\u00e4r hittat",
            "scancode": "Skanna QR-kod",
            "seriallabel": "Skanna eller skriv in serienumret",
            "continue": "Forts\u00e4tt",
            "qrcodeprompt": "Placera koden i skanningsomr\u00e5det"
        },
        "survey": {
            "continuebtn": "Avsluta",
            "doitanyway": "G\u00f6rr en ny tillsyn",
            "truckok": "Tillsyn har redan ut\u00f6rts!",
            "back": "Tillbaka",
            "truckinfo": "Truck info",
            "yes": "Ja",
            "no": "Nej",
            "continue": "Forts\u00e4tt",
            "takeimage": "Ta bild",
            "textplaceholder": "Skriv in ditt svar",
            "numberplaceholder": "Skriv in tal",
            "cancel": "Ta bort",
            "choosequestionnaire": "V\u00e4lj fr\u00e5geformul\u00e4r",
            "blescanning": "Scannar",
            "bleerror": "Bluetooth fel!",
            "blenotruck": "Kan inte hitta trucken",
            "bleconnecting": "Ansluter",
            "blestarting": "Startar",
            "bletrucknotstarted": "Trucken kan troligen inte startas",
            "blecouldntstarttruck": "Kunde inte starta trucken",
            "blecouldntconnect": "Kunde inte koppla upp mot trucken",
            "pushtitle": "Varning",
            "pushtext": "Trucken stannar snart",
            "truck": "Truck",
            "model": "Modell",
            "manufactor": "Fabrikat",
            "serial": "Serienummer",
            "internalid": "Intern ID",
            "type": "Typ",
            "organization": "Organisation",
            "department": "Avdelning",
            "year": "Tillverknings\u00e5r",
            "ownership": "\u00c4garf\u00f6rh\u00e5llande",
            "owned": "\u00c4gd",
            "leased": "Leasad",
            "rent": "Hyra",
            "aquisitiondate": "Ink\u00f6pt",
            "expirationdate": "Utg\u00e5ngsdatum",
            "capacity": "Kapacitet (Kg)",
            "lift": "Lyfth\u00f6jd (mm)",
            "height": "Byggh\u00f6jd (mm)",
            "serviceandmaintenance": "Service och underh\u00e5ll",
            "hours": "Antal timmar",
            "hours_date": "Datum f\u00f6r senaste tim avl\u00e4sning",
            "last_service": "Datum f\u00f6r senaste service",
            "service_agreement": "Service kontrakt",
            "full": "Fullservice",
            "discount": "Rabatterat avtal",
            "none": "Inget avtal",
            "servicepartner": "Service partner",
            "expiration_date_service": "Utg\u00e5ngsdatum",
            "equipment": "utrustning",
            "technic": "Teknik",
            "close_modal": "St\u00e4nga",
            "info": "Information",
            "electricalforklift": "Gaffeltruck Elektrisk",
            "dieselforklift": "Gaffeltruck Diesel",
            "gasforklift": "Gaffeltruck Gas",
            "reachtruck": "Skjutstativtruck",
            "4wayreachtruck": "4-v\u00e4gs truck",
            "pallettruck": "Handtruck",
            "doubledeckerpallettruck": "Dubbel staplare",
            "highliftpallettruck": "Staplare",
            "standonpallettruck": "St\u00e5 staplare",
            "sitonpallettruck": "Sitt staplare",
            "counterweightpallettruck": "H\u00f6glyftande staplare",
            "widetrackpallettruck": "Bredsp\u00e5r staplare",
            "orderpicker": "Plocktruck",
            "narrowtrackpallettruck": "Smalg\u00e5ngstruck",
            "tractor": "Dragtruck",
            "other": "Annat"
        },
        "confirmation": {
            "header": "Tack f\u00f6r din registrering"
        },
        "bletrucktime": {
            "headerresult": "Trucken stannar om",
            "minutes": "minuter",
            "ok": "Vidare"
        }
    },
    "system": {
        "countrycode": "46",
        "passwordsms": "V\u00e4lkommen som en STILL Preop Check-anv\u00e4ndare, anv\u00e4nd appen n\u00e4r du beh\u00f6ver en maskin. Du kan h\u00e4mta appen h\u00e4r: https:\/\/still-preop.com\/app\nDitt anv\u00e4ndarnamn \u00e4r ditt mobilnummer med +46 och din kod \u00e4r {password}",
        "remindersms": "Kom ih\u00e5g att fylla i fr\u00e5geformul\u00e4ret: {fr\u00e5geformul\u00e4r} p\u00e5 maskinen: {typ}, med serienummer: {serial}"
    },
    "web": {
        "generic": {
            "close": "St\u00e4ng ",
            "delete": "Radera",
            "save": "Spara",
            "copy": "Kopiera"
        },
        "login": {
            "wrongUsernameAndPass": "Anv\u00e4ndarnamn eller l\u00f6senord \u00e4r felaktigt",
            "usernamePlaceholder": "Anv\u00e4ndarnamn  ",
            "passwordPlaceholder": "L\u00f6senord",
            "rememberMe": "Kom ih\u00e5g min inloggningsuppgifter",
            "login": "Logga in"
        },
        "department": {
            "delete": "Ta bort avdelning",
            "deleteAreYouSure": "\u00c3\u201er du s\u00c3\u00a4ker p\u00c3\u00a5 att du vill ta bort {name}?",
            "department": "Avdelning",
            "name": "Namn",
            "trucks": "Truckar",
            "working_days": "Arbetsdagar",
            "monday": "M\u00e5ndag",
            "tuesday": "Tisdag",
            "wednesday": "Onsdag",
            "thursday": "Torsdag",
            "friday": "Fredag",
            "saturday": "L\u00f6rdag",
            "sunday": "S\u00f6ndag"
        },
        "departments": {
            "name": "Namn",
            "edit": "Redigera",
            "createDepartment": "Skapa avdelning",
            "departments": "Avdelningar"
        },
        "employee": {
            "delete": "Ta bort medarbetare",
            "deleteAreYouSure": "\u00c4r du s\u00e4ker p\u00e5 att du vill ta bort {name}?",
            "employee": "Medarbetare",
            "name": "Namn",
            "cell": "Mobil",
            "cellNumberExists": "Mobilnummer finns",
            "employeeNumber": "Medarbetarnummer",
            "sendSMS": "Skicka kod med SMS",
            "password": "Kod",
            "emptyPasswordMessage": "Om f\u00e4ltet \u00e4r tomt genererar systemet en kod",
            "usernameExists": "Anv\u00c3\u00a4ndarnamnet finns redan",
            "username": "Anv\u00c3\u00a4ndarnamn",
            "groups": "Grupper"
        },
        "employees": {
            "employees": "Medarbetare",
            "createEemployee": "Skapa medarbetare",
            "edit": "Redigera",
            "groups": "Grupper",
            "createGroup": "Skapa grupp"
        },
        "logout": {
            "thankYou": "Tack f\u00c3\u00b6r tillf\u00c3\u00a4llet",
            "loggedout": "Du \u00c3\u00a4rr utloggad!",
            "loginAgain": "Logga in igen"
        },
        "menu": {
            "countryusers": "Landanv\u00e4ndare",
            "organizations": "Organisationer",
            "models": "Modeller",
            "trucks": "Truckar",
            "users": "Anv\u00e4ndare",
            "submits": "Resultat",
            "questionnaires": "Fr\u00e5geformul\u00e4r ",
            "departmets": "Avdelningar",
            "employees": "Medarbetare",
            "editprofile": "Redigera profil",
            "logout": "Logga ut"
        },
        "model": {
            "model": "Modell",
            "upload": "Ladda upp",
            "delete": "Ta bort",
            "deleteAreYouSure": "\u00c4r du s\u00e4ker p\u00e5 att du vill ta bort {name}?"
        },
        "models": {
            "model": "Modell",
            "models": "Modeller",
            "type": "Typ",
            "edit": "Redigera",
            "createModel": "Skapa modell"
        },
        "organization": {
            "enableble": "Anv\u00e4nd bluetooth-enheter",
            "enableserial": "Anv\u00e4nd USB-enheter",
            "delete": "Ta bort",
            "deleteAreYouSure": "\u00c4r du s\u00e4ker p\u00e5 att du vill ta bort {namn}?",
            "organization": "Organisation",
            "name": "Namn",
            "number": "Kundnummer",
            "address": "Adress",
            "postal": "Postnummer",
            "city": "Ort",
            "contact": "Kontaktperson",
            "users": "Anv\u00e4ndare",
            "employees": "Medarbetare",
            "trucks": "Truckar",
            "departments": "Avdelningar",
            "usernamePrefixExists": "Prefixet till anv\u00c3\u00a4ndarnamne anv\u00c3\u00a4nds av en annan organisation",
            "usernameprefix": "Prefix till anv\u00c3\u00a4ndarnamn",
            "internal": "Internt",
            "demo": "Demo",
            "customer": "Kunder"
        },
        "organizations": {
            "organizations": "Organisationer",
            "name": "Namn",
            "usageReport": "Fakturaunderlag",
            "createOrganization": "Skapa organisation",
            "edit": "Redigera"
        },
        "questionnaire": {
            "number_updatefield": "Uppdatera tidtabeller med det angivna v\u00e4rdet",
            "color": "F\u00e4rger",
            "delete": "Ta bort fr\u00e5geformul\u00e4r",
            "deleteAreYouSure": "\u00c4r du s\u00e4ker p\u00e5 att du vill ta bort {namn}?",
            "questionnaire": "Fr\u00e5geformul\u00e4r",
            "name": "Namn",
            "default": "Standard fr\u00e5geformul\u00e4r",
            "shuffleQuestions": "Blanda fr\u00e5gorna",
            "department": "Avdelning",
            "nocolor": "Ingen f\u00e4rg",
            "grey": "Gr\u00e5",
            "green": "Gr\u00f6n",
            "blue": "Bl\u00e5",
            "yellow": "Gul",
            "red": "R\u00f6d",
            "questions": "Fr\u00e5gor",
            "question": "Fr\u00e5ga",
            "type": "Typ",
            "mandatory": "Obligatoriskt svar",
            "type_boolean": "Ja\/Nej",
            "type_number": "Tal",
            "type_text": "Text",
            "type_image": "Bild",
            "type_boolean_positive": "Ja \u00e4r ett positivt svar",
            "type_boolean_reverse_positive": "Nej \u00e4r positivt svar",
            "action_type_image": "Bild vid negativt svar",
            "action_type_text": "Text vid negativt svar",
            "action_type_no_action": "Ingen aktion",
            "enableble": "Den fr\u00e5ga kan aktivera en bluetooth-enhet",
            "copy": "Kopiera",
            "copyExplainer": "Vill du skapa en kopia av detta fr\u00e5geformul\u00e4r?",
            "alwaysemail": "Skicka alltid resultat med E-mail",
            "nostartonfalse": "Starta inte trucken vid negativt svar",
            "primary": "Prim\u00e4rt fr\u00e5geformul\u00e4r"
        },
        "questionnaireresult": {
            "truck": "Truck",
            "employee": "Medarbetare",
            "time": "Tidpunkt",
            "questionnaire": "Fr\u00e5geformul\u00e4r",
            "view": "Vid svar",
            "pdf": "PDF",
            "no": "Nej",
            "yes": "Ja"
        },
        "questionnaires": {
            "questionnaires": "Fr\u00e5geformul\u00e4r",
            "createQuestionnaire": "Skapa fr\u00e5geformul\u00e4r",
            "name": "Namn",
            "questions": "Fr\u00e5geformul\u00e4r",
            "default": "Standard fr\u00e5geformul\u00e4r",
            "edit": "Redigera",
            "yes": "Ja"
        },
        "truck": {
            "ble": "Bluetooth-enhet",
            "enableble": "Aktivera bluetooh-enhet",
            "serialconnection": "USB-enhet",
            "enableserialconnection": "Aktivera maskinen med USB-C-enhet",
            "blename": "Namn p\u00e5 enheten",
            "bleopentime": "Tid trucken ska vara aktiverad, i minuter",
            "delete": "Ta bort",
            "deleteAreYouSure": "\u00c4r du s\u00e4ker p\u00e5 att du vill tabort trucken",
            "truck": "Truck",
            "qrcode": "QR-kod",
            "model": "Modell",
            "manufactor": "Fabrikat",
            "serial": "Serienummer",
            "internalid": "Intern ID",
            "type": "Typ",
            "organization": "Organisation",
            "department": "Avdelning",
            "year": "Bygg\u00e5r",
            "ownership": "\u00c4gare",
            "owned": "\u00c4gd",
            "leased": "Leasad",
            "rent": "Hyra",
            "aquisitiondate": "Ink\u00f6ps \u00e5r",
            "expirationdate": "Datum f\u00f6r upph\u00f6rande",
            "capacity": "Kapacitet (kg)",
            "lift": "Lyfth\u00f6jd (mm)",
            "height": "Byggh\u00f6jd (mm)",
            "serviceandmaintenance": "Service och underh\u00e5ll",
            "hours": "Antal timmar",
            "hours_date": "Datum f\u00f6r senaste timavl\u00e4sning",
            "last_service": "Datum f\u00f6r senaste service\/underh\u00e5ll",
            "service_agreement": "Serviceavtal",
            "full": "Fullservice",
            "discount": "Rabattavtal",
            "none": "Inget avtal",
            "servicepartner": "Servicepartner",
            "expiration_date_service": "Datum f\u00f6r upph\u00f6rande",
            "equipment": "Utrustning",
            "technic": "Teknik",
            "checkinterval": "Intervall f\u00f6r utf\u00f6rande av kontroll",
            "interval_days": "Dagar",
            "interval_weeks": "Veckor",
            "interval_hours": "Timmar",
            "interval_years": "\u00c5r",
            "deactivated": "Deaktiverad",
            "reminder": "P\u00e5minnelse"
        },
        "trucks": {
            "trucks": "Truckar",
            "createTruck": "Skapa truck",
            "manufactor": "Fabrikat",
            "type": "Typ",
            "organization": "Organisation",
            "serial": "Serienummer",
            "internalid": "Internt ID",
            "edit": "Redigera",
            "export": "Exportera",
            "all": "Alla",
            "checkmorethan3days": "Mer \u00e4n 3 dagar sedan kontroll",
            "checkwithin3days": "Kontrollerad inom tidramen",
            "checktoday": "Kontrollerad idag"
        },
        "user": {
            "user": "Anv\u00e4ndare",
            "delete": "Ta bort anv\u00e4ndare",
            "deleteAreYouSure": "\u00c4r du s\u00e4ker p\u00e5 att du vill ta bort  {name}?",
            "name": "Namn",
            "username": "Anv\u00e4ndarnamn",
            "usernameexists": "Anv\u00e4ndarnamn finns redan",
            "role": "Roll",
            "admin": "Administrat\u00f6r",
            "organization": "Organisation",
            "department": "Avdelning",
            "newpassword": "Ny kod",
            "inputpassword": "Ange kod",
            "email": "E-post",
            "email_reports": "Mottag felrapporter",
            "daily_reports": "Mottag daglig \u00f6versikt \u00f6ver icke-kontrollerade maskiner"
        },
        "users": {
            "users": "Anv\u00e4ndare",
            "name": "Namn",
            "username": "Anv\u00e4ndarnamn",
            "role": "Roll",
            "organization": "Organisation",
            "edit": "Redigera",
            "createUser": "Skapa anv\u00e4ndare"
        },
        "welcome": {
            "truckstotal": "Truckar totalt",
            "withservice": "Truckar med daglig tillsyn utf\u00e4rdad idag",
            "withoutservice": "Truckar med saknad daglig tillsyn"
        },
        "table": {
            "next": "N\u00e4sta",
            "previous": "F\u00f6rra",
            "page": "Sida",
            "of": "av ",
            "nodata": "Ingen data tillg\u00e4nlig"
        }
    },
    "mail": {
        "subject": "Fel meddelande p\u00e5 truck.",
        "text": "En medarbetare har gjort en rapport p\u00e5 maskinen.\nEn medarbetare har fyllt i att det \u00e4r ett problem med trucken.\nSe v\u00e4nligen Pdf-filen.",
        "uncheckedsubject": "Fel meddelande p\u00e5 trucken.",
        "uncheckedtext": "H\u00e4r \u00e4r den dagliga \u00f6versikten \u00f6ver maskiner som inte har fyllt i det prim\u00e4ra fr\u00e5geformul\u00e4ret, se filen"
    },
    "0": "2",
    "kort text": "",
    "t": {
        "ex": {
            " Stse??? F\u00c3\u00b6r STILL Sverige\"": ""
        }
    }
}