module.exports={
    "Code": "Finnish",
    "app": {
        "welcome": {
            "teaser1": "Skannaa QR-koodi",
            "teaser2": "T\u00e4yt\u00e4 Pre-Op-tarkastus",
            "teaser3": "K\u00e4yt\u00e4 trukkia",
            "contactheader": "Ota yhteys STILLiin",
            "continuebtn": "Jatka kirjautumista",
            "contactcontent": "Jos sinulla on kysytt\u00e4v\u00e4\u00e4 Pre-Op-tarkastuksesta tai trukistasi, ota yhteytt\u00e4 STILLiin."
        },
        "login": {
            "teaser": "Tervetuloa STILL Pre-Opiin",
            "logininfo": "Anna k\u00e4ytt\u00e4j\u00e4tietosi",
            "mobile": "Matkapuhelinnumero",
            "password": "Koodi",
            "rememberme": "Muista minut",
            "login": "Kirjaudu sis\u00e4\u00e4n",
            "forgottenpass": "Unohditko salasanasi?"
        },
        "scan": {
            "unknownserial": "Sarjanumero tuntematon!",
            "scancode": "Lue QR-koodi",
            "seriallabel": "Skannaa tai sy\u00f6t\u00e4 sarjanro.",
            "continue": "Jatka",
            "surveyerror": "Kyselyit\u00e4 ei l\u00f6ydetty!",
            "qrcodeprompt": "Aseta QR-koodi skannausalueelle"
        },
        "survey": {
            "back": "Paluu",
            "yes": "Kyll\u00e4",
            "no": "Ei",
            "continue": "Jatka",
            "takeimage": "Ota kuva",
            "textplaceholder": "Kirjoita vastauksesi",
            "numberplaceholder": "Kirjoita numero",
            "cancel": "Peruuta",
            "choosequestionnaire": "Valitse kysely"
        },
        "confirmation": {
            "header": "Kiitos tiedoista!"
        }
    },
    "web": {
        "user": {
            "admin": "P\u00e4\u00e4k\u00e4ytt\u00e4j\u00e4",
            "inputpassword": "Aseta salasana",
            "deleteAreYouSure": "Haluatko varmasti poistaa {name}?",
            "organization": "Organisaatio",
            "role": "Rooli",
            "user": "K\u00e4ytt\u00e4j\u00e4",
            "delete": "Poista k\u00e4ytt\u00e4j\u00e4",
            "name": "Sukunimi",
            "username": "K\u00e4ytt\u00e4j\u00e4tunnus",
            "usernameexists": "K\u00e4ytt\u00e4j\u00e4tunnus on jo olemassa",
            "department": "Osasto",
            "newpassword": "Uusi koodi"
        },
        "organizations": {
            "usageReport": "Laskutus",
            "name": "Sukunimi",
            "edit": "Muokkaa",
            "createOrganization": "Luo organisaatio",
            "organizations": "Organisaatio"
        },
        "generic": {
            "close": "Sulje",
            "delete": "Poista",
            "save": "Tallenna"
        },
        "login": {
            "wrongUsernameAndPass": "K\u00e4ytt\u00e4j\u00e4tunnus tai salasana v\u00e4\u00e4r\u00e4!",
            "usernamePlaceholder": "K\u00e4ytt\u00e4j\u00e4tunnus",
            "passwordPlaceholder": "Salasana",
            "rememberMe": "Muista minut",
            "login": "Kirjaudu sis\u00e4\u00e4n"
        },
        "department": {
            "delete": "Poista osasto",
            "department": "Osasto",
            "name": "Sukunimi",
            "trucks": "Haarukkatrukit",
            "deleteAreYouSure": "Haluatko varmasti poistaa {name}?"
        },
        "truck": {
            "aquisitiondate": "Hankintap\u00e4iv\u00e4m\u00e4\u00e4r\u00e4",
            "height": "Kokonaiskorkeus (mm)",
            "last_service": "Viimeisen huollon p\u00e4iv\u00e4m\u00e4\u00e4r\u00e4",
            "hours_date": "Viimeisin k\u00e4ytt\u00f6tuntilukema",
            "expirationdate": "Er\u00e4\u00e4ntymisp\u00e4iv\u00e4m\u00e4\u00e4r\u00e4",
            "expiration_date_service": "Er\u00e4\u00e4ntymisp\u00e4iv\u00e4m\u00e4\u00e4r\u00e4",
            "ownership": "Omistajuus",
            "deleteAreYouSure": "Haluatko varmasti poistaa trukin?",
            "lift": "Nostokorkeus (mm)",
            "organization": "Organisaatio",
            "delete": "Poista",
            "truck": "Haarukkatrukki",
            "qrcode": "QR-koodi",
            "model": "Malli",
            "manufactor": "Merkki",
            "serial": "Sarjanro.",
            "internalid": "Sis\u00e4inen nro.",
            "type": "Tyyppi",
            "department": "Osasto",
            "year": "Vuosi",
            "owned": "Omistettu",
            "leased": "Liisattu",
            "rent": "Vuokrattu",
            "capacity": "Nostokyky",
            "serviceandmaintenance": "Huolto ja yll\u00e4pito",
            "hours": "Tunnit",
            "service_agreement": "Huoltosopimus",
            "full": "T\u00e4ysi huolto",
            "discount": "Alennussopimus",
            "none": "Ei sopimusta",
            "servicepartner": "Huoltokumppani",
            "equipment": "Varustus"
        },
        "departments": {
            "name": "Sukunimi",
            "edit": "Muokkaa",
            "departments": "Osastot",
            "createDepartment": "Luo osasto"
        },
        "questionnaire": {
            "action_type_image": "Kuva kielteisest\u00e4 vastauksesta",
            "shuffleQuestions": "Sekoita kysymykset",
            "deleteAreYouSure": "Haluatko varmasti poistaa {name}?",
            "name": "Nimi",
            "department": "Osasto",
            "nocolor": "Ei v\u00e4ri\u00e4",
            "grey": "Harmaa",
            "green": "Vihre\u00e4",
            "blue": "Sininen",
            "yellow": "Keltainen",
            "red": "Punainen",
            "type": "Tyyppi",
            "type_boolean": "Kyll\u00e4\/Ei",
            "type_number": "Numero",
            "type_text": "Teksti",
            "type_image": "Kuva",
            "type_boolean_positive": "Kyll\u00e4 on my\u00f6nteinen vastaus",
            "type_boolean_reverse_positive": "Ei on kielteinen vastaus",
            "action_type_no_action": "Ei toimintaa",
            "mandatory": "Vaaditaan",
            "delete": "Poista kysely",
            "questionnaire": "Kysely",
            "questions": "Kysymys",
            "question": "Kysymys",
            "default": "Vakiokysely",
            "action_type_text": "Teksti kielteisest\u00e4 vastauksesta"
        },
        "employee": {
            "name": "Sukunimi",
            "password": "Nro.",
            "deleteAreYouSure": "Haluatko varmasti poistaa {name}?",
            "emptyPasswordMessage": "Jos koodi on tyhj\u00e4, j\u00e4rjestelm\u00e4 tuottaa koodin",
            "employee": "Ty\u00f6ntekij\u00e4",
            "employeeNumber": "Ty\u00f6ntekij\u00e4n numero",
            "cell": "Matkapuhelinnumero",
            "cellNumberExists": "Matkapuhelinnumero olemassa",
            "sendSMS": "L\u00e4het\u00e4 tekstiviesti koodilla",
            "delete": "Poista ty\u00f6ntekij\u00e4"
        },
        "logout": {
            "loggedout": "Olet kirjautunut ulos!",
            "loginAgain": "Kirjaudu uudelleen sis\u00e4\u00e4n",
            "thankYou": "Kiitos t\u00e4h\u00e4n asti"
        },
        "employees": {
            "edit": "Muokkaa",
            "employees": "Ty\u00f6ntekij\u00e4t",
            "createEemployee": "Luo ty\u00f6ntekij\u00e4"
        },
        "model": {
            "deleteAreYouSure": "Haluatko varmasti poistaa {name}?",
            "model": "Malli",
            "upload": "Lataa",
            "delete": "Poista"
        },
        "organization": {
            "deleteAreYouSure": "Haluatko varmasti poistaa {name}?",
            "delete": "Poista",
            "name": "Sukunimi",
            "number": "Asiakasnro.",
            "address": "Osoite",
            "postal": "Postinumero",
            "city": "Paikkakunta",
            "contact": "Yhteyshenkil\u00f6",
            "users": "K\u00e4ytt\u00e4j\u00e4t",
            "trucks": "Haarukkatrukit",
            "departments": "Osastot",
            "employees": "Ty\u00f6ntekij\u00e4t",
            "organization": "Organisaatio"
        },
        "menu": {
            "models": "Mallit",
            "trucks": "Haarukkatrukit",
            "users": "K\u00e4ytt\u00e4j\u00e4t",
            "submits": "Tulokset",
            "departmets": "Osastot",
            "editprofile": "Muokkaa profiilia",
            "logout": "Kirjaudu ulos",
            "employees": "Ty\u00f6ntekij\u00e4t",
            "organizations": "Organisaatio",
            "questionnaires": "Kyselyt"
        },
        "models": {
            "model": "Malli",
            "models": "Mallit",
            "type": "Tyyppi",
            "edit": "Muokkaa",
            "createModel": "Luo malli"
        },
        "questionnaireresult": {
            "employee": "Ty\u00f6ntekij\u00e4",
            "truck": "Haarukkatrukki",
            "time": "Aika",
            "pdf": "PDF",
            "no": "Ei",
            "yes": "Kyll\u00e4",
            "questionnaire": "Kysely",
            "view": "N\u00e4yt\u00e4 vastaus"
        },
        "users": {
            "createUser": "luo k\u00e4ytt\u00e4j\u00e4",
            "organization": "Organisaatio",
            "role": "Rooli",
            "users": "K\u00e4ytt\u00e4j\u00e4t",
            "name": "Sukunimi",
            "username": "K\u00e4ytt\u00e4j\u00e4tunnus",
            "edit": "Muokkaa"
        },
        "questionnaires": {
            "createQuestionnaire": "Luo kysely",
            "name": "Sukunimi",
            "edit": "Muokkaa",
            "yes": "Kyll\u00e4",
            "questionnaires": "Kyselyt",
            "questions": "Kysymys",
            "default": "Vakiokysely"
        },
        "trucks": {
            "createTruck": "Luo haarukkatrukki",
            "organization": "Organisaatio",
            "trucks": "Haarukkatrukit",
            "manufactor": "Merkki",
            "type": "Tyyppi",
            "serial": "Sarjanro.",
            "internalid": "Sis\u00e4inen nro.",
            "edit": "Muokkaa"
        },
        "welcome": {
            "truckstotal": "Trukkeja yhteens\u00e4",
            "withoutservice": "Trukkeja joilta puuttuu tarkastus",
            "withservice": "Trukkeja joiden tarkastus tehty t\u00e4n\u00e4\u00e4n"
        }
    },
    "system": {
        "countrycode": "358",
        "passwordsms": "Welcome as a user of STILL Preop Check, use the app whenever you need a truck. You can download the app here: https:\/\/still-preop.com\/app\nYour username is your cell number, and your code is: {password} \nYour. STILL\n"
    }
}