// React stuff
var React = require("react");
var auth = require("../utils/auth");
var ReactModal = require('react-modal');
var injectIntl = require("react-intl").injectIntl;
var LinkedInput = require('react-linked-input');

var LinkedStateMixin = require('react-addons-linked-state-mixin');

var createClass = require('create-react-class');

var EmployeeGroup = createClass({
  mixins: [LinkedStateMixin],
  getInitialState: function() {
    return {groupid:0,name: '',errors: {}};
  },
  getGroup: function(id){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_employeegroup",
      data: JSON.stringify({id: id}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({groupid: (parseInt(data.result.groupid) || 0),name: data.result.name});
     },
      error: function(){
        console.log(data);
      }
    });
  },
  componentDidMount: function(){
    if(this.props.match.params.id){
        this.getGroup(this.props.match.params.id);
      }
  },
  save: function (e){
    e.preventDefault();
    var errors = {};
    // Check fields
    if(this.state.name.length < 3){
      errors.name = true;
    }
    if(Object.keys(errors).length == 0){
      var self = this;
      $.ajax({
        type: 'POST',
        url: "/system/save_employeegroup",
        data: JSON.stringify({id: self.state.groupid,name: self.state.name}),
        dataType: 'json',
        contentType: "application/json",
        success: function (data, textStatus, jqXHR) {
          if(data.status == "success"){
            self.props.history.push("/employees");
          }
        },
        error: function(){
          console.log(data);
        }
      });
    }else{
      this.setState({errors: errors});
    }
  },
  delete: function (e){
    e.preventDefault();
    this.setState({deleteModal:true});
  },
  confirmDelete: function (e){
    var self = this;
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/delete_employeegroup",
      data: JSON.stringify({id: this.state.groupid}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){

          self.props.history.push("/employees");
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  closeModal: function(e){
    if(e){
      e.preventDefault();
    }
    this.setState({deleteModal:false});
  },
  render: function() {
    var customers = [];
    var self = this;
    return (
      <div id="page-content-wrapper">
        <ReactModal ariaHideApp={false} isOpen={this.state.deleteModal} contentLabel={this.props.intl.formatMessage({id: 'employee.delete'})} onRequestClose={this.closeModal}>     
            <p>{this.props.intl.formatMessage({id: 'employee.deleteAreYouSure'},{name: this.state.name})}</p> 
            <button className='btn btn-sm btn-default' onClick={this.closeModal}>
              <i className="fa fa-times"></i> {this.props.intl.formatMessage({id: 'generic.close'})}
            </button>
            <button className='btn btn-sm btn-danger' onClick={this.confirmDelete}>
              <i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}
            </button>
        </ReactModal>
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'employee.groups'})}
            </h1>
            <form>
              <div className="row">
                <div className="col-sm-6">
                  <div className={"form-group" + (this.state.errors.name ? " has-error" : "")}>
                    <label className="control-label" htmlFor="name">{this.props.intl.formatMessage({id: 'employee.name'})}:</label>
                    <LinkedInput type="text" className="form-control" id="name" valueLink={this.linkState('name')} />
                  </div>
                </div>
                <div className="col-sm-6">
                
                </div>
              </div>    
              <button type="submit" className="btn btn-sm btn-primary" onClick={this.save}><i className="fa fa-check"></i> {this.props.intl.formatMessage({id: 'generic.save'})}</button>
              <button type="submit" className={"pull-right btn btn-sm btn-danger" + (this.state.employeeid==0 ? " hidden" : "")} onClick={this.delete}><i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
});

module.exports = injectIntl(EmployeeGroup);
