// React stuff
var React = require("react");

var injectIntl = require("react-intl").injectIntl;
var createClass = require('create-react-class');

var Menu = createClass({

  render: function() {
    var auth = this.props.auth;
    var menu = [];
    if(auth.getRole() == "superadmin"){
      menu.push(<li className="trapezoid" key="4"><a href="/users">{this.props.intl.formatMessage({id: 'menu.countryusers'})}</a></li>);
    }
    if(auth.getRole() == "admin"){
      menu.push(<li className="trapezoid" key="1"><a href="/organizations">{this.props.intl.formatMessage({id: 'menu.organizations'})}</a></li>);
      menu.push(<li className="trapezoid" key="2"><a href="/models">{this.props.intl.formatMessage({id: 'menu.models'})}</a></li>);
      menu.push(<li className="trapezoid" key="3"><a href="/trucks">{this.props.intl.formatMessage({id: 'menu.trucks'})}</a></li>);
      menu.push(<li className="trapezoid" key="4"><a href="/users">{this.props.intl.formatMessage({id: 'menu.users'})}</a></li>);
    }
    if(auth.getRole() == "organization"){
      menu.push(<li className="trapezoid" key="1"><a href="/submissions">{this.props.intl.formatMessage({id: 'menu.submits'})}</a></li>);
      menu.push(<li className="trapezoid" key="2"><a href="/questionnaires">{this.props.intl.formatMessage({id: 'menu.questionnaires'})}</a></li>);
      menu.push(<li className="trapezoid" key="3"><a href="/departments">{this.props.intl.formatMessage({id: 'menu.departmets'})}</a></li>);
      menu.push(<li className="trapezoid" key="4"><a href="/trucks">{this.props.intl.formatMessage({id: 'menu.trucks'})}</a></li>);
      menu.push(<li className="trapezoid" key="5"><a href="/employees">{this.props.intl.formatMessage({id: 'menu.employees'})}</a></li>);
    }
    if(auth.getRole() == "department"){
      menu.push(<li className="trapezoid" key="1"><a href="/submissions">{this.props.intl.formatMessage({id: 'menu.submits'})}</a></li>);
      menu.push(<li className="trapezoid" key="2"><a href="/trucks">{this.props.intl.formatMessage({id: 'menu.trucks'})}</a></li>);
      menu.push(<li className="trapezoid" key="3"><a href="/employees">{this.props.intl.formatMessage({id: 'menu.employees'})}</a></li>);
    }
    return (
      <div className="navbar navbar-default">
          <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-responsive-collapse">
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
              </button>
          </div>
          <div className="navbar-collapse collapse navbar-responsive-collapse">
              <ul className="nav navbar-nav">
                  <li><a href="/welcome"><i className="fa fa-home"></i></a></li>
                  {menu}
              </ul>
              <ul className="nav navbar-nav navbar-right">
                <li className="dropdown">
                  <a className="dopdown-toggle" data-toggle="dropdown" href="#">
                  <span><strong>{auth.getName()}</strong> {auth.getOrganization()}</span>
                  <i className="fa fa-user"></i></a>
                  <ul className="dropdown-menu">
                    <li><a href={"/user/" + auth.getUserid()}>{this.props.intl.formatMessage({id: 'menu.editprofile'})}</a></li>
                    <li><a href="/logout">{this.props.intl.formatMessage({id: 'menu.logout'})}</a></li>
                  </ul>
                </li>
                <img className="topLogo" src="/assets/img/logo-white.png" />
              </ul>

          </div>
      </div>
    );
  }
});

module.exports = injectIntl(Menu);
