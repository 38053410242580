// React stuff
var React = require("react");
var auth = require("../utils/auth");

var ReactModal = require('react-modal');

var LinkedInput = require('react-linked-input');
var injectIntl = require("react-intl").injectIntl;

var createClass = require('create-react-class');

var LinkedStateMixin = require('react-addons-linked-state-mixin');

var Department = createClass({
  mixins: [LinkedStateMixin],
  getInitialState: function() {
    return {departmentid:0,name: '',errors: {},stats: {trucks: 0},working_days: {monday: false,tuesday: false}};
  },
  getDepartment: function(id){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_department",
      data: JSON.stringify({id: id}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({departmentid: (parseInt(data.result.departmentid) || 0),name: data.result.name, stats: data.result.stats,working_days: data.result.working_days});
     },
      error: function(){
        console.log(data);
      }
    });
  },
  componentDidMount: function(){
    if(this.props.match.params.id){
      this.getDepartment(this.props.match.params.id);
    }
  },
  save: function (e){
    e.preventDefault();
    var errors = {};
    // Check fields
    if(this.state.name.length < 3){
      errors.name = true;
    }
    if(Object.keys(errors).length == 0){
      console.log(this.state);
      var self = this;
      $.ajax({
        type: 'POST',
        url: "/system/save_department",
        data: JSON.stringify({id: self.state.departmentid,name: self.state.name,working_days: self.state.working_days}),
        dataType: 'json',
        contentType: "application/json",
        success: function (data, textStatus, jqXHR) {
          if(data.status == "success"){
           self.props.history.push("/departments");
          }
        },
        error: function(){
          console.log(data);
        }
      });
    }else{
      this.setState({errors: errors});
    }
  },
  delete: function (e){
    e.preventDefault();
    this.setState({deleteModal:true});
  },
  confirmDelete: function (e){
    var self = this;
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/delete_department",
      data: JSON.stringify({id: this.state.departmentid}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          self.props.history.push("/departments");
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  changeDay: function(e){
    console.log(e.target.name);
    days = this.state.working_days;
    if(!days){
      days = {};
    }
    days[e.target.name] = !days[e.target.name];
    console.log(days);
    this.setState({working_days: days},function(){
      console.log(this.state.working_days);
    });
  },
  closeModal: function(e){
    if(e){
      e.preventDefault();
    }
    this.setState({deleteModal:false});
  },
  render: function() {
    var customers = [];
    var self = this;
    return (
      <div id="page-content-wrapper">
        <ReactModal ariaHideApp={false} isOpen={this.state.deleteModal} contentLabel={this.props.intl.formatMessage({id: 'department.delete'})} onRequestClose={this.closeModal}>     
        <p>{this.props.intl.formatMessage({id: 'department.deleteAreYouSure'},{name: this.state.name})}</p>
            <button className='btn btn-sm btn-default' onClick={this.closeModal}>
              <i className="fa fa-times"></i> {this.props.intl.formatMessage({id: 'generic.close'})}
            </button>
            <button className='btn btn-sm btn-danger' onClick={this.confirmDelete}>
              <i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}
            </button>
        </ReactModal>
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'department.department'})}</h1>
            <form>
              <div className="row">
                <div className="col-sm-6">
                  <div className={"form-group" + (this.state.errors.name ? " has-error" : "")}>
                    <label className="control-label" htmlFor="name">{this.props.intl.formatMessage({id: 'department.name'})}:</label>
                    <LinkedInput type="text" className="form-control" id="name" valueLink={this.linkState('name')} />
                  </div>
                  <div>
                    {this.props.intl.formatMessage({id: 'department.working_days'})}:
                  </div>
                  <div className="checkbox ">
                    <label><input type="checkbox" name="monday" checked={this.state.working_days.monday} onChange={this.changeDay} /> {this.props.intl.formatMessage({id: 'department.monday'})}</label>
                  </div>
                  <div className="checkbox ">
                    <label><input type="checkbox" name="tuesday" checked={this.state.working_days.tuesday} onChange={this.changeDay} /> {this.props.intl.formatMessage({id: 'department.tuesday'})}</label>
                  </div>
                  <div className="checkbox ">
                    <label><input type="checkbox" name="wednesday" checked={this.state.working_days.wednesday} onChange={this.changeDay} /> {this.props.intl.formatMessage({id: 'department.wednesday'})}</label>
                  </div>
                  <div className="checkbox ">
                    <label><input type="checkbox" name="thursday" checked={this.state.working_days.thursday} onChange={this.changeDay} /> {this.props.intl.formatMessage({id: 'department.thursday'})}</label>
                  </div>
                  <div className="checkbox ">
                    <label><input type="checkbox" name="friday" checked={this.state.working_days.friday} onChange={this.changeDay} /> {this.props.intl.formatMessage({id: 'department.friday'})}</label>
                  </div>
                  <div className="checkbox ">
                    <label><input type="checkbox" name="saturday" checked={this.state.working_days.saturday} onChange={this.changeDay} /> {this.props.intl.formatMessage({id: 'department.saturday'})}</label>
                  </div>
                  <div className="checkbox ">
                    <label><input type="checkbox" name="sunday" checked={this.state.working_days.sunday} onChange={this.changeDay} /> {this.props.intl.formatMessage({id: 'department.sunday'})}</label>
                  </div>
                  <button type="submit" className="btn btn-sm btn-primary" onClick={this.save}><i className="fa fa-check"></i> {this.props.intl.formatMessage({id: 'generic.save'})}</button>
                  <button type="submit" className={"pull-right btn btn-sm btn-danger" + (this.state.departmentid==0 ? " hidden" : "")} onClick={this.delete}><i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}</button>
                </div>
                <div className="col-sm-6">
                  <table className={"table table-hover" + (this.state.departmentid > 0 ? "" : " hidden")}>
                    <tbody>
                      <tr>
                        <th>{this.props.intl.formatMessage({id: 'department.trucks'})}:</th>
                        <td>{this.state.stats.trucks}</td>
                      </tr>
                    </tbody>
                  </table>  
                </div>
              </div>    
            </form>
          </div>
        </div>
      </div>
		);
  }
});

module.exports = injectIntl(Department);
