// React stuff
var React = require("react");
var auth = require("../utils/auth");

var injectIntl = require("react-intl").injectIntl;
var createClass = require('create-react-class');

var LogOut = createClass({
	componentDidMount: function(){
		auth.logout();
	},
  render: function() {
	  return (
	    <div className="card card-container">
	  	<img src="/assets/img/logo.png" className="center-block img-responsive"/>
				<div className="msg msg-success"><b>{this.props.intl.formatMessage({id: 'logout.thankYou'})}</b><br/>{this.props.intl.formatMessage({id: 'logout.loggedout'})}</div>
	        <a href="/" role="button" className="btn btn-lg btn-primary btn-block btn-signin"><i className="fa fa-sign-in fa-fw"></i> {this.props.intl.formatMessage({id: 'logout.loginAgain'})}</a>
			</div>
    );
  }
});

module.exports = injectIntl(LogOut);
