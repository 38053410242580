// React stuff
var React = require("react");
var auth = require("../utils/auth");

var ReactModal = require('react-modal');
var arrayMove = require('react-sortable-hoc').arrayMove;
var SortableElement = require('react-sortable-hoc').SortableElement;
var SortableContainer = require('react-sortable-hoc').SortableContainer;

var injectIntl = require("react-intl").injectIntl;

var LinkedStateMixin = require('react-addons-linked-state-mixin');
var createClass = require('create-react-class');
var LinkedInput = require('react-linked-input');


var Questionnaire = createClass({
  mixins: [LinkedStateMixin],
  getInitialState: function() {
    return {reminder_time: null,questionnaireid:0,alwaysemail: false,name: '',default: true,primary: false,department: null,questions: [],departments: [],errors: {},new_question: "",new_required: false,new_type: "bool",new_bool_positive: "1",version: 0,shuffle: false,types: [],openble: false,nostartonfalse: false,primary: false};
  },
  getQuestionnaire: function(id){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_questionnaire",
      data: JSON.stringify({id: id}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({
          questionnaireid: (parseInt(data.result.questionnaireid) || 0),
          reminder_time: data.result.reminder_time,
          reminder_period: data.result.reminder_period,
          type: data.result.type,
          openble: parseInt(data.result.openble),
          nostartonfalse: parseInt(data.result.nostartonfalse),
          alwaysemail: parseInt(data.result.alwaysemail),
          primary: parseInt(data.result.primary),
          name: data.result.name,
          default: data.result.default,
          department: data.result.department, 
          questions: data.result.questions,
          version:data.result.version, 
          shuffle: data.result.shuffle, 
          color: data.result.color
        });
      },
      error: function(){
        console.log(data);
      }
    });

  },
  getModelTypes: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_modeltypes",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({types: data.result});
     },
      error: function(data){
        console.log(data);
      }
    });
  },
  onSortEnd: function(indexMove){
    this.setState({
      questions: arrayMove(this.state.questions, indexMove.oldIndex, indexMove.newIndex)
    });
  },
  componentDidMount: function(){
    if(this.props.match.params.id){
      this.getQuestionnaire(this.props.match.params.id);
    }
    this.getDepartments();
    this.getModelTypes();
  },
  getDepartments: function(){
    var self = this;
    $.ajax({
      type: 'POST',
      url: "/system/get_departments",
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        self.setState({departments: data.result});
      },
      error: function(){
        console.log(data);
      }
    });
  },
  save: function (e){
    e.preventDefault();
    var errors = {};
    // Check fields
    if(this.state.name.length < 3){
      errors.name = true;
    }
    if(Object.keys(errors).length == 0){
      var self = this;
      self.setState({mobileexists: false});
      $.ajax({
        type: 'POST',
        url: "/system/save_questionnaire",
        data: JSON.stringify({
          id: self.state.questionnaireid,
          primary: self.state.primary,
          openble: self.state.openble,
          nostartonfalse: self.state.nostartonfalse,
          alwaysemail: self.state.alwaysemail,
          name: self.state.name,
          default:self.state.default,
          department: self.state.department,
          questions: self.state.questions,
          version:self.state.version, 
          shuffle: self.state.shuffle, 
          color: self.state.color,
          type: self.state.type,
          reminder_time: self.state.reminder_time,
          reminder_period: self.state.reminder_period
        }),
        dataType: 'json',
        contentType: "application/json",
        success: function (data, textStatus, jqXHR) {
          if(data.status == "success"){
            self.props.history.push("/questionnaires");
          }
        },
        error: function(){
          console.log(data);
        }
      });
    }else{
      this.setState({errors: errors});
    }
  },
  delete: function (e){
    e.preventDefault();
    this.setState({deleteModal:true});
  },
  copy: function (e){
    e.preventDefault();
    this.setState({copyModal:true});
  },
  confirmDelete: function (e){
    e.preventDefault();
    var self = this;
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/delete_questionnaire",
      data: JSON.stringify({id: this.state.questionnaireid}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          self.props.history.push("/questionnaires");
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  saveCopy: function (e){
    e.preventDefault();
    var self = this;
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: "/system/copy_questionnaire",
      data: JSON.stringify({id: this.state.questionnaireid,name: (this.state.copy_text ? this.state.copy_text : this.state.name)}),
      dataType: 'json',
      contentType: "application/json",
      success: function (data, textStatus, jqXHR) {
        if(data.status == "success"){
          self.props.history.push("/questionnaire/" + data.result.id);
          self.getQuestionnaire(data.result.id);
          self.closeModal();
        }
      },
      error: function(){
        console.log(data);
      }
    });
  },
  closeModal: function(e){
    if(e){
      e.preventDefault();
    }
    this.setState({deleteModal:false,copyModal: false});
  },
  changeHandler: function(e){
    var questions = this.state.questions;
    if(e.target.dataset.type == "number_updatefield"){
      questions[e.target.dataset.idx].number_updatefield = (e.target.checked ? "1" : "0");
    }
    if(e.target.dataset.type == "question"){
      questions[e.target.dataset.idx].question = e.target.value;
    }
    if(e.target.dataset.type == "type"){
      questions[e.target.dataset.idx].type = e.target.value;
    }
    if(e.target.dataset.type == "required"){
      questions[e.target.dataset.idx].required = !questions[e.target.dataset.idx].required;
    }
    if(e.target.dataset.type == "bool_positive"){
      questions[e.target.dataset.idx].bool_positive = e.target.value;
    }
    if(e.target.dataset.type == "action_handler"){
      questions[e.target.dataset.idx].action_handler = e.target.value;
    }
    this.setState({questions: questions});
  },
  addQuestion: function(e){
    e.preventDefault();
    var questions = this.state.questions;
    console.log(this.state.bool_negative_image);
    questions.push({question: this.state.new_question,type: this.state.new_type,required: this.state.new_required,bool_positive: this.state.new_bool_positive,action_handler: this.state.new_action_handler});
    this.setState({questions: questions,new_question: "",new_type: "bool",new_required: false,new_bool_positive: "1",new_action_handler: 'text'});
  },
  deleteQuestion: function(e){
    console.log(e.target.dataset);
    e.preventDefault();
    var questions = this.state.questions;
    questions.splice(e.target.dataset.idx,1);
    console.log(questions);
    this.setState({questions: questions});
  },
  changeType: function(e){
    this.setState({new_type: e.target.value});
  },
  changeActionHandler: function(e){
    if(e.target)
    this.setState({new_action_handler: e.target.value});
  },
  changeBoolPositive: function(e){
    this.setState({new_bool_positive: !this.state.new_bool_positive});
  },
  selectChange: function(data,e){
    if(data=="department"){
      this.setState({department: e.target.value});
    }else if(data=="color"){
      this.setState({color: e.target.value});
    }else if(data=="type"){
      this.setState({type: e.target.value});
    }else if(data=="reminder_period"){
      this.setState({reminder_period: e.target.value});
    }else{
      console.log("Unknown data",data);
    }
    data = e.target.value;
  },
  render: function() {
    var customers = [];
    var self = this;
    return (
      <div id="page-content-wrapper">
        <ReactModal ariaHideApp={false} isOpen={this.state.copyModal} contentLabel={this.props.intl.formatMessage({id: 'questionnaire.copy'})} onRequestClose={this.closeModal}>     
            <p>{this.props.intl.formatMessage({id: 'questionnaire.copyExplainer'},{name: this.state.name})}</p>
            <LinkedInput type="text" className="form-control" id="copy_text" valueLink={this.linkState('copy_text')} />
            <button className='btn btn-sm btn-default' onClick={this.closeModal}>
              <i className="fa fa-times"></i> {this.props.intl.formatMessage({id: 'generic.close'})}
            </button>
            <button className='btn btn-sm btn-primary' onClick={this.saveCopy}>
              <i className="fa fa-file"></i> {this.props.intl.formatMessage({id: 'generic.copy'})}
            </button>
        </ReactModal>
        <ReactModal ariaHideApp={false} isOpen={this.state.deleteModal} contentLabel={this.props.intl.formatMessage({id: 'questionnaire.delete'})} onRequestClose={this.closeModal}>     
             <p>{this.props.intl.formatMessage({id: 'questionnaire.deleteAreYouSure'},{name: this.state.name})}</p>
            <button className='btn btn-sm btn-default' onClick={this.closeModal}>
              <i className="fa fa-times"></i> {this.props.intl.formatMessage({id: 'generic.close'})}
            </button>
            <button className='btn btn-sm btn-danger' onClick={this.confirmDelete}>
              <i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}
            </button>
        </ReactModal>
        <div className="container-fluid">
          <div className="col-sm-12">
            <h1>{this.props.intl.formatMessage({id: 'questionnaire.questionnaire'})}</h1>
            <form>
              <div className="row">
                <div className="col-sm-6">
                  <div className={"form-group" + (this.state.errors.name ? " has-error" : "")}>
                    <label className="control-label" htmlFor="name">{this.props.intl.formatMessage({id: 'questionnaire.name'})}:</label>
                    <LinkedInput type="text" className="form-control" id="name" valueLink={this.linkState('name')} />
                  </div>
                  <div className="checkbox">
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('default')}/> {this.props.intl.formatMessage({id: 'questionnaire.default'})}</label>
                  </div>
                  <div className="checkbox">
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('shuffle')}/> {this.props.intl.formatMessage({id: 'questionnaire.shuffleQuestions'})}</label>
                  </div>
                  <div className="checkbox">
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('primary')}/>{this.props.intl.formatMessage({id: 'questionnaire.primary'})}</label>
                  </div>
                  <div className="checkbox">
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('alwaysemail')}/>{this.props.intl.formatMessage({id: 'questionnaire.alwaysemail'})}</label>
                  </div>
                  <div className={"checkbox " + (auth.getEnableble()=="1" ? "" : "hidden")}>
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('openble')}/>{this.props.intl.formatMessage({id: 'questionnaire.enableble'})}</label>
                  </div>
                  <div className={"checkbox" + (this.state.openble ? "" : " hidden")}>
                    <label><LinkedInput type="checkbox" checkedLink={this.linkState('nostartonfalse')}/>{this.props.intl.formatMessage({id: 'questionnaire.nostartonfalse'})}</label>
                  </div>
                  <div className={"form-group"}>
                    <label className="control-label" htmlFor="reminder_time">{this.props.intl.formatMessage({id: 'truck.reminder'})}:</label>
                    <div className="row">
                      <div className="col-sm-6">
                        <LinkedInput type="number" ref="reminder_time" className="form-control" id="reminder_time" valueLink={this.linkState('reminder_time')} />
                      </div>
                      <div className="col-sm-6">
                        <select className="form-control" id="reminder_period" value={this.state.interval_period} onChange={this.selectChange.bind(this,"reminder_period")} disabled={this.state.reminder_time==null||this.state.reminder_time==0}>
                          <option value="days">{this.props.intl.formatMessage({id: 'truck.interval_days'})}</option>
                          <option value="weeks">{this.props.intl.formatMessage({id: 'truck.interval_weeks'})}</option>
                          <option value="years">{this.props.intl.formatMessage({id: 'truck.interval_years'})}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className={"form-group " + (this.state.errors.department ? " has-error" : "")}>
                    <label className="control-label" htmlFor="department">{this.props.intl.formatMessage({id: 'questionnaire.department'})}:</label>
                    <select className="form-control" id="department" value={this.state.department} onChange={this.selectChange.bind(this,"department")}>
                      <option></option>
                      {this.state.departments.map(function(val,index){
                          return (<option value={val.departmentid}  key={index}>{val.name}</option>);
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="color">{this.props.intl.formatMessage({id: 'questionnaire.color'})}:</label>
                    <select className="form-control" id="color"  value={this.state.color} onChange={this.selectChange.bind(this,"color")}>
                      <option value="default">{this.props.intl.formatMessage({id: 'questionnaire.nocolor'})}</option>
                      <option value="active">{this.props.intl.formatMessage({id: 'questionnaire.grey'})}</option>
                      <option value="success">{this.props.intl.formatMessage({id: 'questionnaire.green'})}</option>
                      <option value="info">{this.props.intl.formatMessage({id: 'questionnaire.blue'})}</option>
                      <option value="warning">{this.props.intl.formatMessage({id: 'questionnaire.yellow'})}</option>
                      <option value="danger">{this.props.intl.formatMessage({id: 'questionnaire.red'})}</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="color">{this.props.intl.formatMessage({id: 'truck.type'})}:</label>
                    <select className="form-control" id="type"  value={this.state.type} onChange={this.selectChange.bind(this,"type")}>
                      <option></option>
                      {this.state.types.map(function(val,index){
                        return (<option value={val.identifier}  key={index}>{val.name}</option>);
                      })}
                    </select>
                  </div>
                </div>
              </div>  
              <h2>{this.props.intl.formatMessage({id: 'questionnaire.questions'})}</h2>
              <table className="table table-hover table-striped">
                <thead>
                  <tr>
                    <th>{this.props.intl.formatMessage({id: 'questionnaire.question'})}</th>
                    <th className="col-sm-5">{this.props.intl.formatMessage({id: 'questionnaire.type'})}</th>
                    <th className="text-center">{this.props.intl.formatMessage({id: 'questionnaire.mandatory'})}</th>
                    <th className="text-center">&nbsp;</th>
                  </tr>
                </thead>
                <SortableList pressDelay={200} items={this.state.questions} onSortEnd={this.onSortEnd} parent={this} />
              </table>            
              <button type="submit" className="btn btn-sm btn-primary" onClick={this.save}><i className="fa fa-check"></i> {this.props.intl.formatMessage({id: 'generic.save'})}</button>
              <button type="submit" className={"pull-right btn btn-sm btn-danger" + (this.state.questionnaireid==0 ? " hidden" : "")} onClick={this.delete}><i className="fa fa-trash"></i> {this.props.intl.formatMessage({id: 'generic.delete'})}</button>
              <button type="submit" className={"pull-right btn btn-sm btn-default" + (this.state.questionnaireid==0 ? " hidden" : "")} onClick={this.copy}><i className="fa fa-file"></i> {this.props.intl.formatMessage({id: 'generic.copy'})}</button>
              </form>
          </div>
        </div>
      </div>
    );
  }
});

module.exports = injectIntl(Questionnaire);

var SortableItem = SortableElement(function (_ref) {
  var value = _ref.value,
      parent = _ref.parent,
      idx = _ref.idx;
  return (
    <tr className="test">
      <td><LinkedInput type="text" value={value.question} onChange={parent.changeHandler} data-type="question" data-idx={idx} className="form-control"/></td>
      <td>
        <label className="radio-inline"><LinkedInput type="radio" name={"type[" + idx + "]"} data-type="type" onChange={parent.changeHandler} data-idx={idx} value="bool" checked={(value.type=="bool" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.type_boolean'})}</label>
        <label className="radio-inline"><LinkedInput type="radio" name={"type[" + idx + "]"} data-type="type" onChange={parent.changeHandler} data-idx={idx} value="number" checked={(value.type=="number" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.type_number'})}</label>
        <label className="radio-inline"><LinkedInput type="radio" name={"type[" + idx + "]"} data-type="type" onChange={parent.changeHandler} data-idx={idx} value="text" checked={(value.type=="text" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.type_text'})}</label>
        <label className="radio-inline"><LinkedInput type="radio" name={"type[" + idx + "]"} data-type="type" onChange={parent.changeHandler} data-idx={idx} value="image" checked={(value.type=="image" ? true : false)} />{parent.props.intl.formatMessage({id: 'questionnaire.type_image'})}</label>
        <div className={(value.type=="bool" ? "" : "hidden")}>
          <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeHandler} value="1" data-idx={idx} data-type="bool_positive" checked={(value.bool_positive=="1" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.type_boolean_positive'})}</label>
          <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeHandler} value="0" data-idx={idx} data-type="bool_positive" checked={(value.bool_positive!="1" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.type_boolean_reverse_positive'})}</label>

          <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeHandler} value="image" data-idx={idx} data-type="action_handler" checked={(value.action_handler=="image" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.action_type_image'})}</label>
          <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeHandler} value="text" data-idx={idx} data-type="action_handler" checked={(value.action_handler=="text" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.action_type_text'})}</label>
          <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeHandler} value="" data-idx={idx} data-type="action_handler" checked={(!value.action_handler ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.action_type_no_action'})}</label>
        </div>
        <div className={(value.type=="number" ? "" : "hidden")}>
          <label className="radio-inline"><LinkedInput type="checkbox" onChange={parent.changeHandler} data-idx={idx} data-type="number_updatefield" checked={(value.number_updatefield=="1" ? true : false)}/> {parent.props.intl.formatMessage({id: 'questionnaire.number_updatefield'})}</label>
        </div>
      </td>
      <td>
        <div className="checkbox text-center">
          <label><LinkedInput type="checkbox" onChange={parent.changeHandler} data-type="required" data-idx={idx} checked={value.required}/></label>
        </div>
      </td>
      <td className="text-center"><i className="fa fa-sort"></i><button data-idx={idx} className="btn btn-sm btn-danger pull-right" onClick={parent.deleteQuestion}><i data-idx={idx} className="fa fa-trash"></i></button></td>
    </tr>
  );
}
);
var SortableList = SortableContainer(function (_ref) {
  var items = _ref.items,
      parent = _ref.parent;
    return (
        <tbody>
          {
              items.map(function(value, index){
                  return (<SortableItem helperClass="onthemove" key={"item-" + index} index={index} idx={index} value={value} parent={parent}/>)
              }) 
          }
          <tr className="test">
            <td><LinkedInput type="text" valueLink={parent.linkState('new_question')} className="form-control"/></td>
            <td>
              <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeType} value="bool" checked={(parent.state.new_type=="bool" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.type_boolean'})}</label>
              <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeType} value="number" checked={(parent.state.new_type=="number" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.type_number'})}</label>
              <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeType} value="text" checked={(parent.state.new_type=="text" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.type_text'})}</label>
              <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeType} value="image" checked={(parent.state.new_type=="image" ? true : false)} />{parent.props.intl.formatMessage({id: 'questionnaire.type_image'})}</label>
              <div className={(parent.state.new_type=="bool" ?"" : "hidden")}>
                <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeBoolPositive} value="1" checked={(parent.state.new_bool_positive=="1" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.type_boolean_positive'})}</label>
                <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeBoolPositive} value="0" checked={(parent.state.new_bool_positive!="1" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.type_boolean_reverse_positive'})}</label>
                <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeActionHandler} value="image" checked={(parent.state.new_action_handler=="image" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.action_type_image'})}</label>
                <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeActionHandler} value="text" checked={(parent.state.new_action_handler=="text" ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.action_type_text'})}</label>
                <label className="radio-inline"><LinkedInput type="radio" onChange={parent.changeActionHandler} value="" checked={(!parent.state.new_action_handler ? true : false)}/>{parent.props.intl.formatMessage({id: 'questionnaire.action_type_no_action'})}</label>
                
              </div>
            </td>
            <td>
              <div className="checkbox text-center">
                <label><LinkedInput type="checkbox" checkedLink={parent.linkState('new_required')}/></label>
              </div>
            </td>
            <td className="text-center"><button className="btn btn-sm btn-primary pull-right" onClick={parent.addQuestion}><i className="fa fa-plus"></i></button></td>
          </tr>
        </tbody>     
    );
});
