module.exports={
    "mail": {
        "subject": "Fejl rapport på maskine",
        "text": "En medarbejder har udfyldt en rapport på en maskine.\nMedarbejderen har angivet der er et problem med trucken\n se venligst PDF filen",
        "uncheckedsubject": "Fejl rapport på maskinen",
        "uncheckedtext": "Hermed den daglige oversigt over maskiner der ikke har fået udfyldt det primære spørgeskema, se venligst filen"
    },
    "app": {
        "welcome": {
            "teaser1": "Scan QR Kode",
            "teaser2": "Inspicer maskinen",
            "teaser3": "Betjen maskinen",
            "contactheader": "Kontakt STILL",
            "contactcontent": "Har du spørgsmål til Pre-op check, eller til din maskine er du meget velkommen til at kontakte STILL.",
            "continuebtn": "Fortsæt til login"
        },
        "login": {
            "teaser": "Velkommen til STILL Pre-op check.",
            "logininfo": "Indtast venligst dine login oplysninger.",
            "mobile": "Mobiltelefon nummer eller brugernavn",
            "password": "Kode",
            "rememberme": "Husk mit login",
            "login": "Log ind",
            "scanlogin": "QR Login",
            "forgottenpass": "Har du glemt din kode?"
        },
        "scan": {
            "unknownserial": "Serie nummeret blev ikke genkendt!",
            "surveyerror": "Der blev ikke fundet nogle spørgeskemaer!",
            "scancode": "Scan QR kode",
            "seriallabel": "Scan eller tast serienummer",
            "continue": "Fortsæt",
            "qrcodeprompt": "Placer QR koden i scanningsområdet"
        },
        "survey": {
            "continuebtn": "Fortsæt",
            "doitanyway": "Udfyld skemaet igen",
            "truckok": "Spørgeskemaet er gennemført!",
            "back": "Tilbage",
            "truckinfo": "Maskininfo",
            "yes": "Ja",
            "no": "Nej",
            "continue": "Fortsæt",
            "takeimage": "Tag billede",
            "textplaceholder": "Indtast dit svar",
            "numberplaceholder": "Indtast tal",
            "cancel": "Annuller",
            "choosequestionnaire": "Vælg spørgeskema",
            "blescanning": "Skanner...",
            "bleerror": "Bluetooth fejl!",
            "blenotruck": "Kunne ikke finde maskinen",
            "bleconnecting": "Forbinder...",
            "blestarting": "Starter...",
            "bletrucknotstarted": "Maskinen kunne muligvis ikke startes",
            "blecouldntstarttruck": "Kunne ikke starte maskinen",
            "blecouldntconnect": "Kunne ikke forbinde til maskinen",
            "pushtitle": "Advarsel",
            "pushtext": "Maskinen stopper snart",
            "truck": "maskine",
            "model": "Model",
            "manufactor": "Mærke",
            "serial": "Serie nummer",
            "internalid": "internt ID",
            "type": "Type",
            "organization": "Organisation",
            "department": "Afdeling",
            "year": "Årgang",
            "ownership": "Ejer forhold",
            "owned": "Ejet",
            "leased": "Leaset",
            "rent": "Leje",
            "aquisitiondate": "Anskaffelses dato",
            "expirationdate": "Dato for udløb",
            "capacity": "Kapacitet (Kg)",
            "lift": "Løftehøjde (mm)",
            "height": "Byggehøjde (mm)",
            "serviceandmaintenance": "Service og vedligeholdelse",
            "hours": "Antal timer",
            "hours_date": "Dato for seneste timeaflæsning",
            "last_service": "Dato for seneste service\/eftersyn",
            "service_agreement": "Service aftale",
            "full": "Fuldservice",
            "discount": "Rabat aftale",
            "none": "Ingen aftale",
            "servicepartner": "Service partner",
            "expiration_date_service": "Dato for udløb",
            "equipment": "Udstyr",
            "technic": "Teknik",
            "close_modal": "Luk",
            "info": "Information",
            "electricalforklift": "Gaffeltruck Elektrisk",
            "dieselforklift": "Gaffeltruck Diesel",
            "gasforklift": "Gaffeltruck Gas",
            "reachtruck": "Reach Truck",
            "4wayreachtruck": "4-Vejs Truck",
            "pallettruck": "Pallevogn",
            "doubledeckerpallettruck": "Dobbeltstacker",
            "highliftpallettruck": "Stabler",
            "standonpallettruck": "Sta Ind Stabler",
            "sitonpallettruck": "Sid ind Stabler",
            "counterweightpallettruck": "Kontravægt Stabler",
            "widetrackpallettruck": "Bredspor Stabler",
            "orderpicker": "Plukketruck",
            "narrowtrackpallettruck": "Smalgangstruck",
            "tractor": "Trækker",
            "other": "Andre"
        },
        "confirmation": {
            "header": "Tak for din indtastning!"
        },
        "bletrucktime": {
            "headerresult": "Maskinen stopper om",
            "minutes": "minutter",
            "ok": "Videre"
        }
    },
    "system": {
        "countrycode": "45",
        "passwordsms": "Velkommen som bruger af STILL Preop Check, benyt Appen hvergang du skal bruge en maskine. Du kan hente Appen her: https:\/\/still-preop.com\/app Dit brugernavn er dit mobilnummmer\t og din kode er: {password}",
        "remindersms": "Husk at udfylde spørgeskemaet: {questionnaire} på maskinen: {type}, med serienummeret: {serial}"
    },
    "web": {
        "generic": {
            "close": "Luk",
            "delete": "Slet",
            "save": "Gem",
            "copy": "Kopier"
        },
        "login": {
            "wrongUsernameAndPass": "Brugernavn eller kodeord er forkert!",
            "usernamePlaceholder": "Brugernavn",
            "passwordPlaceholder": "Kodeord",
            "rememberMe": "Husk mine oplysninger",
            "login": "Log ind"
        },
        "department": {
            "delete": "Slet afdeling",
            "deleteAreYouSure": "Er du sikker på du vil slette {name}?",
            "department": "Afdeling",
            "name": "Navn",
            "trucks": "Maskiner",
            "working_days": "Drift dage",
            "monday": "Mandag",
            "tuesday": "Tirsdag",
            "wednesday": "Onsdag",
            "thursday": "Torsdag",
            "friday": "Fredag",
            "saturday": "Lørdag",
            "sunday": "Søndag"
        },
        "departments": {
            "name": "Navn",
            "edit": "Rediger",
            "createDepartment": "Opret afdeling",
            "departments": "Afdelinger"
        },
        "employee": {
            "delete": "Slet medarbejder",
            "deleteAreYouSure": "Er du sikker på du vil slette {name}?",
            "employee": "Medarbejder",
            "name": "Navn",
            "cell": "Mobil",
            "cellNumberExists": "Mobil nummeret eksisterer",
            "employeeNumber": "Medarbejder nummer",
            "sendSMS": "Send SMS med kode",
            "password": "Kode",
            "emptyPasswordMessage": "Hvis koden er tom, generer systemet en kode",
            "usernameExists": "Brugernavnet eksisterer allerede",
            "username": "Brugernavn",
            "groups": "Medarbejder grupper"
        },
        "employees": {
            "employees": "Medarbejdere",
            "createEemployee": "Opret medarbejder",
            "edit": "Rediger",
            "groups": "Grupper",
            "createGroup": "Opret gruppe"
        },
        "logout": {
            "thankYou": "Tak for nu",
            "loggedout": "Du er nu logged ud!",
            "loginAgain": "Log ind igen"
        },
        "menu": {
            "countryusers": "Landebrugere",
            "organizations": "Organisationer",
            "models": "Modeller",
            "trucks": "Maskiner",
            "users": "Brugere",
            "submits": "Resultater",
            "questionnaires": "Spørgeskemaer",
            "departmets": "Afdelinger",
            "employees": "Medarbejdere",
            "editprofile": "Rediger profil",
            "logout": "Log ud"
        },
        "model": {
            "model": "Model",
            "upload": "Upload",
            "delete": "Delete",
            "deleteAreYouSure": "Er du sikker på du vil slette {name}?"
        },
        "models": {
            "model": "Model",
            "models": "Models",
            "type": "Type",
            "edit": "Rediger",
            "createModel": "Opret model"
        },
        "organization": {
            "enableble": "Benyt Bluetooth enheder",
            "enableserial": "Benyt USB enheder",
            "delete": "Slet",
            "deleteAreYouSure": "Er du sikker på du vil slette {name}?",
            "organization": "Organisation",
            "name": "Navn",
            "number": "Kunde nr.",
            "address": "Adresse",
            "postal": "Post nr.",
            "city": "By",
            "contact": "Kontakt person",
            "users": "Brugere",
            "employees": "Medarbejdere",
            "trucks": "Maskiner",
            "departments": "Afdelinger",
            "usernamePrefixExists": "Prefixet til brugernavne bruges af en anden organisation",
            "usernameprefix": "Prefix til brugernavne, kort tekst, f.eks. stdk for STILL Danmark",
            "internal": "Internt",
            "demo": "Demo",
            "customer": "Kunde"
        },
        "organizations": {
            "organizations": "Organisationer",
            "name": "Navn",
            "usageReport": "Afregningsgrundlag",
            "createOrganization": "Opret organisation",
            "edit": "Rediger"
        },
        "questionnaire": {
            "number_updatefield": "Opdater timetæller med værdien der indtastes",
            "color": "Farve",
            "delete": "Slet spørgeskema",
            "deleteAreYouSure": "Er du sikker på du vil slette {name}?",
            "questionnaire": "Spørgeskema",
            "name": "Navn",
            "default": "Standard spørgeskema",
            "shuffleQuestions": "Bland spørgsmålene",
            "department": "Afdeling",
            "nocolor": "Ingen farve",
            "grey": "Grå",
            "green": "Grøn",
            "blue": "Blå",
            "yellow": "Gul",
            "red": "Rød",
            "questions": "Spørgsmål",
            "question": "Spørgsmål",
            "type": "Type",
            "mandatory": "Påkrævet",
            "type_boolean": "Ja\/Nej",
            "type_number": "Tal",
            "type_text": "Tekst",
            "type_image": "Billede",
            "type_boolean_positive": "Ja er positivt svar",
            "type_boolean_reverse_positive": "Nej er positivt svar",
            "action_type_image": "Billede ved negativt svar",
            "action_type_text": "Tekst ved negativt svar",
            "action_type_no_action": "Ingen aktion",
            "enableble": "Dette spørgeske kan aktivere Bluetooth enhed",
            "copy": "Kopier",
            "copyExplainer": "Vil du oprette en kopi af dette spørgeskema ?",
            "alwaysemail": "Send altid resultat med email",
            "nostartonfalse": "Start ikke maskinen ved falske besvarelser",
            "primary": "Primært spørgeskema"
        },
        "questionnaireresult": {
            "truck": "Maskine",
            "employee": "Medarbejder",
            "time": "Tidspunkt",
            "questionnaire": "Spørgeskema",
            "view": "Vis besvarelse",
            "pdf": "PDF",
            "no": "Nej",
            "yes": "Ja"
        },
        "questionnaires": {
            "questionnaires": "Spørgeskemaer",
            "createQuestionnaire": "Opret spørgeskema",
            "name": "Navn",
            "questions": "Spørgsmål",
            "default": "Standard spørgeskema",
            "edit": "Rediger",
            "yes": "Ja"
        },
        "truck": {
            "ble": "Bluetooth enhed",
            "enableble": "Aktiver bluetooth enhed",
            "blename": "Navn på enhed",
            "bleopentime": "Tid enhenden skal være aktiveret, i minutter",
            "delete": "Slet",
            "deleteAreYouSure": "Er du sikker på du vil slette maskinen?",
            "truck": "Maskine",
            "qrcode": "QR Kode",
            "model": "Model",
            "manufactor": "Mærke",
            "serial": "Serie nummer",
            "internalid": "internt ID",
            "type": "Type",
            "organization": "Organisation",
            "department": "Afdeling",
            "year": "Årgang",
            "ownership": "Ejer forhold",
            "owned": "Ejet",
            "leased": "Leaset",
            "rent": "Leje",
            "aquisitiondate": "Anskaffelses dato",
            "expirationdate": "Dato for udløb",
            "capacity": "Kapacitet (Kg)",
            "lift": "Løftehøjde (mm)",
            "height": "Byggehøjde (mm)",
            "serviceandmaintenance": "Service og vedligeholdelse",
            "hours": "Antal timer",
            "hours_date": "Dato for seneste timeaflæsning",
            "last_service": "Dato for seneste service\/eftersyn",
            "service_agreement": "Service aftale",
            "full": "Fuldservice",
            "discount": "Rabat aftale",
            "none": "Ingen aftale",
            "servicepartner": "Service partner",
            "expiration_date_service": "Dato for udløb",
            "equipment": "Udstyr",
            "technic": "Teknik",
            "checkinterval": "Interval for udførelse af kontrol",
            "interval_days": "dage",
            "interval_weeks": "uger",
            "interval_hours": "timer",
            "interval_years": "år",
            "deactivated": "Deaktiveret",
            "reminder": "Påmindelse",
            "serialconnection": "USB Forbindelse",
            "enableserialconnection": "Start trucken med USB forbindelse"
        },
        "trucks": {
            "trucks": "Maskiner",
            "createTruck": "Opret maskine",
            "manufactor": "Mærke",
            "type": "Type",
            "organization": "Organisation",
            "serial": "Serie nummer",
            "internalid": "internt ID",
            "edit": "Rediger",
            "export": "Eksporter",
            "all": "Alle",
            "checkmorethan3days": "Mere end 3 dage siden tjek",
            "checkwithin3days": "Tjekket inden for 3 dage",
            "checktoday": "Tjekket idag"
        },
        "user": {
            "user": "Bruger",
            "delete": "Slet bruger",
            "deleteAreYouSure": "Er du sikker på du vil slette {name}?",
            "name": "Navn",
            "username": "Brugernavn",
            "usernameexists": "Brugernavnet eksisterer allerede",
            "role": "Rolle",
            "admin": "Administrator",
            "organization": "Organisation",
            "department": "Afdeling",
            "newpassword": "Ny kode",
            "inputpassword": "Angiv kodeord",
            "email": "Email",
            "email_reports": "Modtag fejl rapporter",
            "daily_reports": "Modtag daglig oversigt over maskiner der ikke er tjekket"
        },
        "users": {
            "users": "Brugere",
            "name": "Navn",
            "username": "Brugernavn",
            "role": "Rolle",
            "organization": "Organisation",
            "edit": "Rediger",
            "createUser": "Opret bruger"
        },
        "welcome": {
            "truckstotal": "Maskiner ialt",
            "withservice": "Maskiner med udført tjek idag",
            "withoutservice": "Maskiner med manglende tjek"
        },
        "table": {
            "next": "Næste",
            "previous": "Forrige",
            "page": "Side",
            "of": "af",
            "nodata": "Ingen data tilgængelig"
        }
    },
    "0": "1"
}